import { ReactSortable } from "react-sortablejs";

import { useState, useEffect, useContext, useRef } from 'react';
import { getImages } from '../Const';
import { Field } from "formik";

const makeArray = (array) => {

}
const PhotosUpload = () => {
    const imgs = [];
    // const {uploadDocument, editFileHeaders} = useContext([]);
    const [imgsSrc, setImgsSrc] = useState([]);
    const [imgURLs, setIMGURLs] = useState([]);
    const readFileContents = async (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = async (e) => {
                const result = reader.result;
                resolve({ result });
            };
            reader.onerror = () => {
                reject(new Error("File reading failed"));
            };
        });
    }

    const readAllFiles = async (AllFiles) => {
        const urls = await Promise.all(AllFiles.map(async (file) => {
            const url = await readFileContents(file);
            return url;
        }));
        return urls;
    }

    let lastIndexUsed = 0;
    const handleUpload = (e) => {
        let AllFiles = [];
        [...e.target.files].forEach(file => AllFiles.push(file));

        readAllFiles(AllFiles).then(urls => {
            const img = urls.map((item) => {
                let index = lastIndexUsed;
                let id = "item" + index;

                while (imgsSrc.some(img => img.id === id)) {
                    index++;
                    id = "item" + index;
                }

                lastIndexUsed = index + 1;

                return {
                    id: id,
                    item: item.result,
                };
            });

            setImgsSrc([...imgsSrc, ...img]);
            setIMGURLs([...imgURLs, ...img.map(item => item.item)]);
        })
            .catch(err => {
                alert(err);
            });
    }

    useEffect(() => {
        // nextStep(0, { "images": imgURLs });
    }, [imgURLs]);



    const [checked, setChecked] = useState([]);
    const checkPrimary = (e) => {
        var checkeds = [...checked];
        console.log(checkeds);
        console.log(e.target.id);

        if (checkeds.indexOf(e.target.id) > -1) {
            checkeds = checkeds.filter((item) => item !== e.target.id);
        }
        else {
            checkeds.push((e.target.id))
        }
        setChecked(checkeds);
    };

    const isSortingRef = useRef(false);

    const updateOrder = (updatedList) => {
        if (!isSortingRef.current) return;
        isSortingRef.current = false;
        setImgsSrc(updatedList);
    };

    const isMounted = useRef(false);

    const deleteItem = () => {
        const filteredImgsSrc = imgsSrc.filter(item => !checked.includes(item.id));
        setImgsSrc(filteredImgsSrc);
        setIMGURLs(filteredImgsSrc.map(item => item.item));
        setChecked([]);
    };

    const setAsPrimary = () => {
        setImgsSrc(imgsSrc.filter(item => checked.includes(item.id)).concat(imgsSrc.filter(item => !checked.includes(item.id))));
    }

    useEffect(() => {
        if (isMounted.current) {
            console.log(imgsSrc);
            for (var i = 0; i < imgsSrc.length; i++) {
                // editFileHeaders(imgsSrc[i].key,{"order":i});
            }
        } else
            isMounted.current = true;
    }, [imgsSrc]);

    return (
        <>
            {imgsSrc.length > 0 ?
                <div className='up-img-flex my-5'>
                    <div className="d-flex align-items-center upload-action-block">
                        <div>
                            <button type='button'
                                className="xs-title font-12 text-start text-uppercase text-decoration-none text-white action-link upload-photo-btn">
                                <input type="file" onChange={handleUpload} name="file" accept="image/*" multiple />
                                <img src={getImages('icon-photos-small.png')} /> Add More Photos
                            </button>
                        </div>
                        <div className="ms-4">
                            <button type='button'
                                className="xs-title font-12 text-start text-uppercase text-decoration-none text-white action-link" onClick={setAsPrimary}>
                                <img src={getImages('icon-primary.png')} /> Set as Primary
                            </button>
                        </div>
                        <div className="ms-4">
                            <button type='button'
                                className="xs-title font-12 text-start text-uppercase text-decoration-none text-white action-link" onClick={deleteItem}>
                                <img src={getImages('icon-delete-photo.png')} /> Delete</button>
                        </div>
                    </div>
                    <div className='grid-overflow my-3'>
                        <ReactSortable
                            ghostClass="dropArea"
                            handle=".dragHandle"
                            filter=".ignoreDrag"
                            preventOnFilter={true}
                            className="grid-container"
                            dragoverBubble={false}
                            list={imgsSrc}
                            onUpdate={() => isSortingRef.current = true}
                            setList={(updatedList) => updateOrder(updatedList)}>

                            {imgsSrc && <>
                                {imgsSrc.map((item, index) => (
                                    <div className={(checked.indexOf('primaryCheck' + item.id) > -1) ? "pl-box checked dragHandle" : "pl-box dragHandle"} key={index}>
                                        <div style={{ padding: "12px" }}>
                                            <div className="pl-img">
                                                <img src={item.item} />
                                            </div>
                                            <div className="custom-checkbox sm-checkbox">
                                                <input className="styled-checkbox" id={item.id} type="checkbox"
                                                    checked={checked.indexOf(item.id) > -1}
                                                    value={item.id} onClick={(e) => checkPrimary(e)} onChange={(e) => console.log(e.target.value)} />
                                                <label htmlFor={item.id}><span>Primary {item.id}</span></label>
                                            </div>
                                        </div>
                                    </div>

                                ))}</>
                            }
                        </ReactSortable>
                    </div>
                    <div className="text-start info-text font-12">Tip: Select Primary Photo for your  Bid and you can also drag and drop to re-order</div>
                </div>
                : <div className="upload-photos-box my-5">
                    <div className="sm-title fw-400 text-center text-white">Select up to 20 photos you wish to upload</div>
                    <div className="my-30 text-center">
                        {/* <button className="blue-transparent-btn text-uppercase md-btn plr-65 upload-photo-btn">
                            <input type="file" onChange={handleUpload} name="file" accept="image/*" multiple />
                            <span className='icon-upload'></span> Browse Photos
                        </button>
                        
                        <Field name="file">
                            {({ field, form }) => (
                                <input
                                    type="file"
                                    accept="image/*"
                                    multiple
                                    onChange={(e) => handleImageChange(e.target.files)}
                                    id="fileInput"
                                />
                            )}
                        </Field> */}
                        <span className='blue-transparent-btn text-uppercase md-btn plr-65 upload-photo-btn d-inline-block'>
                            <Field name="file">
                                {({ field, form }) => (
                                    <input
                                    type="file"
                                    accept="image/*"
                                    multiple
                                    onChange={handleUpload}
                                    id="fileInput"
                                    />
                                )}
                            </Field>
                            <span className='icon-upload'></span> Browse Photos
                        </span>
                    </div>
                    <div className="text-center info-text font-12">Maximum file size 5mb, file format allowed jpg, png, gif</div>
                </div>
            }
        </>
    )
}

export default PhotosUpload