import React, { useState, useContext, useEffect } from "react";
import { getImages } from "../Const";
import { LeadsContext } from "../Context/LeadsContext";

const PlainTextEmail = ({ close, onAddScriptBtnClick, leadData, selectedScript }) => {
  
  const { emailScripts, leadFields, agentData, sendPlainTextEmail } = useContext(LeadsContext);

  const [selectedOption, setSelectedOption] = useState(0);

  const [emailSubject,setEmailSubject] = useState("");
  const [emailBody,setEmailBody] = useState("");

  const [agentEmail,setAgentEmail] = useState("");

  const [leadEmail,setLeadEmail] = useState("");

  const handleSelectChange = (value) => {
    setSelectedOption(value);

    //console.log("selecting value",value,leadData,emailScripts,leadFields);

    if (value !== null && Array.isArray(emailScripts) && value in emailScripts) {

      var this_subject = emailScripts[value].subject;
      var this_script = emailScripts[value].script;

      leadFields.map((field) => {
        this_subject = this_subject.replaceAll('[' + field + ']',leadData[field] || "");
        this_script = this_script.replaceAll('[' + field + ']',leadData[field] || "");
      });

      setEmailSubject(this_subject);
      setEmailBody(this_script);
    }
  };

  const handleEmailBody = (body) => {
    setEmailBody(body);
  }

  const sendEmail = async (e) => {
      e.preventDefault();
      
      const formData = Object.fromEntries(new FormData(e.target));

      const response = await sendPlainTextEmail(formData,leadData);

        if(response && response.success) {
          close();
          alert('Your email has been sent');
        } else alert('Error sending email');
  }

  const [emailHistory, setEmailHistory] = useState([]);

  useEffect(() => {
    if(leadData) {
      handleSelectChange(selectedOption);

      if(agentData && Array.isArray(agentData)) setAgentEmail(leadData.agent_email || "");

      setLeadEmail(leadData.email);

      if(leadData.notes) {
        var email_history = [];

        leadData.notes.map((v,i) => {
          if(v.title == "Plain Text Email" || v.title == "Received Email") {

            const createdAtDate = new Date(v.created_at + " UTC");

            v.time = createdAtDate.toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
            v.date = createdAtDate.toLocaleString('en-US', { year: "2-digit", month: "2-digit", day: "numeric" });

            email_history.push(v);
          }
        });

        setEmailHistory(email_history);
      }

      console.log(email_history);

    }
  }, [leadData]);

  useEffect(() => {
    if(emailScripts && Array.isArray(emailScripts)) handleSelectChange(0);
  }, [emailScripts]);

  useEffect(() => {
    if(selectedScript) {
      var this_script = null;
      emailScripts.map((v,i) => {
        if(v.script_name == selectedScript) this_script = i;
      })
      if(this_script) handleSelectChange(this_script);
    }
  }, [selectedScript]);
  
  return (
    <>
      <div className="modal-header">
        <h1 className="modal-title text-uppercase" id="staticBackdropLabel">
          Plain Text Email
        </h1>
        <button className="sm-box-close" type="button" onClick={close}>
          <i className="fa-solid fa-xmark"></i>
        </button>
      </div>
      <div className="modal-body pd-25-65">
        <div className="text-end mb-2 d-none d-lg-block">
          <button
            onClick={() => {
              close();
              onAddScriptBtnClick();
            }}
            className="text-nowrap blue-transparent-btn dark-transparent-btn text-uppercase"
            type="button"
          >
            <span className="blue-add-icon"></span> Add script
          </button>
        </div>

        <form className="custom-form form-style2" onSubmit={(e) => sendEmail(e)}>
          <input type="hidden" name="email" value={leadEmail || ""} onChange={(e) => setLeadEmail(e.target.value)}/>
          <div className="form-group mb-3">
            <label>Select Sender</label>
            <select 
              name="agent_email" 
              className="form-control"
              onChange={(e) => setAgentEmail(e.target.value)}
              value={agentEmail}
            >
              {agentData.map((agent,i) => 
                <option key={i} value={agent.email}>{agent.full_name}</option>
              )}
            </select>
          </div>
          <div className="form-group mb-3">
            <label>Select Script</label>
            <select
              name="script"
              className="form-control"
              onChange={(e) => handleSelectChange(e.target.value)}
              value={selectedOption}
            >
              {emailScripts.map((script,i) => 
                <option key={i} value={i}>{script.script_name}</option>
              )}
            </select>
          </div>
          <div className="form-group mb-3">
            <label>Subject</label>
            <input
              type="text"
              name="subject"
              className="form-control"
              value={emailSubject}
              onChange={(e) => setEmailSubject(e.target.value)}
            />
          </div>
          <div className="form-group mb-3">
            <label>Message</label>
              <textarea className="msgitem msgItem1 form-control h260" name="message" value={emailBody} onChange={(e) => handleEmailBody(e.target.value)}>
              </textarea>
          </div>
          <div className="ptm-logo text-start mb-4">
            <img src={getImages("main-logo.svg")} alt="logo" />
          </div>
          <div className="text-end">
            <button
              className="blue-btn mnw-170 text-center text-uppercase"
              type="submit"
            >
              <span className="send-icon"></span> SEND Message
            </button>
          </div>
        </form>

        <div className="email-history-block mt-5">
          <div className="pd-sm-title text-d-blue mb-2">
            <i className="fa-regular fa-envelope me-2"></i> Email History
          </div>
          <div className="email-history-box">
            {emailHistory.map((v,i) => {
              return (
                <div className="eh-box-list" key={i}>
                  <div className="d-flex align-items-center mb-3 justify-content-between">
                    <div className="pd-sm-title text-d-blue font-12">{v.subtitle}</div>
                    <div className="d-flex align-items-center">
                      <div className="pd-xs-title fw-500 me-4">{v.time}</div>
                      <div className="pd-xs-title fw-500">{v.date}</div>
                    </div>
                  </div>
                  <div className="pd-xs-title lh-16">{v.note}</div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default PlainTextEmail;
