import React, { useState } from 'react'
import { getImages } from '../Const';

const LeadSourceStaticData = ({
    leadSourceId,
    leadSource, 
    openLead, 
    handleAccordionClick, 
    handleMobArrow,
    mobLeadItem,
    cancelMobLeadItem,
    leadData
}) => {

  return (
    <>
        
        <tr id="staticData">
                <td className='mob-lead-item d-lg-none mb-3 px-0'>
                    <div>
                        <table className='w-100'>
                            <tbody>
                                <tr>
                                    <td className='w-100'>
                                        <div className='d-flex align-items-center'>
                                            <div>
                                                <div className='pd-box-icon position-relative'>
                                                    <img src={getImages('store-lead-icon.svg')} alt='icon'/>
                                                </div>
                                            </div>
                                            <div className='pd-sm-title text-white ps-3'>Third-Party</div>
                                            <div className='ms-auto'>
                                                <button className="mob-lead-btn"
                                                    type="button"
                                                    onClick={()=>{handleMobArrow("staticData"); handleAccordionClick("staticData");}}
                                                >
                                                    <span className='btn-arrow' type='button'></span>
                                                </button>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </td>
                <td className={`p-0 col-parent ${mobLeadItem === "staticData" ? 'parent-mob-active':''}`}>
                    <div className="accordion-item">
                        <button className='mob-back-btn p-0 d-lg-none' type='button' onClick={cancelMobLeadItem}><span className='ps-0'><img src={getImages('left-arrow.svg')} /></span> Back</button>
                        <table className='w-100'>
                            <tbody>
                                <tr>
                                    <td className='mob-d-none ps-4' style={{ width: "5%" }}>
                                        <h2 className="accordion-header p-0">
                                            <button className={`accordion-button p-0 ${openLead === "staticData" ? "" : "collapsed"}`}
                                                type="button"
                                                onClick={() => handleAccordionClick("staticData")}
                                            >
                                                <span className='btn-arrow accordionTitle' type='button'></span>
                                            </button>
                                        </h2>
                                    </td>
                                    <td style={{ width: "10%" }}>
                                        <div className='d-flex align-items-center'>

                                            <div className='pd-box-icon position-relative'>
                                                <img src={getImages('store-lead-icon.svg')} alt='icon' />
                                            </div>
                                            <div className='pd-content ps-4 d-none d-lg-block'>
                                                <div className='pd-sm-title'>{}</div>
                                            </div>
                                        </div>
                                    </td>
                                    <td style={{ width: "25%" }} className='text-start'>Third-Party</td>
                                    <td style={{ width: "15%" }}>
                                        <div className='d-lg-none'>Leads</div>
                                        <div>10</div>
                                    </td>
                                    <td style={{ width: "15%" }}>
                                        <div className='d-lg-none'>Sales</div>
                                        <div>7</div>
                                    </td>
                                    <td style={{ width: "15%" }}>
                                        <div className='d-lg-none'>Total Revenue</div>
                                        <div>$5m</div>
                                    </td>
                                    <td style={{ width: "15%" }} className='text-green'>
                                        <div className='d-lg-none'>Gross Profit</div>
                                        <div>$2m</div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        {openLead === "staticData" ? (
                            <div className="accordion-collapse collapse show">
                                <div className="accordion-body p-0">
                                    <div className='inside-cat-table'>
                                        <table className='w-100'>
                                            <tbody>
                                                <tr>
                                                    <td style={{width: "5%"}}></td>
                                                    <td style={{width: "10%"}} className='text-start'>
                                                        <img src={getImages('Autotrader-logo.png')}/>
                                                    </td>
                                                    <td style={{width: "25%"}} className='text-start'>Autotrader</td>
                                                    <td style={{width: "15%"}}>4</td>
                                                    <td style={{width: "15%"}}>1</td>
                                                    <td style={{width: "15%"}}>$40,000</td>
                                                    <td style={{width: "15%"}} className='text-green'>$12,000</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className='inside-ls-table'>
                                        <table className='w-100'>
                                            <thead>
                                                <tr>
                                                    <th style={{width:'25%'}}>Name</th>
                                                    <th style={{width:'31%'}}>Vehicle</th>
                                                    <th style={{width:'15%'}}>Date</th>
                                                    <th style={{width:'10%'}}>Stock #</th>
                                                    <th style={{width:'19%'}} className='text-center'>Gross Profit</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td style={{width:'25%'}} data-title="Name" className='text-white'>Richard Smith</td>
                                                    <td style={{width:'31%'}} data-title="Vehicle" className='text-white'>2021 Porsche Turbo S</td>
                                                    <td style={{width:'15%'}} data-title="Date" className='text-nowrap'>mm/dd/yy</td>
                                                    <td style={{width:'10%'}} data-title="Stock #">#FK29181</td>
                                                    <td style={{width: '19%'}} data-title="Gross Profit" className='text-white font-15  text-center'>$10,000</td>
                                                </tr>
                                                <tr>
                                                    <td style={{width:'25%'}} data-title="Name" className='text-white'>Billy Corgan</td>
                                                    <td style={{width:'31%'}} data-title="Vehicle" className='text-white'>2013 Cadillac Escalade</td>
                                                    <td style={{width:'15%'}} data-title="Date" className='text-nowrap'>mm/dd/yy</td>
                                                    <td style={{width:'10%'}} data-title="Stock #">#FK29181</td>
                                                    <td style={{width: '19%'}} data-title="Gross Profit" className='text-white font-15  text-center'>$10,000</td>
                                                </tr>
                                                <tr>
                                                    <td style={{width:'25%'}} data-title="Name" className='text-white'>Jonh Mellencamp</td>
                                                    <td style={{width:'31%'}} data-title="Vehicle" className='text-white'>2022 Audi R8 Quatro</td>
                                                    <td style={{width:'15%'}} data-title="Date" className='text-nowrap'>mm/dd/yy</td>
                                                    <td style={{width:'10%'}} data-title="Stock #">#FK29181</td>
                                                    <td style={{width: '19%'}} data-title="Gross Profit" className='text-white font-15  text-center'>$10,000</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>


                                     {/* Data 2 */}
                                     <div className='inside-cat-table'>
                                        <table className='w-100'>
                                            <tbody>
                                                <tr>
                                                    <td style={{width: "5%"}}></td>
                                                    <td style={{width: "10%"}} className='text-start'>
                                                        <img src={getImages('cargurus-1.png')}/>
                                                    </td>
                                                    <td style={{width: "25%"}} className='text-start'>CarGurus</td>
                                                    <td style={{width: "15%"}}>3</td>
                                                    <td style={{width: "15%"}}>1</td>
                                                    <td style={{width: "15%"}}>$28,000</td>
                                                    <td style={{width: "15%"}} className='text-green'>$10,000</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className='inside-ls-table'>
                                        <table className='w-100'>
                                            <thead>
                                                <tr>
                                                    <th style={{width:'25%'}}>Name</th>
                                                    <th style={{width:'31%'}}>Vehicle</th>
                                                    <th style={{width:'15%'}}>Date</th>
                                                    <th style={{width:'10%'}}>Stock #</th>
                                                    <th style={{width:'19%'}} className='text-center'>Gross Profit</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td style={{width:'25%'}} data-title="Name" className='text-white'>Jane Peterson</td>
                                                    <td style={{width:'31%'}} data-title="Vehicle" className='text-white'>2021 Porsche Turbo S</td>
                                                    <td style={{width:'15%'}} data-title="Date" className='text-nowrap'>mm/dd/yy</td>
                                                    <td style={{width:'10%'}} data-title="Stock #">#FK29181</td>
                                                    <td style={{width: '19%'}} data-title="Gross Profit" className='text-white font-15  text-center'>$10,000</td>
                                                </tr>
                                                <tr>
                                                    <td style={{width:'25%'}} data-title="Name" className='text-white'>Mike Tyson</td>
                                                    <td style={{width:'31%'}} data-title="Vehicle" className='text-white'>2013 Cadillac Escalade</td>
                                                    <td style={{width:'15%'}} data-title="Date" className='text-nowrap'>mm/dd/yy</td>
                                                    <td style={{width:'10%'}} data-title="Stock #">#FK29181</td>
                                                    <td style={{width: '19%'}} data-title="Gross Profit" className='text-white font-15  text-center'>$10,000</td>
                                                </tr>
                                                <tr>
                                                    <td style={{width:'25%'}} data-title="Name" className='text-white'>William Shakesphere</td>
                                                    <td style={{width:'31%'}} data-title="Vehicle" className='text-white'>2022 Audi R8 Quatro</td>
                                                    <td style={{width:'15%'}} data-title="Date" className='text-nowrap'>mm/dd/yy</td>
                                                    <td style={{width:'10%'}} data-title="Stock #">#FK29181</td>
                                                    <td style={{width: '19%'}} data-title="Gross Profit" className='text-white font-15  text-center'>$10,000</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    {/* Data 3 */}
                                    <div className='inside-cat-table'>
                                        <table className='w-100'>
                                            <tbody>
                                                <tr>
                                                    <td style={{width: "5%"}}></td>
                                                    <td style={{width: "10%"}} className='text-start'>
                                                        <img src={getImages('logo-cars.png')}/>
                                                    </td>
                                                    <td style={{width: "25%"}} className='text-start'>Cars.com</td>
                                                    <td style={{width: "15%"}}>1</td>
                                                    <td style={{width: "15%"}}>1</td>
                                                    <td style={{width: "15%"}}>$4,000</td>
                                                    <td style={{width: "15%"}} className='text-green'>$2,000</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className='inside-ls-table'>
                                        <table className='w-100'>
                                            <thead>
                                                <tr>
                                                    <th style={{width:'25%'}}>Name</th>
                                                    <th style={{width:'31%'}}>Vehicle</th>
                                                    <th style={{width:'15%'}}>Date</th>
                                                    <th style={{width:'10%'}}>Stock #</th>
                                                    <th style={{width:'19%'}} className='text-center'>Gross Profit</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td style={{width:'25%'}} data-title="Name" className='text-white'>Veronica Jones</td>
                                                    <td style={{width:'31%'}} data-title="Vehicle" className='text-white'>2021 Porsche Turbo S</td>
                                                    <td style={{width:'15%'}} data-title="Date" className='text-nowrap'>mm/dd/yy</td>
                                                    <td style={{width:'10%'}} data-title="Stock #">#FK29181</td>
                                                    <td style={{width: '19%'}} data-title="Gross Profit" className='text-white font-15  text-center'>$10,000</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        ) : ""}
                    </div>
                </td>
            </tr>
    </>
  )
}

export default LeadSourceStaticData