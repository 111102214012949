import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import LeadsContextProvider from './Components/Context/LeadsContext';
import EnvContextProvider from './Components/Context/EnvContext';
import UserContextProvider from './Components/Context/UserContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  //<React.StrictMode>

    <EnvContextProvider>
      <UserContextProvider>
        <LeadsContextProvider>
          <App />
        </LeadsContextProvider>
      </UserContextProvider>
    </EnvContextProvider>
  //</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
