import React, { useEffect, useRef, useState, useContext } from "react";
import { LeadsContext } from "../Context/LeadsContext";

const ScriptModal = ({ close }) => {

  const {leadFields,emailScripts,addEmailScript} = useContext(LeadsContext);

  useEffect(() => {
    handleSelectChange(0);
  }, [emailScripts]);

  const [scriptName, setScriptName] = useState("");
  const [selectedOption, setSelectedOption] = useState(0);
  let dragElementType = useRef(null);
  const preDefinedTags = leadFields;

  useEffect(() => {
    const tagsWrapper = document.getElementById("tagsWrapper");
    tagsWrapper.addEventListener("dragstart", handleDragStart);

    return () => {
      tagsWrapper.removeEventListener("dragstart", handleDragStart);
    };
  }, []);

  const handleDragStart = (e) => {
    e.dataTransfer.setData("text/plain", e.target.dataset.value);
    dragElementType.current = "tag";
  };

  const allowDrop = (e) => {
    e.preventDefault();
  };

  const drop = (e, sectionId) => {
    e.preventDefault();
    if (dragElementType.current === "tag") {
      console.log(e.target.tagName, e.target?.firstChild?.tagName);
      if (e.target?.firstChild?.tagName?.toLowerCase() === "br") {
        e.target.firstChild.nodeValue = " ";
      }
      if (e.target.tagName.toLowerCase() === "div") {
        dragElementType.current = null;
        const data = e.dataTransfer.getData("text/plain");
        const tagElement = createTagElement(data);
        tagElement.addEventListener("drop", (e) => e.preventDefault());
        console.log("tagElement:", tagElement);
        const section = document.getElementById(sectionId);
        let range;

        if (document.caretPositionFromPoint) {
          const position = document.caretPositionFromPoint(
            e.clientX,
            e.clientY
          );
          const node = position.offsetNode;
          const offset = position.offset;

          if (
            isStartOfLine(node, offset) ||
            (offset === 0 &&
              node.nodeType === Node.ELEMENT_NODE &&
              !node.childNodes.length)
          ) {
            insertTagAtBeginning(tagElement, section);
          } else {
            insertTagInText(node, offset, tagElement);
          }
        } else if (document.caretRangeFromPoint) {
          range = document.caretRangeFromPoint(e.clientX, e.clientY);
          const node = range.endContainer;
          const offset = range.endOffset;

          if (
            isStartOfLine(node, offset) ||
            (offset === 0 &&
              node.nodeType === Node.ELEMENT_NODE &&
              !node.childNodes.length)
          ) {
            insertTagAtBeginning(tagElement, section);
          } else {
            insertTagInText(node, offset, tagElement);
          }
        }
      }
    }
  };

  const createTagElement = (data) => {
    const tagElement = document.createTextNode(`[${data}]`);
    return tagElement;
  };

  const isStartOfLine = (node, offset) => {
    return (
      (node.nodeType === Node.TEXT_NODE && offset === 0) ||
      node.tagName === "BR"
    );
  };

  const insertTagInText = (node, offset, tagElement) => {
    if (node.nodeType === Node.TEXT_NODE && node.parentNode) {
      console.log("in first id");
      const text = node.textContent;
      node.textContent = "";
      const firstHalf = text.slice(0, offset);
      const secondHalf = text.slice(offset);
      const firstTextNode = document.createTextNode(firstHalf);
      const secondTextNode = document.createTextNode(secondHalf);
      node.parentNode.insertBefore(firstTextNode, node);
      node.parentNode.insertBefore(tagElement, node);
      node.parentNode.insertBefore(secondTextNode, node);
    } else {
      node.appendChild(tagElement);
    }
  };

  const insertTagAtBeginning = (tagElement, section) => {
    const firstChild = section.firstChild;
    const spaceElement = document.createTextNode(" ");
    if (firstChild) {
      section.insertBefore(tagElement, firstChild);
      section.appendChild(spaceElement);
    } else {
      section.appendChild(tagElement);
      section.appendChild(spaceElement);
    }
  };

  const handleSelectChange = (value) => {
    if(emailScripts && Array.isArray(emailScripts) && value in emailScripts) {
      setSelectedOption(value);

      document.getElementById("subject").innerHTML = emailScripts && emailScripts[value].subject ? emailScripts[value].subject : '';
      document.getElementById("email").innerHTML = emailScripts && emailScripts[value].script ? emailScripts[value].script.replace(/(?:\r\n|\r|\n)/g, '<br>') : '';

    }
  };

  const removeBRElements = (event) => {
    // if (event.key === "Enter" || event.keyCode === 13) {
    // } else {
    //   let id = event.target.id;
    //   let divElement = document.getElementById(id);
    //   Array.from(divElement.querySelectorAll("div:empty")).forEach((ele) => {
    //     ele.remove();
    //   });
    //   Array.from(divElement.querySelectorAll(":scope > br")).forEach((ele) => {
    //     ele.remove();
    //   });
    // }
  };

  const addScript = async () => {

    if(scriptName == "") {
      alert('Please add a script name');
      return;
    }

    const response = await addEmailScript(scriptName, document.getElementById("subject").innerHTML, document.getElementById("email").innerHTML.replace(/<br\s*[\/]?>/gi, "\r\n"));

    if(response && response.success) {
      close();
      alert('Your script has been added');
    } else alert('Error adding script');
  };
  return (
    <>
      <div className="modal-header">
        <h1 className="modal-title text-uppercase" id="staticBackdropLabel">
          Create Script
        </h1>
        <button className="sm-box-close" type="button" onClick={close}>
          <i className="fa-solid fa-xmark"></i>
        </button>
      </div>
      <div className="modal-body pd-25-65">
        <label className="text-uppercase mb-3 text-green fw-bold">
          Plain Text Script
        </label>
        <div className="custom-form form-style2">
          <div className="row">
            <div className="col-4">
              <div className="form-group mb-3">
                <label
                  className="text-uppercase text-black"
                  style={{ opacity: 0.4 }}
                >
                  Script Name
                </label>
                <input
                  type="text"
                  value={scriptName}
                  onChange={(e) => setScriptName(e.target.value)}
                  name="script"
                  className="form-control"
                />
              </div>
            </div>
            <div className="col-4">
              <div className="form-group mb-3">
                <label
                  className="text-uppercase text-black"
                  style={{ opacity: 0.4 }}
                >
                  Load Existing Script
                </label>
                <select
                  name="script"
                  className="form-control"
                  value={selectedOption}
                  onChange={(e) => handleSelectChange(e.target.value)}
                >
                  {emailScripts.map((script,i) => 
                    <option key={i} value={i}>{script.script_name}</option>
                  )}
                </select>
              </div>
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-4">
              <label
                className="text-black text-uppercase"
                style={{ opacity: 0.4 }}
              >
                Insert pre-defined tags
              </label>
              <div id="tagsWrapper" className="tags-wrapper">
                {preDefinedTags.map((tag, index) => (
                  <div
                    key={index}
                    className="tag"
                    data-value={tag}
                    draggable="true"
                    onDragStart={handleDragStart}
                  >
                    {tag}
                    <svg
                      className="ms-auto"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                      />
                    </svg>
                  </div>
                ))}
              </div>

              <small
                className="text-gray text-sm"
                style={{ fontSize: "10px", opacity: 0.5 }}
              >
                Tip: You can drag and drop those tags to compose your email
              </small>
            </div>
            <div className="col-8">
              <div className="form-group mb-3">
                <label
                  className="text-black text-uppercase"
                  style={{ opacity: 0.4 }}
                >
                  Email subject
                </label>
                <div className="form-control py-2">
                  <div
                    id="subject"
                    className="w-100"
                    style={{ height: 22 }}
                    onDrop={(e) => {
                      drop(e, "subject");
                    }}
                    onDrag={(e) => e.preventDefault()}
                    contentEditable="true"
                    onKeyUp={removeBRElements}
                  ></div>
                </div>
              </div>
              <div className="form-group mt-5 mb-3">
                <label
                  className="text-black text-uppercase"
                  style={{ opacity: 0.4 }}
                >
                  Insert Your Custom Script For The Plain Text Emails
                </label>
                <div className="form-control pt-3 email-template-box">
                  <div
                    contentEditable="true"
                    id="email"
                    className="h-100 w-100"
                    style={{ lineHeight: "24px" }}
                    onDrop={(e) => drop(e, "email")}
                    onDrag={(e) => e.preventDefault()}
                    onDragOver={(e) => allowDrop(e)}
                    onKeyUp={removeBRElements}
                  ></div>
                </div>
              </div>
            </div>
            <div className="w-full d-flex justify-content-end mt-2">
              <button
                onClick={addScript}
                className="py-2 btn text-white px-4 blue-btn"
              >
                Save Script
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ScriptModal;
