import { BrowserRouter } from "react-router-dom";
import AppRoutes from "./Route/AppRoutes";
import 'react-toastify/dist/ReactToastify.css';
import "./assets/css/all.min.css";
import "./assets/css/style.css";
import "./assets/css/responsive.css";
function App() {
  return (
    <div className="App">
        <BrowserRouter>
          <AppRoutes />
        </BrowserRouter>
    </div>
  );
}

export default App;
