import React, { useState } from 'react';
import { getImages } from '../Const';

const CommunityUpload = () => {
    const [uploads, setUploads] = useState([
        { id: 1, uploadedImage: null, isDragging: false },
        { id: 2, uploadedImage: null, isDragging: false },
        { id: 3, uploadedImage: null, isDragging: false },
    ]);

    const handleImageUpload = (e, id) => {
        const file = e.target.files[0];
        if (file) {
            const newUploads = uploads.map((upload) =>
                upload.id === id ? { ...upload, uploadedImage: URL.createObjectURL(file) } : upload
            );
            setUploads(newUploads);
        }
    };

    const handleDragOver = (e, id) => {
        e.preventDefault();
        setUploads(uploads.map(upload =>
            upload.id === id ? { ...upload, isDragging: true } : upload
        ));
    };

    const handleDragLeave = (id) => {
        setUploads(uploads.map(upload =>
            upload.id === id ? { ...upload, isDragging: false } : upload
        ));
    };

    const handleDrop = (e, id) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        if (file) {
            const newUploads = uploads.map((upload) =>
                upload.id === id ? { ...upload, uploadedImage: URL.createObjectURL(file), isDragging: false } : upload
            );
            setUploads(newUploads);
        }
    };

    const handleDelete = (id) => {
        setUploads(uploads.map(upload =>
            upload.id === id ? { ...upload, uploadedImage: null } : upload
        ));
    };

    return (
        <div className="community-uploads">
            <div className='row mb-5'>
                {uploads.map((upload) => (
                    <div className='col-md-4'>
                        <div key={upload.id} className={`community-upload-box ${upload.uploadedImage ? 'image-uploaded' : ''} ${upload.isDragging ? 'dragging' : ''}`}>
                            <div className='md-title fw-500 text-white mb-3'>Photo {upload.id}</div>
                            <div
                                className="com-upload-box d-flex align-items-center justify-content-center"
                                onDragOver={(e) => handleDragOver(e, upload.id)}
                                onDragLeave={() => handleDragLeave(upload.id)}
                                onDrop={(e) => handleDrop(e, upload.id)}
                            >
                                {!upload.uploadedImage ? (
                                    <div>
                                        
                                        <input
                                            type="file"
                                            id={`fileUpload-${upload.id}`}
                                            style={{ display: 'none' }}
                                            onChange={(e) => handleImageUpload(e, upload.id)}
                                            accept="image/*"
                                        />
                                        <label htmlFor={`fileUpload-${upload.id}`} className='upload-input d-flex align-items-center justify-content-center'>
                                            <div>
                                                <div className='text-center mb-2'>
                                                    <img src={getImages('icon-upload-photo.svg')} alt="Upload icon" />
                                                </div>
                                                <div className='sm-title fw-600 nunito-sans text-center text-uppercase text-gray'>Drag Photo</div>
                                            </div>
                                        </label>
                                    </div>
                                ) : (
                                    <div className='uploaded-image'>
                                        <img src={upload.uploadedImage} alt="Uploaded" />
                                    </div>
                                )}
                            </div>
                            <div className='d-flex align-items-center mt-4 justify-content-between'>
                                <div>
                                    <button type='button' className='mw-del-btn' onClick={() => handleDelete(upload.id)}>
                                        <img className='me-2' src={getImages('icon-delete-photo.svg')} alt="Delete icon" /> <span>Delete</span>
                                    </button>
                                </div>
                                <div>
                                    <input
                                        type="file"
                                        id={`fileUpload-${upload.id}`}
                                        style={{ display: 'none' }}
                                        onChange={(e) => handleImageUpload(e, upload.id)}
                                        accept="image/*"
                                    />
                                    <label htmlFor={`fileUpload-${upload.id}`} className='blue-btn text-uppercase'>Upload</label>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default CommunityUpload;
