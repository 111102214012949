import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';

const FullWidthScollerPlot = ({ data, width, height, xAxisColor, yAxisColor, isVisible, svgColor }) => {
  const [svgWidth, setSvgWidth] = useState(0);
  const svgRef = useRef();
  const tooltipRef = useRef(null);
  const isWidthPercentage = width === "100%";
  const colorWithOpacity = svgColor.replace('rgb(', 'rgba(').replace(')', ', 0.2)');

  useEffect(() => {
    const handleResize = () => {
      if (svgRef.current) {
        const containerWidth = svgRef.current.parentElement.clientWidth;
        setSvgWidth(isWidthPercentage ? containerWidth : parseFloat(width));
      }
    };

    if (isVisible) {
        handleResize(); // Initialize dimensions when tab is visible
        window.addEventListener('resize', handleResize);
      }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [width, isVisible]);

  useEffect(() => {
    if (!data || svgWidth === 0) return;

    const margin = { top: 20, right: 50, bottom: 10, left: 65 };
    const boundsWidth = svgWidth + margin.right + margin.left;
    const boundsHeight = height - margin.top - margin.bottom;

    const svg = d3.select(svgRef.current);
    svg.selectAll('*').remove(); // Clear previous content

    const x = d3
      .scaleBand()
      .domain(data.map(d => d.x))
      .range([0, boundsWidth])
      .padding(0);

    const roundedDomain = [0, 5000];
    const y = d3
      .scaleLinear()
      .domain(roundedDomain)
      .nice()
      .range([boundsHeight, 20]);

    const area = d3
      .area()
      .x(d => x(d.x) + x.bandwidth() / 2)
      .y0(boundsHeight)
      .y1(d => y(d.y));

    // Define the gradient
    const gradient = svg.append('defs')
      .append('linearGradient')
      .attr('id', 'areaGradient')
      .attr('gradientTransform', 'rotate(90)');

    gradient.append('stop')
      .attr('offset', '0%')
      .attr('stop-color', svgColor);

    gradient.append('stop')
      .attr('offset', '100%')
      .attr('stop-color', colorWithOpacity);

    svg.append('path')
      .datum(data)
      .attr('class', 'area')
      .attr('fill', 'url(#areaGradient)')
      .attr('opacity', 0.5)
      .attr('d', area);

    svg
      .selectAll('.dot')
      .data(data)
      .enter()
      .append('circle')
      .attr('class', 'dot')
      .attr('cx', d => x(d.x) + x.bandwidth() / 2)
      .attr('cy', d => y(d.y))
      .attr('r', 5)
      .attr('fill', svgColor)
      .on('mouseover', (event, d) => {
        const [xPos, yPos] = d3.pointer(event);
        const formatComma = d3.format(',');
        d3.select(tooltipRef.current)
          .style('opacity', 1)
          .style('left', `${xPos - 80}px`)
          .style('top', `${yPos - 80}px`)
          .html(`<div class='w-100'><div class='svg-tooltip-label'>${d.x}</div><div class='svg-tooltip-value' style='color: ${svgColor}'>${formatComma(d.y)}</div></div>`);
      })
      .on('mouseout', () => {
        d3.select(tooltipRef.current)
          .style('opacity', 0);
      });

    svg
      .selectAll('.line')
      .data(data.slice(1))
      .enter()
      .append('line')
      .attr('class', 'line')
      .attr('x1', (d, i) => x(data[i].x) + x.bandwidth() / 2)
      .attr('y1', (d, i) => y(data[i].y))
      .attr('x2', (d, i) => x(data[i + 1].x) + x.bandwidth() / 2)
      .attr('y2', (d, i) => y(data[i + 1].y))
      .attr('stroke', svgColor)
      .attr('stroke-width', 2)
      .attr('fill', 'none');

    // Add average line
    // svg.append('line')
    //   .attr('x1', 0)
    //   .attr('y1', y(d3.max(data, d => d.y)))
    //   .attr('x2', boundsWidth)
    //   .attr('y2', y(d3.min(data, d => d.y)))
    //   .attr('stroke', 'rgb(255 255 255 / 30%)')
    //   .attr('stroke-width', 2)
    //   .attr('stroke-dasharray', '5,5');

    // X Axis
    svg
      .append('g')
      .attr('transform', `translate(0, ${boundsHeight})`)
      .call(d3.axisBottom(x))
      .selectAll('text')
      .style('fill', xAxisColor);

    // Y Axis
    svg
      .append('g')
      .style('transform', `translate(35px, -10px)`)
      .call(d3.axisLeft(y).ticks(6))
      .selectAll('text')
      .style('fill', yAxisColor);

    const yAxis = svg
      .append("g")
      .call(d3.axisLeft(y).ticks(5).tickSizeOuter(0)); 

    yAxis.selectAll(".tick").each(function (d) {
        svg
          .append("line")
          .attr("x1", 0)
          .attr("y1", y(d))
          .attr("x2", boundsWidth)
          .attr("y2", y(d))
          .attr("stroke", "rgb(255 255 255 / 50%)")
          .attr("stroke-width", 0.5)
      });

    d3.selectAll('.domain').remove();
    svg.selectAll('.tick line').remove();

  }, [data, svgWidth, height, xAxisColor, yAxisColor]);

  return (
    <div style={{ position: 'relative' }}>
        <svg
        ref={svgRef}
        width={isWidthPercentage ? '100%' : svgWidth}
        height={height}
        viewBox={`0 0 ${svgWidth} ${height}`}
        />
        <div
            ref={tooltipRef}
            className="svg-tooltip"
            style={{
            position: 'absolute',
            opacity: 0,
            backgroundColor: '#fff',
            border: '1px solid #ccc',
            padding: '5px',
            borderRadius: '3px',
            pointerEvents: 'none',
            }}
        ></div>
    </div>
  );
};

export default FullWidthScollerPlot;
