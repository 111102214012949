import React from 'react'
import Layout from '../Components/Layouts/Layout'
import TopSection from '../Components/Common/TopSection'
import { getImages } from '../Components/Const'

const AvailableInventory = () => {
    return (
        <>
            <Layout>
                <TopSection />
                <div className='main-block py-4'>
                    <div className='container-fluid'>
                        <div className='gray-box p-5'>
                            <div className='d-flex align-items-center justify-content-between'>
                                <div className='lg-title text-uppercase font-26'>Available Inventory</div>
                                <div className='d-flex'>
                                    <div className="search-box position-relative me-5 vehicle-search">
                                        <input type="text" className="form-control" placeholder="Vehicle Search" />
                                        <span className="search-icon"><i className="fa-solid fa-magnifying-glass"></i></span>
                                    </div>
                                    <button className="blue-btn mnw-170 text-center text-uppercase" type="button"><span className="add-icon"></span> Add Vehicle</button>
                                </div>
                            </div>
                            <div className='custom-table2 mt-5'>
                                <table className='w-100'>
                                    <thead>
                                        <tr>
                                            <th>&nbsp;</th>
                                            <th>Vehicle Details</th>
                                            <th>VIN</th>
                                            <th className='text-center'>Ext Color</th>
                                            <th className='text-center'>Miles</th>
                                            <th className='text-center'>Visible</th>
                                            <th className='text-center'>&nbsp;</th>
                                            <th className='text-center'>&nbsp;</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <img src={getImages('inv-icon1.png')} alt='Inventory' />
                                            </td>
                                            <td>
                                                <div className='inv-title1 fw-500'>2018 Porsche</div>
                                                <div className='inv-title2'>Macan Sport, Cayman</div>
                                            </td>
                                            <td>
                                                <div className='d-flex align-items-center'>
                                                    <div className='inv-vin-no'>JTERU5JR1N5978664</div>
                                                    <div className='ms-2'>
                                                        <button type='button' className='copy-icon'></button>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className='inv-title1 text-center'>White</td>
                                            <td className='inv-title1 text-center'>18.2k</td>
                                            <td className='text-center'>
                                                <div className='custom-switch'>
                                                    <input type="checkbox" id="switch1" />
                                                    <label htmlFor="switch1">Toggle</label>
                                                </div>
                                            </td>
                                            <td className='w-5 text-center'>
                                                <button className='edit-icon' type='button'></button>
                                            </td>
                                            <td className='w-15 text-center'>
                                                <button className="text-nowrap blue-transparent-btn text-uppercase" type="button">Mark as Sold</button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <img src={getImages('inv-icon1.png')} alt='Inventory' />
                                            </td>
                                            <td>
                                                <div className='inv-title1 fw-500'>2018 Porsche</div>
                                                <div className='inv-title2'>Macan Sport, Cayman</div>
                                            </td>
                                            <td>
                                                <div className='d-flex align-items-center'>
                                                    <div className='inv-vin-no'>JTERU5JR1N5978664</div>
                                                    <div className='ms-2'>
                                                        <button type='button' className='copy-icon'></button>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className='inv-title1 text-center'>White</td>
                                            <td className='inv-title1 text-center'>18.2k</td>
                                            <td className='text-center'>
                                                <div className='custom-switch'>
                                                    <input type="checkbox" id="switch2" />
                                                    <label htmlFor="switch2">Toggle</label>
                                                </div>
                                            </td>
                                            <td className='w-5 text-center'>
                                                <button className='edit-icon' type='button'></button>
                                            </td>
                                            <td className='w-15 text-center'>
                                                <button className="text-nowrap blue-transparent-btn text-uppercase" type="button">Mark as Sold</button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <img src={getImages('inv-icon1.png')} alt='Inventory' />
                                            </td>
                                            <td>
                                                <div className='inv-title1 fw-500'>2018 Porsche</div>
                                                <div className='inv-title2'>Macan Sport, Cayman</div>
                                            </td>
                                            <td>
                                                <div className='d-flex align-items-center'>
                                                    <div className='inv-vin-no'>JTERU5JR1N5978664</div>
                                                    <div className='ms-2'>
                                                        <button type='button' className='copy-icon'></button>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className='inv-title1 text-center'>White</td>
                                            <td className='inv-title1 text-center'>18.2k</td>
                                            <td className='text-center'>
                                                <div className='custom-switch'>
                                                    <input type="checkbox" id="switch3" />
                                                    <label htmlFor="switch3">Toggle</label>
                                                </div>
                                            </td>
                                            <td className='w-5 text-center'>
                                                <button className='edit-icon' type='button'></button>
                                            </td>
                                            <td className='w-15 text-center'>
                                                <button className="text-nowrap blue-transparent-btn text-uppercase" type="button">Mark as Sold</button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <img src={getImages('inv-icon1.png')} alt='Inventory' />
                                            </td>
                                            <td>
                                                <div className='inv-title1 fw-500'>2018 Porsche</div>
                                                <div className='inv-title2'>Macan Sport, Cayman</div>
                                            </td>
                                            <td>
                                                <div className='d-flex align-items-center'>
                                                    <div className='inv-vin-no'>JTERU5JR1N5978664</div>
                                                    <div className='ms-2'>
                                                        <button type='button' className='copy-icon'></button>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className='inv-title1 text-center'>White</td>
                                            <td className='inv-title1 text-center'>18.2k</td>
                                            <td className='text-center'>
                                                <div className='custom-switch'>
                                                    <input type="checkbox" id="switch4" />
                                                    <label htmlFor="switch4">Toggle</label>
                                                </div>
                                            </td>
                                            <td className='w-5 text-center'>
                                                <button className='edit-icon' type='button'></button>
                                            </td>
                                            <td className='w-15 text-center'>
                                                <button className="text-nowrap blue-transparent-btn text-uppercase" type="button">Mark as Sold</button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <img src={getImages('inv-icon1.png')} alt='Inventory' />
                                            </td>
                                            <td>
                                                <div className='inv-title1 fw-500'>2018 Porsche</div>
                                                <div className='inv-title2'>Macan Sport, Cayman</div>
                                            </td>
                                            <td>
                                                <div className='d-flex align-items-center'>
                                                    <div className='inv-vin-no'>JTERU5JR1N5978664</div>
                                                    <div className='ms-2'>
                                                        <button type='button' className='copy-icon'></button>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className='inv-title1 text-center'>White</td>
                                            <td className='inv-title1 text-center'>18.2k</td>
                                            <td className='text-center'>
                                                <div className='custom-switch'>
                                                    <input type="checkbox" id="switch5" />
                                                    <label htmlFor="switch5">Toggle</label>
                                                </div>
                                            </td>
                                            <td className='w-5 text-center'>
                                                <button className='edit-icon' type='button'></button>
                                            </td>
                                            <td className='w-15 text-center'>
                                                <button className="text-nowrap blue-transparent-btn text-uppercase" type="button">Mark as Sold</button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <img src={getImages('inv-icon1.png')} alt='Inventory' />
                                            </td>
                                            <td>
                                                <div className='inv-title1 fw-500'>2018 Porsche</div>
                                                <div className='inv-title2'>Macan Sport, Cayman</div>
                                            </td>
                                            <td>
                                                <div className='d-flex align-items-center'>
                                                    <div className='inv-vin-no'>JTERU5JR1N5978664</div>
                                                    <div className='ms-2'>
                                                        <button type='button' className='copy-icon'></button>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className='inv-title1 text-center'>White</td>
                                            <td className='inv-title1 text-center'>18.2k</td>
                                            <td className='text-center'>
                                                <div className='custom-switch'>
                                                    <input type="checkbox" id="switch1" />
                                                    <label htmlFor="switch1">Toggle</label>
                                                </div>
                                            </td>
                                            <td className='w-5 text-center'>
                                                <button className='edit-icon' type='button'></button>
                                            </td>
                                            <td className='w-15 text-center'>
                                                <button className="text-nowrap blue-transparent-btn text-uppercase" type="button">Mark as Sold</button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <img src={getImages('inv-icon1.png')} alt='Inventory' />
                                            </td>
                                            <td>
                                                <div className='inv-title1 fw-500'>2018 Porsche</div>
                                                <div className='inv-title2'>Macan Sport, Cayman</div>
                                            </td>
                                            <td>
                                                <div className='d-flex align-items-center'>
                                                    <div className='inv-vin-no'>JTERU5JR1N5978664</div>
                                                    <div className='ms-2'>
                                                        <button type='button' className='copy-icon'></button>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className='inv-title1 text-center'>White</td>
                                            <td className='inv-title1 text-center'>18.2k</td>
                                            <td className='text-center'>
                                                <div className='custom-switch'>
                                                    <input type="checkbox" id="switch2" />
                                                    <label htmlFor="switch2">Toggle</label>
                                                </div>
                                            </td>
                                            <td className='w-5 text-center'>
                                                <button className='edit-icon' type='button'></button>
                                            </td>
                                            <td className='w-15 text-center'>
                                                <button className="text-nowrap blue-transparent-btn text-uppercase" type="button">Mark as Sold</button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <img src={getImages('inv-icon1.png')} alt='Inventory' />
                                            </td>
                                            <td>
                                                <div className='inv-title1 fw-500'>2018 Porsche</div>
                                                <div className='inv-title2'>Macan Sport, Cayman</div>
                                            </td>
                                            <td>
                                                <div className='d-flex align-items-center'>
                                                    <div className='inv-vin-no'>JTERU5JR1N5978664</div>
                                                    <div className='ms-2'>
                                                        <button type='button' className='copy-icon'></button>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className='inv-title1 text-center'>White</td>
                                            <td className='inv-title1 text-center'>18.2k</td>
                                            <td className='text-center'>
                                                <div className='custom-switch'>
                                                    <input type="checkbox" id="switch3" />
                                                    <label htmlFor="switch3">Toggle</label>
                                                </div>
                                            </td>
                                            <td className='w-5 text-center'>
                                                <button className='edit-icon' type='button'></button>
                                            </td>
                                            <td className='w-15 text-center'>
                                                <button className="text-nowrap blue-transparent-btn text-uppercase" type="button">Mark as Sold</button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <img src={getImages('inv-icon1.png')} alt='Inventory' />
                                            </td>
                                            <td>
                                                <div className='inv-title1 fw-500'>2018 Porsche</div>
                                                <div className='inv-title2'>Macan Sport, Cayman</div>
                                            </td>
                                            <td>
                                                <div className='d-flex align-items-center'>
                                                    <div className='inv-vin-no'>JTERU5JR1N5978664</div>
                                                    <div className='ms-2'>
                                                        <button type='button' className='copy-icon'></button>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className='inv-title1 text-center'>White</td>
                                            <td className='inv-title1 text-center'>18.2k</td>
                                            <td className='text-center'>
                                                <div className='custom-switch'>
                                                    <input type="checkbox" id="switch4" />
                                                    <label htmlFor="switch4">Toggle</label>
                                                </div>
                                            </td>
                                            <td className='w-5 text-center'>
                                                <button className='edit-icon' type='button'></button>
                                            </td>
                                            <td className='w-15 text-center'>
                                                <button className="text-nowrap blue-transparent-btn text-uppercase" type="button">Mark as Sold</button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <img src={getImages('inv-icon1.png')} alt='Inventory' />
                                            </td>
                                            <td>
                                                <div className='inv-title1 fw-500'>2018 Porsche</div>
                                                <div className='inv-title2'>Macan Sport, Cayman</div>
                                            </td>
                                            <td>
                                                <div className='d-flex align-items-center'>
                                                    <div className='inv-vin-no'>JTERU5JR1N5978664</div>
                                                    <div className='ms-2'>
                                                        <button type='button' className='copy-icon'></button>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className='inv-title1 text-center'>White</td>
                                            <td className='inv-title1 text-center'>18.2k</td>
                                            <td className='text-center'>
                                                <div className='custom-switch'>
                                                    <input type="checkbox" id="switch5" />
                                                    <label htmlFor="switch5">Toggle</label>
                                                </div>
                                            </td>
                                            <td className='w-5 text-center'>
                                                <button className='edit-icon' type='button'></button>
                                            </td>
                                            <td className='w-15 text-center'>
                                                <button className="text-nowrap blue-transparent-btn text-uppercase" type="button">Mark as Sold</button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className='mt-5 text-center custom-pagination'>
                                <nav aria-label="Page navigation example" className='d-inline-block'>
                                    <ul className="pagination">
                                        <li className="page-item"><a className="page-link back-btn me-5" href="#">Back</a></li>
                                        <li className="page-item"><a className="page-link" href="#">1</a></li>
                                        <li className="page-item"><a className="page-link active" href="#">2</a></li>
                                        <li className="page-item"><a className="page-link" href="#">3</a></li>
                                        <li className="page-item"><a className="page-link" href="#">4</a></li>
                                        <li className="page-item"><a className="page-link" href="#">5</a></li>
                                        <li className="page-item"><a className="page-link" href="#">6</a></li>
                                        <li className="page-item"><a className="page-link next-btn ms-5" href="#">Next</a></li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default AvailableInventory