import React, { useState, useContext, useEffect } from 'react'
import ModalLayout from '../Modals/ModalLayout';
import QuickUpModal from '../Modals/QuickUpModal';
import AutoComplete from './AutoComplete';
import TaskModal from '../Modals/TaskModal';
import LeadReplies from '../Modals/LeadReplies';
import { LeadsContext } from '../Context/LeadsContext';

const TopSection = () => {

    const {leadData} = useContext(LeadsContext);

    const [taskCount,setTaskCount] = useState(0);
    const [replyCount,setReplyCount] = useState(0);

    const [quickOpen, setQuickOpen] = useState(false);
    const handleQuickUp = () => {
        setQuickOpen(true);
    }
    const quickClose = () => {
        setQuickOpen(false);
    }
    // Tasks Modal
    const [taskModal, setTaskModal] = useState(false);
    const handleTasksOpen = () => {
        setTaskModal(true);
    }
    const handleTasksClose = () => {
        setTaskModal(false);
    }
    // Replies Modal
    const [repliesModal, setRepliesModal] = useState(false);
    const repliesModalOpen = (value) => {
        setRepliesModal(value);
    }

    return (
        <>
            <div className='topbar-block'>
                <div className='container-fluid'>
                    <div className='d-flex align-items-center justify-content-between topbar-btns'>
                        <div>
                            <button type="button" onClick={() => {repliesModalOpen(true)}} className='transparent-btn yellow-border d-inline-block me-3 text-uppercase'>
                                <span className='exclamation-icon'><i className="fa-solid fa-triangle-exclamation"></i></span> Un-Answered <span className='count ms-3'>{replyCount}</span>
                            </button>
                            <button type="button" onClick={handleTasksOpen} className='transparent-btn d-inline-block'>
                                <span className='setting-icon'></span> TASKS <span className='count ms-3'>{taskCount}</span>
                            </button>
                        </div>
                        <div className='d-flex align-items-center'>
                            <div className='d-none d-lg-block'>
                                <button className='blue-btn mnw-170 text-start' type='button' onClick={handleQuickUp}>
                                    <span className='upload-icon me-3'></span>QUICK UP
                                </button>
                            </div>
                            {/* <div className='search-box position-relative ms-5'>
                                <input type='text' className='form-control' placeholder='Search' />
                                <span className='search-icon'><i className="fa-solid fa-magnifying-glass"></i></span>
                            </div> */}
                            <div className='d-none d-lg-block'><AutoComplete/></div>
                        </div>
                    </div>
                </div>
            </div>
            <ModalLayout open={quickOpen} close={quickClose}>
                <QuickUpModal close={quickClose}/>
            </ModalLayout>
            <ModalLayout open={taskModal} close={handleTasksClose}>
                <TaskModal close={handleTasksClose} setTaskCount={setTaskCount}/>
            </ModalLayout>
            <ModalLayout open={repliesModal} close={() => {repliesModalOpen(false)}}>
                <LeadReplies close={() => {repliesModalOpen(false)}} setReplyCount={setReplyCount}/>
            </ModalLayout>
        </>
    )
}

export default TopSection