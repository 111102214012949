import React, { useState, useEffect, useContext } from 'react'
import { getImages } from '../Const';
import LeadTaskItem from './LeadTaskItem';
import { LeadsContext } from '../Context/LeadsContext';
import { UserContext } from '../Context/UserContext';

const LeadItemAccordion = ({ leadData, openPlainTextEmail, textLeadModal }) => {
    const [taskStates, setTaskStates] = useState([]);

    const { updateTask, addNote, archiveLead, getImageType } = useContext(LeadsContext);

    const { user } = useContext(UserContext);
    useEffect(() => {
        if (leadData.tasks) {
            setTaskStates(leadData.tasks);
        }
    }, [leadData.tasks]);
    const handleRadioChange = (index, value) => {
        console.log("radio change", index, value, taskStates[index]);
        const newTaskStates = [...taskStates];
        newTaskStates[index]['completed'] = value;
        setTaskStates(newTaskStates);

        console.log(leadData.tasks[index]);

        updateTask(leadData.id, leadData.tasks[index].id, value);
    };

    const [note, setNote] = useState("");

    const submitNoteForm = async (e) => {
        e.preventDefault();

        const formData = Object.fromEntries(new FormData(e.target));

        const response = await addNote(formData);

        if (response && response.success) {
            //close();
            //alert('Your email has been sent');

            setNote("");

            leadData.notes.push(formData);
        } else alert('Error adding note');
    }

    
    const [openInsideText, setOpenInsideText] = useState('')
    const handleInsideTabs = (tabsNameText) => {
        setOpenInsideText(tabsNameText);
        document.body.classList.add('mob-overflow-body');
    }
    const closeInsideText = () => {
        setOpenInsideText('');
        document.body.classList.remove('mob-overflow-body');
    }

    const getLeadLink = (id) => {
        window.open("http://dashboard.kentmotorclub.com/?lead_id=" + id, "_blank", "noreferrer");
        //navigator.clipboard.writeText("http://dashboard.kentmotorclub.com/?email=" + email + "&vin=" + vin);
       // alert("Link copied to clipboard");
    }

    return (
        <>
            <table className='w-100'>
                <tbody>
                    <tr>
                        <td colSpan={8}>
                            <div className='px-5 pb-4'>
                                <div className='row'>
                                    <div className='col-lg-6'>
                                        <div className='inside-tab-block'>
                                            <nav className='inside-tabs'>
                                                <div className="nav nav-tabs" id="al-tab" role="tablist">
                                                    <button className="nav-link" id={`al-dealstage-tab${leadData.lead_ui_id}`} data-bs-toggle="tab" data-bs-target={`#nav-dealstage${leadData.lead_ui_id}`} type="button" role="tab" aria-controls={`nav-dealstage${leadData.lead_ui_id}`} aria-selected="true" onClick={() => {handleInsideTabs('DealStage')}}>
                                                        <div>Deal Stage</div>
                                                        <div className='d-lg-none'><img src={getImages('right-arrow.svg')} /></div>
                                                    </button>
                                                    <button className="nav-link active" id={`nav-task-tab${leadData.lead_ui_id}`} data-bs-toggle="tab" data-bs-target={`#nav-task${leadData.lead_ui_id}`} type="button" role="tab" aria-controls={`nav-task${leadData.lead_ui_id}`} aria-selected="false" onClick={() => {handleInsideTabs('Task')}}>
                                                        <div>TASK</div>
                                                        <div className='d-lg-none'><img src={getImages('right-arrow.svg')} /></div>
                                                    </button>
                                                    {/*<button className="nav-link" id={`nav-history-tab${leadData.lead_ui_id}`} data-bs-toggle="tab" data-bs-target={`#nav-history${leadData.lead_ui_id}`} type="button" role="tab" aria-controls={`nav-history${leadData.lead_ui_id}`} aria-selected="false" onClick={() => handleInsideTabs('History')}>
                                                        <div>HISTORY</div>
                                                        <div className='d-lg-none'><img src={getImages('right-arrow.svg')} /></div>
                                                    </button>*/}
                                                    <button className="nav-link d-lg-none" type="button" role="tab" onClick={() => {handleInsideTabs('Notes')}}>
                                                        <div>Notes</div>
                                                        <div className='d-lg-none'><img src={getImages('right-arrow.svg')} /></div>
                                                    </button>
                                                </div>
                                            </nav>
                                            <div className={`tab-content ${openInsideText === 'DealStage' || openInsideText === 'Task' || openInsideText === 'History' ? 'show' : ''}`} id="al-tabContent">
                                                <div className='d-lg-none'>
                                                    <button className='mob-back-btn p-0' type='button' onClick={closeInsideText}><span className='ps-0'><img src={getImages('left-arrow.svg')} /></span> Back</button>
                                                    <div className='main-heading text-uppercase'>
                                                        {(() => {
                                                            if (openInsideText === 'DealStage') {
                                                                return 'Deal Stage' 
                                                            } else {
                                                                return 'Task'
                                                            }
                                                        })()}
                                                    </div>
                                                </div>
                                                <div className={`tab-panel fade inside-tab-content ${openInsideText === 'DealStage' ? 'selected' : ''}`} id={`nav-dealstage${leadData.lead_ui_id}`} role="tabpanel" aria-labelledby={`al-dealstage-tab${leadData.lead_ui_id}`} tabIndex="0">
                                                    <div className='white-box'>
                                                        <div className='d-flex justify-content-between cs-processbar'>
                                                            <div className='pb-col active'>
                                                                <span className='circle'></span>
                                                                <span className='count'>1</span>
                                                            </div>
                                                            <div className='pb-col active'>
                                                                <span className='circle'></span>
                                                                <span className='count'>2</span>
                                                            </div>
                                                            <div className='pb-col active'>
                                                                <span className='circle'></span>
                                                                <span className='count'>3</span>
                                                            </div>
                                                            <div className='pb-col'>
                                                                <span className='circle'></span>
                                                                <span className='count'>4</span>
                                                            </div>
                                                            <div className='pb-col'>
                                                                <span className='circle'></span>
                                                                <span className='count'>5</span>
                                                            </div>
                                                        </div>
                                                        <div className='sm-title d-text-blue text-center text-uppercase mt-4'>Step 3:</div>
                                                        <div className='md-title mt-2 text-center'>Waiting on Client Paperwork</div>
                                                        <div className='next-task-box mt-4'>
                                                            <div className='sm-title d-text-blue text-center text-uppercase'>Next Contact Task</div>
                                                            <div className='md-title mt-2 text-center'>Jan 18th</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={`tab-panel fade show active inside-tab-content ${openInsideText === 'Task' ? 'selected' : ''}`} id={`nav-task${leadData.lead_ui_id}`} role="tabpanel" aria-labelledby={`nav-contact-tab${leadData.lead_ui_id}`} tabIndex="0">
                                                    <div className='white-box py-4 px-3 mnh-315'>
                                                        <div className='task-table'>
                                                            <table className='w-100'>
                                                                <tbody>
                                                                    <tr style={{ borderStyle: 'hidden' }}>
                                                                        {/* <td className=''></td> */}
                                                                        <td colSpan={2} className='task-call-count text-end'>Completed?</td>
                                                                    </tr>
                                                                    {taskStates?.map((task, index) =>
                                                                        <LeadTaskItem
                                                                            key={index}
                                                                            index={index}
                                                                            taskData={task}
                                                                            handleRadioChange={handleRadioChange}
                                                                            lead_ui_id={leadData.lead_ui_id}
                                                                        />
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={`tab-panel fade inside-tab-content ${openInsideText === 'History' ? 'selected' : ''}`} id={`nav-history${leadData.lead_ui_id}`} role="tabpanel" aria-labelledby="nav-history-tab" tabIndex="0">
                                                    <div className='white-box'></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`col-lg-6 inside-tab-content inside-note-content ${openInsideText === 'Notes' ? 'selected' : ''}`}>
                                        <div className='d-lg-none'>
                                            <button className='mob-back-btn p-0' type='button' onClick={closeInsideText}><span className='ps-0'><img src={getImages('left-arrow.svg')} /></span> Back</button>
                                            <div className='main-heading text-uppercase'>Notes</div>
                                        </div>
                                        <div className='xs-title text-uppercase mb-3 d-none d-lg-block'>Notes</div>
                                        <div className='white-box notes-box p-4'>
                                            <div className='notes-scroll'>
                                                {leadData.notes && leadData.notes.map((v, i) => {
                                                    const createdAtDate = new Date(v.created_at + " UTC");
                                                    return (
                                                        <div className='d-flex align-items-start mb-4' key={i}>
                                                            <div className='mnw-icon'>
                                                                <img src={getImages(getImageType(v.title))} alt='envlope' />
                                                            </div>
                                                            <div className='ps-2'>
                                                                <div className='pd-sm-title text-blue text-uppercase'>{v.title}</div>
                                                                <div className='pd-xs-title mt-2' dangerouslySetInnerHTML={{__html: v.note}}/>
                                                            </div>
                                                            <div className='ms-auto'>
                                                                <div className='d-flex'>
                                                                    <div className='pd-xs-title'>{createdAtDate.toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true })}</div>
                                                                    <div className='pd-xs-title ms-4'>{createdAtDate.toLocaleString('en-US', { year: "2-digit", month: "2-digit", day: "numeric" })}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                        <form className='note-footer' onSubmit={(e) => submitNoteForm(e)}>
                                            <div className='d-flex align-items-center note-footer-flex w-100'>
                                                <input type="hidden" name="lead_id" value={leadData.id} />
                                                <input type="hidden" name="title" value="Agent Note" />
                                                <input type="hidden" name="subtitle" value={user && user.first_name && user.last_name ? user.first_name + " " + user.last_name : ""} />
                                                <input type='text' className='form-control' name="note" value={note} onChange={(e) => setNote(e.target.value)} />
                                                <button className='note-btn' type='submit'>Enter</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div className='d-lg-block mob-btns-actions'>
                                    <div className='mt-30 d-flex flex-wrap'>
                                        <div className='me-4'>
                                            <button className='blue-transparent-btn text-uppercase' type='button' onClick={(e) => openPlainTextEmail(leadData)}>PLAIN TEXT EMAIL</button>
                                        </div>
                                        <div className='me-4'>
                                            <button className='blue-transparent-btn text-uppercase' type='button' onClick={(e) => textLeadModal(leadData)}>TEXT LEAD</button>
                                        </div>
                                        <div className='me-4 d-none d-lg-block'>
                                            <button className='blue-transparent-btn text-uppercase' type='button' onClick={(e) => archiveLead(leadData)}>Archive LEAD</button>
                                        </div>
                                        <div className='me-4 d-none d-lg-block'>
                                            <button className='blue-transparent-btn text-uppercase' type='button' onClick={(e) => getLeadLink(leadData.id)}>Get LEAD Link</button>
                                        </div>
                                        {/* <div className='me-4'>
                                            <button className='blue-transparent-btn text-uppercase' type='button'>Template Email</button>
                                        </div>
                                        <div className='me-4'>
                                            <button className='blue-transparent-btn text-uppercase' type='button'>APPRAISAL EMAIL</button>
                                        </div> */}
                                        
                                        {/* <div className='me-4'>
                                            <button className='blue-transparent-btn text-uppercase' type='button'>SET APPOINTMENT</button>
                                        </div>
                                        <div className='ms-auto me-4'>
                                            <button className='blue-transparent-btn text-uppercase add-more-btn' type='button'>
                                                <span className='plus-icon'></span> MORE OPTIONS
                                            </button>
                                        </div> */}
                                        <div className='ms-auto'>
                                            <button className='blue-btn' type='button'>
                                                SAVE UPDATES
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </>
    )
}

export default LeadItemAccordion