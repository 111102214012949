import React, { useContext, useEffect, useState } from 'react';
import { getImages } from '../Const'
import LeadItemAccordion from './LeadItemAccordion';
import Fancybox from '../Front/Fancybox';
import { LeadsContext } from '../Context/LeadsContext';

const LeadItem = ({ 
        leadData, 
        openPlainTextEmail, 
        handleAccordionClick, 
        openLead, 
        createNoteOpenModal, 
        textLeadModal, 
        handleMobArrow, 
        openDealStageModal,
        openMobArrow,
        mobLeadItem,
        cancelMobLeadItem,
        handleNotesMobileModal,
        openNotesMobModal,
        closeNotesMobModal
    }) => {

    const { agentData, assignAgent, completeNextTask, updateLeadData, getImageType, addNote } = useContext(LeadsContext);

    const [assignee, setAssignee] = useState(leadData.agent_id || "unassigned");

    useEffect(() => {
        //console.log(assignee,leadData,leadData.agent_id,leadData.agent_id || "unassigned");
        setAssignee(leadData.agent_id || "unassigned");
    }, [leadData.agent_id]);

    

    const updateAssignee = (agent_id, lead_id) => {
        // console.log(agent_id, lead_id);
        setAssignee(agent_id);
        assignAgent(agent_id, lead_id);
    }

    const formattedNumber = leadData.phone ? leadData.phone.replace(/[^\d\s]/g, '') : "";
    let vehicleImages = leadData.photos ? leadData.photos.split(",") : [];
    let vehicleImage = vehicleImages.at(0);
    const imageUrl = vehicleImage;
    const [enterVinOpen, setEnterVinOpen] = useState(false);
    let timeoutId;
    const handleMouseEnter = () => {
        clearTimeout(timeoutId);
        setEnterVinOpen(true);
    };
    const handleMouseLeave = () => {
        timeoutId = setTimeout(() => {
            setEnterVinOpen(false);
        }, 500); // 2000 milliseconds = 2 seconds
    };
    const enterVinClose = () => {
        setEnterVinOpen(false)
    }
    // 
    const [appraisalOpenIndex, setAppraisalOpenIndex] = useState(null);
    const [pendingIndex, setPendingIndex] = useState(null);
    let timeoutId1 = null;
    const handleMouseEnter1 = (index) => {
        if (timeoutId1) {
            clearTimeout(timeoutId1);
            timeoutId1 = null;
        }
        setAppraisalOpenIndex(null);
        setTimeout(() => {
            setAppraisalOpenIndex(index);
        }, 0);
    };
    const handleMouseLeave1 = () => {
        timeoutId1 = setTimeout(() => {
            setAppraisalOpenIndex(null);
        }, 0); 
    };
    const handelMobAppraisal = (leadIndex) => {
        setAppraisalOpenIndex(leadIndex)
    }
    const appraisalClose = () => {
        setAppraisalOpenIndex(null);
    }
    // 
    const [notesOpen, setNotesOpen] = useState(null);
    let timeoutId2 = null;
    const handleMouseEnter2 = (index) => {
        if (timeoutId2) {
            clearTimeout(timeoutId2);
            timeoutId2 = null;
        }
        setNotesOpen(null);
        setTimeout(() => {
            setNotesOpen(index);
        }, 0);
    };
    const handleMouseLeave2 = () => {
        timeoutId2 = setTimeout(() => {
            setNotesOpen(null);
        }, 0); 
    };
    const notesClose = () => {
        setNotesOpen(false)
    }
    const [percent, setPercent] = useState(20)
    useEffect(() => {
        const chartElement = document.querySelector('.progress-pie-chart');
        const percentValue = parseFloat(percent);

        const deg = 360 * percent / 100;

        if (percentValue > 50) {
            chartElement.classList.add('gt-50');
        } else {
            chartElement.classList.remove('gt-50');
        }

        const ppcProgressFill = document.querySelector('.ppc-progress-fill');
        ppcProgressFill.style.transform = `rotate(${deg}deg)`;
    }, [percent]);

    // Timer Calculation Function
    // Convert current Time Zone Format
    const [currentUtcTime, setCurrentUtcTime] = useState('');
    // console.log("currentUtcTime", currentUtcTime)
    useEffect(() => {
        const getCurrentUtcTime = () => {
            const now = new Date();
            const utcString = now.toISOString(); // Get UTC time in ISO format
            setCurrentUtcTime(utcString);
        };

        getCurrentUtcTime();
    }, []);
    // Convert current Time Zone Format
    const originalTimestamp = currentUtcTime;
    const dateObj = new Date(originalTimestamp);
    const year = dateObj.getUTCFullYear();
    const month = String(dateObj.getUTCMonth() + 1).padStart(2, '0');
    const day = String(dateObj.getUTCDate()).padStart(2, '0');
    const hours = String(dateObj.getUTCHours()).padStart(2, '0');
    const minutes = String(dateObj.getUTCMinutes()).padStart(2, '0');
    const seconds = String(dateObj.getUTCSeconds()).padStart(2, '0');

    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    // Get Different Between both time
    const createdAtDate = new Date(leadData.created_at);
    const currentUtcTimeDate = new Date(leadData.stop_clock || formattedDate);
    const timeDifference = currentUtcTimeDate.getTime() - createdAtDate.getTime();
    const timeDifferenceInSeconds = Math.abs(timeDifference / 500);
    // let timeDifferenceInMinutes = timeDifferenceInSeconds / 60;

    const [finalTimeDifferent, setFinalTimeDifferent] = useState("");
    const [timeLabel, setTimeLabel] = useState("");
    const [stateCls, setStateCls] = useState("");
    useEffect(() => {
        let timeDifferenceInMinutes = timeDifferenceInSeconds / 60;

        if (timeDifferenceInMinutes > 59) {
            const timeDifferenceInHours = Math.floor(timeDifferenceInMinutes / 60);
            if (timeDifferenceInHours > 24) {
                setFinalTimeDifferent("24+");
            } else {
                setFinalTimeDifferent(timeDifferenceInHours);
            }
            setTimeLabel("hrs");
            setStateCls("red");
        } else {
            timeDifferenceInMinutes = Math.round(timeDifferenceInMinutes);
            // console.log("timeDifferenceInMinutes", timeDifferenceInMinutes)
            setFinalTimeDifferent(timeDifferenceInMinutes);
            if (timeDifferenceInMinutes <= 5) {
                setStateCls("green");
            } else if (timeDifferenceInMinutes >= 6 && timeDifferenceInMinutes <= 10) {
                setStateCls("yellow");
            } else {
                setStateCls("red");
            }
            setTimeLabel("min");
        }
    }, [timeDifferenceInSeconds]);

    useEffect(() => {
        if (document.getElementById(`${openLead}`)) {
            window.scrollTo(0,
                findPosition(document.getElementById(`${openLead}`)));
        }
    }, [openLead]);

    function findPosition(obj) {
        var currenttop = 0;
        if (obj.offsetParent) {
            do {
                currenttop += obj.offsetTop;
            } while ((obj = obj.offsetParent));
            return [currenttop];
        }
    }
    // characters
    const [isTruncated, setIsTruncated] = useState(true);

    const toggleTruncate = () => {
        setIsTruncated(!isTruncated);
    };

    const [emailSent, setEmailSent] = useState(leadData.email_sent);
    const [phoneCall, setPhoneCall] = useState(leadData.phone_call);
    const [appraisal, setAppraisal] = useState(leadData.appraisal);

    useEffect(() => {
        //console.log(assignee,leadData,leadData.agent_id,leadData.agent_id || "unassigned");
        setEmailSent(leadData.email_sent);
        setPhoneCall(leadData.phone_call);
        setAppraisal(leadData.appraisal)
    }, [leadData]);

    
    const completeTask = async (leadData, type) => {
        const task_id = await completeNextTask(leadData.id, type);

        // console.log("leaditem completeTask", leadData.id, leadData.tasks);
        
        if (type == 'email') setEmailSent(!emailSent);
        if (type == 'call') setPhoneCall(!phoneCall);
        if (task_id) {
            var newTaskData = [];

            leadData.tasks.map((v, i) => {
                if (v.id == task_id) v['completed'] = 1;
                newTaskData.push(v);
            });

            leadData.tasks = newTaskData;

        } else {

        }
        //refresh task data
    }

    const addVIN = (e) => {
        e.preventDefault();

        const formData = Object.fromEntries(new FormData(e.target));

        console.log(formData);

        updateLeadData(formData.lead_id, 'vin', formData.vin);

        //refresh task data
    }

    const addAppraisal = (e) => {
        e.preventDefault();

        const formData = Object.fromEntries(new FormData(e.target));

        setAppraisal(formData.appraisal);

        updateLeadData(formData.lead_id, 'appraisal', formData.appraisal);

        const values = {'lead_id':formData.lead_id,'title':'Appraisal Added','subtitle':leadData.agent_name,'note':formData.appraisal};

        addNote(values);

        //refresh task data
    }

    const floatFormatter = (value, currency = false) => {
        var num = value ? value.toString().replace(/[^0-9\.]+/g, "") : 0;
        var thousands = num > 500;

        if (thousands) num = Math.round(num / 100) / 10;

        var sign = num >= 0 ? "" : "-";
        var str = num.toString().replace("$", ""), parts = false, output = [], i = 1, formatted = null;
        if (str.indexOf(".") > 0) {
            parts = str.split(".");
            str = parts[0];
        }
        str = str.split("").reverse();
        for (var j = 0, len = str.length; j < len; j++) {
            if (str[j] != ",") {
                output.push(str[j]);
                if (i % 3 == 0 && j < (len - 1)) {
                    output.push(",");
                }
                i++;
            }
        }
        formatted = output.reverse().join("");
        return ((currency ? "$" : "") + sign + formatted + ((parts) ? "." + parts[1].substr(0, 2) : "") + (thousands ? "k" : ""));
    }

    const dealStages = [
        'Waiting Response', 
        'Agent Responded', 
        'Appraisal Given', 
        'Appraisal Accepted', 
        'Passed to Finalizers'
    ];

    const getLeadSource = (channel) => {
        var image = getImages('phone-lead-icon.svg');
        switch(channel) {
            case "Paid Search":
            case "Display":
            case "Cross-Network":
            case "Paid Search":
            case "Paid Shopping":
            case "Affiliates":
                image = getImages('google-lead-icon.svg'); break;
            case "Referral": image = getImages('community-lead-icon.svg'); break;
            case "Organic Shopping": 
            case "Organic Search": 
                image = getImages('seo-lead-icon.svg'); break;
            case "Quick Up": image = getImages('store-lead-icon.svg'); break;
            case "Direct": image = getImages('direct-lead-icon.svg'); break;
            case "Paid Social": image = getImages('paid-lead-icon.svg'); break;
            case "Organic Social": image = getImages('organic-lead-icon.svg'); break;
            case "Email": image = getImages('email-lead-icon.svg'); break;
            case "SMS": image = getImages('sms-lead-icon.svg'); break;
            case "Audio": 
                image = getImages('phone-lead-icon.svg'); break;
            default: image = getImages('phone-lead-icon.svg'); break;
        }

        return image;
    }

    return (
        <>
            <tr id={leadData.lead_ui_id}>
                <td className={`p-0 col-parent ${mobLeadItem === leadData.lead_ui_id ? 'parent-mob-active':''}`}>
                    <div className='mob-lead-item d-lg-none mb-3'>
                        <table className='w-100'>
                            <tbody>
                                <tr>
                                    <td className='w-100'>
                                        <div className='d-flex align-items-center'>
                                            <div>
                                                <div className='source-box position-relative text-center'>
                                                    <img src={getLeadSource(leadData.lead_channel)} alt='icon'/>
                                                    <div className='text-center mt-2 text-white font-10 text-uppercase'>{leadData.lead_channel}</div>
                                                </div>
                                            </div>
                                            <div className='ps-4'>
                                                    <div className='d-lg-none pd-sm-title mb-2' onClick={()=>handleMobArrow(leadData)}>{leadData.first_name} {leadData.last_name}</div>
                                                    <div className='pd-sm-title' onClick={()=>handleMobArrow(leadData)}>{leadData.year} {leadData.make}</div>
                                                    {leadData.VIN ?
                                                        <>
                                                            <div className='pd-xs-title my-2 text-uppercase' onClick={()=>handleMobArrow(leadData)}>{leadData.VIN}</div> 
                                                        </>
                                                        : <>
                                                            <div className={`sm-box-relative d-inline-block mob-large-modal ${enterVinOpen ? "open" : ""}`}
                                                                onMouseEnter={handleMouseEnter}
                                                                onMouseLeave={handleMouseLeave}
                                                            >
                                                                <div className={`overlay-modal ${enterVinOpen ? "open" : ""}`} onClick={enterVinClose}></div>
                                                                <div className='sm-box-pos mob-large-modal-pos'>
                                                                    <div className='sm-box-header mb-3'>Enter VIN # <button className='sm-box-close' type='button' onClick={enterVinClose}><i className="fa-solid fa-xmark"></i></button></div>
                                                                    <form className='custom-form' onSubmit={(e) => addVIN(e)}>
                                                                        <input type="hidden" name="lead_id" value={leadData.id} />
                                                                        <div className='form-group mb-4'>
                                                                            <label className='cs-label text-dgray'>VIN</label>
                                                                            <input type='text' className='form-control' defaultValue="" name="vin"/>
                                                                        </div>
                                                                        <div className='text-center px-3 pb-4'>
                                                                            <button className='blue-btn mnw-170 text-center text-uppercase' type="submit">Save</button>
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                                <div>
                                                                    <button type='button' className='pd-sm-title text-blue text-uppercase need-vin-btn mt-2 pb-0' onClick={handleMouseEnter}>Need Vin</button>
                                                                </div>
                                                            </div>
                                                        </>
                                                    }
                                            </div>
                                            <div className='ms-auto'>
                                                <button className="mob-lead-btn"
                                                    type="button"
                                                    onClick={()=>{handleMobArrow(leadData.lead_ui_id); handleAccordionClick(leadData.lead_ui_id);}}
                                                >
                                                    <span className='btn-arrow' type='button'></span>
                                                </button>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="accordion-item">
                        <button className='mob-back-btn p-0 d-lg-none' type='button' onClick={cancelMobLeadItem}><span className='ps-0'><img src={getImages('left-arrow.svg')} /></span> Back</button>
                        <table className='w-100'>
                            <tbody>
                                <tr>
                                    <td className='mob-d-none ps-4' style={{ width: "5%" }}>
                                        <h2 className="accordion-header p-0">
                                            <button className={`accordion-button p-0 ${openLead === leadData.lead_ui_id ? "" : "collapsed"}`}
                                                type="button"
                                                onClick={() => handleAccordionClick(leadData.lead_ui_id)}
                                            >
                                                <span className='btn-arrow accordionTitle' type='button'></span>
                                            </button>
                                        </h2>
                                    </td>
                                    <td style={{ width: "18%" }}>
                                        <div className='d-flex align-items-center'>
                                            <div className='pd-content ps-0 d-none d-lg-block'>
                                                <div className='pd-sm-title'>{leadData.first_name} {leadData.last_name}</div>
                                                <div className='pd-xs-title my-2'><a className='pd-xs-title' href={`tel:${leadData.phone}`}>{formattedNumber}</a></div>
                                                <div className='pd-email' onClick={(e) => openPlainTextEmail(leadData)}>{leadData.email}</div>
                                            </div>
                                        </div>
                                    </td>
                                    <td style={{ width: "15%" }}>
                                        <div>
                                            <div className='pd-sm-title mob-font-18'>{leadData.year} {leadData.make}</div>
                                            {leadData.VIN ?
                                                <>
                                                    <div className='pd-xs-title my-2 text-uppercase mob-font-18 mob-text-blue'><span className='d-none d-lg-inline-block'>{leadData.VIN.length > 10 ? "VIN#" : "Stock#"}</span> {leadData.VIN}</div> 
                                                </>
                                                : <>
                                                    <div className={`sm-box-relative d-inline-block mob-large-modal ${enterVinOpen ? "open" : ""}`}
                                                        onMouseEnter={handleMouseEnter}
                                                        onMouseLeave={handleMouseLeave}
                                                    >
                                                        <div className={`overlay-modal ${enterVinOpen ? "open" : ""}`} onClick={enterVinClose}></div>
                                                        <div className='sm-box-pos mob-large-modal-pos'>
                                                            <div className='sm-box-header mb-3'>Enter VIN # <button className='sm-box-close' type='button' onClick={enterVinClose}><i className="fa-solid fa-xmark"></i></button></div>
                                                            <form className='custom-form' onSubmit={(e) => addVIN(e)}>
                                                                <input type="hidden" name="lead_id" value={leadData.id} />
                                                                <div className='form-group mb-4'>
                                                                    <label className='cs-label text-dgray'>VIN</label>
                                                                    <input type='text' className='form-control' defaultValue="" name="vin"/>
                                                                </div>
                                                                <div className='text-center px-3 pb-4'>
                                                                    <button className='blue-btn mnw-170 text-center text-uppercase' type="submit">Save</button>
                                                                </div>
                                                            </form>
                                                        </div>
                                                        <button type='button' className='pd-sm-title text-blue text-uppercase need-vin-btn my-2 mob-font-18'>Need Vin</button>
                                                    </div>
                                                </>
                                            }
                                            <div className='pd-xs-title d-flex align-items-center justify-content-between mob-table-str'>
                                                <div className='w-60'><span>Miles:</span> <span>{leadData.mileage}</span></div>
                                                <div className='w-40 text-capitalize'><span>Color:</span> <span>{leadData.exterior}</span></div>
                                                <div className='d-lg-none'>
                                                    <span>Appraisal:</span> 
                                                    <span>
                                                        {appraisal ?
                                                            <div className='text-center pd-sm-title text-blue'>{floatFormatter(appraisal, true)}</div> :
                                                            <div className='text-center cs-close'>
                                                                <button type='button' onClick={()=>handelMobAppraisal(leadData.lead_ui_id)}
                                                                    className='btn-transparent'><span className='close-icon'></span></button>
                                                            </div>
                                                        }
                                                    </span>
                                                </div>
                                            </div>
                                            <div className={`d-lg-none sm-box-relative d-inline-block  text-start appraisal-modal ${appraisalOpenIndex === leadData.lead_ui_id ? "open" : ""}`}
                                            >
                                                {/* <div className={`overlay-modal ${appraisalOpen === index ? "open" : ""}`} 
                                                    ></div> */}
                                                <div className='sm-box-pos'>
                                                    <div className='sm-box-header mb-3'>Update Appraisal <button className='sm-box-close' type='button' onClick={appraisalClose}><i className="fa-solid fa-xmark"></i></button></div>
                                                    <form className='custom-form' onSubmit={(e) => addAppraisal(e)}>
                                                        <input type="hidden" name="lead_id" value={leadData.id} />
                                                        <div className='form-group mb-4'>
                                                            <label className='cs-label text-dgray'>Fixed Price</label>
                                                            <input type='text' className='form-control' name="appraisal" defaultValue={leadData.appraisal ? leadData.appraisal : ""}/>
                                                        </div>
                                                        {/* <div className='form-group mb-4'>
                                                                <label className='cs-label text-dgray'>Price Range</label>
                                                                <div className='row'>
                                                                    <div className='col-6'>
                                                                        <input type='text' className='form-control' />
                                                                    </div>
                                                                    <div className='col-6'>
                                                                        <input type='text' className='form-control' />
                                                                    </div>
                                                                </div>
                                                            </div> */}
                                                        <div className='text-center px-3 pb-4'>
                                                            <button className='blue-btn mnw-170 text-center text-uppercase' type="submit">Save</button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td style={{ width: "7%" }} className='text-center mob-d-none'>
                                        <div className='source-box position-relative'>
                                            <img src={getLeadSource(leadData.lead_channel)} alt='icon'/>
                                            <div className='text-center mt-2 text-white font-10 text-uppercase'>{leadData.lead_channel}</div>
                                        </div>
                                    </td>
                                    <td style={{ width: "7%" }} className='text-center'>
                                        <div className='pd-personal-title mb-2 d-lg-none text-white text-start'>Customer Info</div>
                                        <div className='d-flex d-lg-block'>
                                            <div className='pd-content text-start d-lg-none'>
                                                <div className='pd-sm-title'>{leadData.first_name} {leadData.last_name}</div>
                                                <div className='pd-xs-title my-2'><a className='pd-xs-title' href={`tel:${formattedNumber}`}>{leadData.phone}</a></div>
                                                <div className='pd-email' onClick={(e) => openPlainTextEmail(leadData)}>{leadData.email}</div>
                                            </div>
                                            <div onClick={() => {openDealStageModal(leadData)}} className='cursor-pointer'>
                                                <div className={`progress-pie-chart ${leadData.deal_stage + 1 > 3 ? 'gt-50': ''}`} data-percent={(leadData.deal_stage + 1) * 20}>
                                                    <span className="label">{leadData.deal_stage + 1}</span>
                                                    <div className="ppc-progress">
                                                        <div className="ppc-progress-fill" style={{ transform: `rotate(${(leadData.deal_stage + 1) * 72}deg)` }}></div>
                                                    </div>
                                                    <div className="ppc-percents">
                                                        <div className="pcc-percents-wrapper"></div>
                                                    </div>
                                                </div>
                                                <div className='xsm-title mt-2'>{dealStages[leadData.deal_stage]}</div>
                                            </div>
                                        </div>
                                    </td>
                                    <td style={{ width: "7%" }} className='pt-3 text-center mob-d-none'>
                                        <div className={`sm-box-relative d-inline-block  text-start appraisal-modal ${appraisalOpenIndex === leadData.lead_ui_id ? "open" : ""}`}
                                            onMouseEnter={()=>handleMouseEnter1(leadData.lead_ui_id)}
                                            onMouseLeave={handleMouseLeave1}
                                        >
                                            {/* <div className={`overlay-modal ${appraisalOpen === index ? "open" : ""}`} 
                                                ></div> */}
                                            <div className='sm-box-pos'>
                                                <div className='sm-box-header mb-3'>Update Appraisal <button className='sm-box-close' type='button' onClick={appraisalClose}><i className="fa-solid fa-xmark"></i></button></div>
                                                <form className='custom-form' onSubmit={(e) => addAppraisal(e)}>
                                                    <input type="hidden" name="lead_id" value={leadData.id || ""} />
                                                    <div className='form-group mb-4'>
                                                        <label className='cs-label text-dgray'>Fixed Price</label>
                                                        <input type='text' className='form-control' name="appraisal" defaultValue={leadData.appraisal ? leadData.appraisal : ""}/>
                                                    </div>
                                                    {/* <div className='form-group mb-4'>
                                                            <label className='cs-label text-dgray'>Price Range</label>
                                                            <div className='row'>
                                                                <div className='col-6'>
                                                                    <input type='text' className='form-control' />
                                                                </div>
                                                                <div className='col-6'>
                                                                    <input type='text' className='form-control' />
                                                                </div>
                                                            </div>
                                                        </div> */}
                                                    <div className='text-center px-3 pb-4'>
                                                        <button className='blue-btn mnw-170 text-center text-uppercase' type="submit">Save</button>
                                                    </div>
                                                </form>
                                            </div>
                                            {appraisal ?
                                                <div className='text-center pd-sm-title text-blue pt-4'>{floatFormatter(appraisal, true)}</div> :
                                                <div className='text-center cs-close'>
                                                    <button type='button'
                                                        className='btn-transparent'><span className='close-icon'></span></button>
                                                </div>
                                            }
                                        </div>
                                    </td>
                                    <td style={{ width: "7%" }} className='text-center cs-close pt-3 mob-w-25'>
                                        <div className='mb-2 mob-tb-title d-lg-none'>Email <br />Sent</div>
                                        {emailSent ?
                                            <button type='button' className='btn-transparent'>
                                                <span className='green-check-icon'  onClick={(e) => completeTask(leadData, 'email')}></span>
                                            </button> :
                                            <button type='button' className='btn-transparent' onClick={(e) => completeTask(leadData, 'email')}>
                                                <span className='close-icon'></span>
                                            </button>
                                        }
                                    </td>
                                    <td style={{ width: "7%" }} className='text-center cs-close pt-3 mob-w-25'>
                                        <div className='mb-2 mob-tb-title d-lg-none'>Phone <br />Sent</div>
                                        {phoneCall ?
                                            <button type='button' className='btn-transparent'>
                                                <span className='green-check-icon' onClick={(e) => completeTask(leadData, 'call')}></span>
                                            </button> :
                                            <button type='button' className='btn-transparent' onClick={(e) => completeTask(leadData, 'call')}>
                                                <span className='close-icon'></span>
                                            </button>
                                        }
                                    </td>
                                    <td style={{ width: "6%" }} className='text-center pt-4 mob-w-25'>
                                        <div className='mb-2 mob-tb-title d-lg-none'>Notes</div>
                                        {/* {Notes && */}
                                        <div className={`sm-box-relative d-inline-block  text-start notes-modal ${notesOpen === leadData.lead_ui_id ? "open" : ""} ${openNotesMobModal ? 'mob-notes-active' : ''}`}
                                            onMouseEnter={()=>handleMouseEnter2(leadData.lead_ui_id)}
                                            onMouseLeave={handleMouseLeave2}
                                        >
                                            {/* <div className={`overlay-modal ${notesOpen === index ? "open" : ""}`} onClick={notesClose}></div> */}
                                            <div className='sm-box-pos'>
                                                <div className='sm-box-header mb-3'>Notes <button className='sm-box-close' type='button' onClick={()=>{notesClose(); closeNotesMobModal();}}><i className="fa-solid fa-xmark"></i></button></div>
                                                <div className='notes-scroll email-history-box notes-lits-box'>
                                                    {leadData.notes && leadData.notes.map((v, i) => {
                                                        const createdAtDate = new Date(v.created_at + " UTC");
                                                        return (
                                                            <div className='d-flex align-items-start mb-3' key={i}>
                                                                <div className='mnw-icon'>
                                                                    <img src={getImages(getImageType(v.title))} alt='envlope' />
                                                                </div>
                                                                <div className='ps-2'>
                                                                    <div className='pd-sm-title text-blue text-uppercase'>{v.title}</div>
                                                                    <div className='pd-xs-title mt-2' dangerouslySetInnerHTML={{__html: v.note}}/>
                                                                </div>
                                                                <div className='ms-auto'>
                                                                    <div className='d-flex'>
                                                                        <div className='pd-xs-title'>{createdAtDate.toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true })}</div>
                                                                        <div className='pd-xs-title ms-4'>{createdAtDate.toLocaleString('en-US', { year: "2-digit", month: "2-digit", day: "numeric" })}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                                <div className="text-start px-0">
                                                    <button className="blue-btn mnw-170 text-center" onClick={() => {createNoteOpenModal(leadData)}}>Create Note</button>
                                                </div>
                                            </div>
                                            <div className='d-md-block d-none'>
                                                <button className='file-icon' type='button'></button>
                                            </div>
                                        </div>
                                        
                                        <div className='d-lg-none'>
                                            <button className='file-icon' type='button' onClick={handleNotesMobileModal}></button>
                                        </div>
                                        {/* } */}
                                    </td>
                                    <td style={{ width: "7%" }} className='text-center pt-3 mob-w-25'>
                                        <div className='mb-2 mob-tb-title d-lg-none'>Timer</div>
                                        <span className='timer-icon'></span>
                                        <div className={`text-center time-time ${stateCls}`}>{finalTimeDifferent} {timeLabel}</div>
                                    </td>
                                    <td style={{ width: "16%" }} className='text-center pt-3'>
                                        <div className='mb-2 mob-tb-title d-lg-none text-start'>Assigned to</div>
                                        <select className='form-control sm-form-control w-100' value={assignee} onChange={(e) => updateAssignee(e.target.value, leadData.id)}>
                                            <option value={leadData.agent_id || "unassigned"}>{leadData.agent_name || "unassigned"}</option>
                                            {agentData.map((agent, i) => {
                                                return (<option value={agent.id} key={i}>{agent.full_name}</option>)
                                            })}
                                        </select>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        {leadData && leadData.notes && Array.isArray(leadData.notes) && leadData.notes.length > 0 && 
                            <div className='d-flex align-items-center mob-desc-block'>
                                <div style={{ width: "5%", padding: "15px 6px" }}></div>
                                <div className='pd-xs-title lh-16 mb-3 ps-2' style={{ width: "95%" }}>
                                    <span className='text-white'>Last Note: </span>
                                    {isTruncated ? (
                                        <>
                                            {leadData.notes[0].title} : <span dangerouslySetInnerHTML={{__html: leadData.notes[0].trimmed_note}}/>
                                            {leadData.notes[0].note.length > 300 && <button type="button" className='read-btn' onClick={toggleTruncate}>Read More</button>}
                                        </>
                                    ) : (
                                        <>
                                            {leadData.notes[0].title} : <span dangerouslySetInnerHTML={{__html: leadData.notes[0].note}}/>
                                            <button type="button" className='read-btn' onClick={toggleTruncate}>Read Less</button>
                                        </>
                                    )}
                                </div>
                            </div>
                        }
                        {openLead === leadData.lead_ui_id ? (
                            <div className="accordion-collapse collapse show">
                                <div className="accordion-body p-0">
                                    <LeadItemAccordion
                                        openPlainTextEmail={openPlainTextEmail}
                                        textLeadModal={textLeadModal}
                                        leadData={leadData}
                                    />
                                </div>
                            </div>
                        ) : ""}
                    </div>
                </td>
            </tr>
        </>
    )
}

export default LeadItem