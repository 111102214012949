import React, { useContext, useEffect, useState } from 'react'
import Layout from '../Components/Layouts/Layout'
import TopSection from '../Components/Common/TopSection'
import ModalLayout from '../Components/Modals/ModalLayout'
import AddAppraiserModal from '../Components/Modals/AddAppraiserModal'
import { UserContext } from '../Components/Context/UserContext'

const AppraiserSetup = () => {
    const [openAppraiser, setOpenAppraiser] =  useState(false);
    const handleOpenAppraiser = () => {
        setOpenAppraiser(true)
    }
    const handleCloseAppraiser = () => {
        setOpenAppraiser(false)
    }

    const {allUserData, changeUserData} = useContext(UserContext);

    const [userStates, setUserStates] = useState(allUserData);

    const handleRadioChange = (user_data, index, value, field) => {

        var this_value = value ? 1 : 0;

        const newUserData = [...userStates];
        newUserData[index][field] = this_value;
        setUserStates(newUserData);

        var values = {"username":user_data['username'],"field":field,"value":this_value};

        console.log(user_data,values);
        
        changeUserData(values);
    };

    useEffect(() => {
        setUserStates(allUserData);
    },[allUserData])

    return (
        <>
            <Layout>
                <TopSection />
                <div className='main-block py-4'>
                    <div className='container-fluid mxw-1180'>
                        <div className='gray-box p-5'>
                            <div className='lg-title text-uppercase font-26'>Appraiser Setup</div>
                            <div className='pd-xs-title text-white fw-400 pt-1'>TIP: Brief explanation to inform user. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</div>
                            <div className='mt-4 row mlr-20'>
                                <div className='col-md-6'>
                                    <div className='rp-box mnh-545'>
                                        <div className='lg-title font-24'>Appraisers</div>
                                        <div className='custom-table2 appraiser-table'>
                                            <table className='w-100 custom-form'>
                                                <tbody>
                                                    <tr>
                                                        <th>Agent Name</th>
                                                        <th className='w-130px'>Active</th>
                                                        <th className='text-end w-30px pe-0'>Admin</th>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className='custom-table2 appraiser-table add-scroll'>
                                            <table className='w-100 custom-form'>
                                                <tbody>
                                                    {userStates.map((v,i) => {
                                                        return (
                                                            <tr key={i}>
                                                                <td>{v.name}</td>
                                                                <td className='w-130px'>
                                                                    <label className="custom-checkbox">
                                                                        <input type="checkbox" id={`appraiserSetup${i}`} checked={v.active == 1} value={v.active == 1 ? "active" : "inactive"} onChange={(e) => {handleRadioChange(v, i, e.target.checked, 'active')}}/>
                                                                        <label htmlFor={`appraiserSetup${i}`}></label>
                                                                    </label>
                                                                </td>
                                                                <td className='text-end w-30px pe-0'>
                                                                    <label className="custom-checkbox">
                                                                        <input type="checkbox" id={`appraiserSetupAdmin${i}`} checked={v.admin == 1} value={v.admin == 1 ? "active" : "inactive"} onChange={(e) => {handleRadioChange(v, i, e.target.checked, 'admin')}}/>
                                                                        <label htmlFor={`appraiserSetupAdmin${i}`}></label>
                                                                    </label>
                                                                </td>
                                                                {/*<td className='text-end w-30px pe-0'>
                                                                    <button className='delete-icon'></button>
                                                                </td>*/}
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                        <button className="text-nowrap blue-transparent-btn text-uppercase mt-3" type="button" onClick={handleOpenAppraiser}>
                                            <span className='blue-add-icon'></span> Add Appraiser
                                        </button>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='rp-box mnh-545'>
                                        <div className='lg-title font-24'>Finalizers</div>
                                        <div className='custom-table2 appraiser-table'>
                                            <table className='w-100 custom-form'>
                                                <tbody>
                                                    <tr>
                                                        <th>Agent Name</th>
                                                        <th className='w-130px'>Lead %</th>
                                                        <th className='text-end w-30px pe-0'></th>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className='custom-table2 appraiser-table add-scroll'>
                                            <table className='w-100 custom-form'>
                                                <tbody>
                                                    <tr>
                                                        <td>Carl Smith</td>
                                                        <td className='w-130px'>
                                                            <select className='form-control'>
                                                                <option value="20">20%</option>
                                                            </select>
                                                        </td>
                                                        <td className='text-end w-30px pe-0'>
                                                            <button className='delete-icon'></button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Carl Smith</td>
                                                        <td className='w-130px'>
                                                            <select className='form-control'>
                                                                <option value="20">20%</option>
                                                            </select>
                                                        </td>
                                                        <td className='text-end w-30px pe-0'>
                                                            <button className='delete-icon'></button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Carl Smith</td>
                                                        <td className='w-130px'>
                                                            <select className='form-control'>
                                                                <option value="20">20%</option>
                                                            </select>
                                                        </td>
                                                        <td className='text-end w-30px pe-0'>
                                                            <button className='delete-icon'></button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Carl Smith</td>
                                                        <td className='w-130px'>
                                                            <select className='form-control'>
                                                                <option value="20">20%</option>
                                                            </select>
                                                        </td>
                                                        <td className='text-end w-30px pe-0'>
                                                            <button className='delete-icon'></button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Carl Smith</td>
                                                        <td className='w-130px'>
                                                            <select className='form-control'>
                                                                <option value="20">20%</option>
                                                            </select>
                                                        </td>
                                                        <td className='text-end w-30px pe-0'>
                                                            <button className='delete-icon'></button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <button className="text-nowrap blue-transparent-btn text-uppercase mt-3" type="button">
                                            <span className='blue-add-icon'></span> Add Finalizer
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className='row mlr-20'>
                                <div className='col-md-6'>
                                    <div className='lgray-box w-100'>
                                        <div className='lg-title font-24 text-uppercase'>Auto Rules</div>
                                        <ul className='as-list mt-2 mnh-210'>
                                            <li className='mb-2'>
                                                <div className="d-flex align-items-center justify-content-between as-lbox">
                                                    <div className='as-title'>Copy Chad on all leads above $100k</div>
                                                    <div>
                                                        <button className='delete-icon' type="button"></button>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='mb-2'>
                                                <div className="d-flex align-items-center justify-content-between as-lbox">
                                                    <div className='as-title'>Some other rule name here</div>
                                                    <div>
                                                        <button className='delete-icon' type="button"></button>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                        <div className='mt-2 text-center'>
                                            <button className="text-nowrap blue-transparent-btn text-uppercase" type="button">
                                                <span className='blue-add-icon'></span> Add Automatic rule
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='lgray-box w-100'>
                                        <div className='lg-title font-24 text-uppercase'>Auto Rules</div>
                                        <ul className='as-list mt-2 mnh-210'>
                                            <li className='mb-2'>
                                                <div className="d-flex align-items-center justify-content-between as-lbox">
                                                    <div className='as-title'>Copy Original Appraiser on all Finalization communication</div>
                                                    <div>
                                                        <button className='delete-icon' type="button"></button>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                        <div className='mt-2 text-center'>
                                            <button className="text-nowrap blue-transparent-btn text-uppercase" type="button">
                                                <span className='blue-add-icon'></span> Add Automatic rule
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
            <ModalLayout open={openAppraiser} close={handleCloseAppraiser}>
                <AddAppraiserModal close={handleCloseAppraiser}/>
            </ModalLayout>
        </>
    )
}

export default AppraiserSetup