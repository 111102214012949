import React from 'react'
import Header from '../Common/Header'

const Layout = ({children}) => {
  return (
    <>
        <Header/>
        {children}
    </>
  )
}

export default Layout