import {createContext, useContext, useEffect, useState} from 'react';
import axios from 'axios'
import {EnvContext} from './EnvContext';
import { UserContext } from './UserContext';

export const LeadsContext = createContext();

export const LeadsContextProvider = ({children}) => {
    const  {baseURL}  = useContext(EnvContext);
    const {user} = useContext(UserContext);

    const Axios = axios.create({ baseURL: baseURL });

    const [openLoadingModal, setOpenLoadingModal] = useState(false);

    const [singlePagination, setsinglePagination] = useState(true);
    const [activeLeadID, setActiveLeadID] = useState(null);
    const [leadData, setLeadData] = useState([]);
    const [archivedLeadData, setArchivedLeadData] = useState([]);


    const [ activeTab, setActiveTab]  = useState(true);

    const [leadFields,setLeadFields] = useState([]);

    const [emailScripts,setEmailScripts] = useState([]);

    const [textScripts,setTextScripts] = useState([]);

    const [agentData,setAgentData] = useState([]);

    const [leaderboard,setLeaderboard] = useState([]);    

    /*search stuff*/

    const [searchText,setSearchText] = useState("");

    const [filteredLeads, setFilteredLeads] = useState([]);
    const [filteredArchivedLeadData, setFilteredArchivedLeadData] = useState([]);

    const queryParameters = new URLSearchParams(window.location.search)
    const query_lead_id = queryParameters.get("lead_id");


    useEffect(() => {

        var filteredResults = filterLeadData(leadData);
        
        if(filteredResults.length==0){
            filteredResults = leadData;
            setsinglePagination(true);
        }
        else{
            setsinglePagination(false);
        }

        setFilteredLeads(filteredResults);

        var filteredResults = filterLeadData(archivedLeadData);

        setFilteredArchivedLeadData(filteredResults);
    }, [searchText, leadData]);

    /*search stuff*/

    const filterLeadData = (leadData) => {
        var filteredResults;

        if(!searchText && query_lead_id) {
            filteredResults = leadData.filter(lead =>
                (lead.id && lead.id == query_lead_id)
            );
        } else if (!searchText) {
            filteredResults = leadData;
        } else {
            filteredResults = leadData.filter(lead =>
                (lead.year && (lead.year + '').includes(searchText)) ||
                (lead.make && lead.make.toLowerCase().includes(searchText.toLowerCase())) ||
                (lead.vin && lead.vin.toLowerCase().includes(searchText.toLowerCase())) ||
                (lead.email && lead.email.toLowerCase().includes(searchText.toLowerCase())) ||
                (lead.first_name && lead.last_name && (lead.first_name.toLowerCase() + " " + lead.last_name.toLowerCase()).includes(searchText.toLowerCase()))
            );
        }

        return filteredResults;
    }


    const getLeads = async () => {
        setOpenLoadingModal(true);
        try {
            const loginToken = localStorage.getItem('loginToken');
            Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;

            const values = {'agent_id':user.user_id,'admin':user.admin,'include_deal_info':true};

            console.log(values);

            const response = await Axios.post('bridge/leads/leads.php',values);
            console.log(response.data);
            setOpenLoadingModal(false);

            var leads = [];
            var archived_leads = [];

            response.data.map((v,i) => {
                var name_parts = [];
                if(v.year) name_parts.push(v.year);
                if(v.make) name_parts.push(v.make);
                if(v.model) name_parts.push(v.model);
                if(v.trim) name_parts.push(v.trim);

                v.vehicle_name = name_parts.length > 0 ? name_parts.join(' ') : v.vin;

                var name_parts = [];
                if(v.first_name) name_parts.push(v.first_name);
                if(v.last_name) name_parts.push(v.last_name);

                v.full_name = name_parts.join(' ');

                if(v.archived) archived_leads.push(v);
                else leads.push(v);

            });       

            setLeadData(leads);
            setArchivedLeadData(archived_leads);

        } catch (error) {
            console.error('Error fetching leads:', error);
            setOpenLoadingModal(false);
            return {}; // Or handle error as needed
        }
    };

    const getLeadSourceData = (lead_data,start_time = '',end_time = '') => {
        var lead_source_data = {};

        lead_data.map((v,i) => {
            if(start_time != '' && v.created_at_utc < start_time) return false;

            if(end_time != "" && v.created_at_utc > end_time) return false;

            if(v.source in lead_source_data) {
                lead_source_data[v.source]['leads'].push(v);
                lead_source_data[v.source]['total_revenue'] += v.total_revenue;
                lead_source_data[v.source]['total_profit'] += v.total_profit;
                lead_source_data[v.source]['gross_profit'] += v.gross_profit;
            } else {
                lead_source_data[v.source] = {};
                lead_source_data[v.source]['leads'] = [v];
                lead_source_data[v.source]['source'] = v.source;

                lead_source_data[v.source]['total_revenue'] = v.total_revenue;
                lead_source_data[v.source]['total_profit'] = v.total_profit;
                lead_source_data[v.source]['gross_profit'] = v.gross_profit;
            }

        });

        for(var source in lead_source_data) {

            let uniqueItems = [...new Set(lead_source_data[source]['leads'].map(function(d) { return d['email']; }))]

            lead_source_data[source]['total_leads'] = uniqueItems.length;
        }

        console.log('lead source data',lead_source_data);

        return lead_source_data;

    }

    const getLeadFields = async () => {
        try {
            const loginToken = localStorage.getItem('loginToken');
            Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;

            const response = await Axios.post('bridge/leads/lead_fields.php');

            console.log(response.data);
            setLeadFields(response.data);


        } catch (error) {
            console.error('Error fetching lead fields:', error);
            return {}; //Or handle error as needed
        }
    };

    const getEmailScripts = async () => {
        try {
            const loginToken = localStorage.getItem('loginToken');
            Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;

            const response = await Axios.post('bridge/leads/email_scripts.php');

            console.log(response.data);
            setEmailScripts(response.data);


        } catch (error) {
            console.error('Error fetching email scripts:', error);
            return {}; //Or handle error as needed
        }
    };

    const getTextScripts = async () => {
        try {
            const loginToken = localStorage.getItem('loginToken');
            Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;

            const response = await Axios.post('bridge/leads/text_scripts.php');

            console.log(response.data);
            setTextScripts(response.data);


        } catch (error) {
            console.error('Error fetching text scripts:', error);
            return {}; //Or handle error as needed
        }
    };

    const getAgents = async () => {
        try {
            const loginToken = localStorage.getItem('loginToken');
            Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;

            const response = await Axios.post('bridge/leads/agents.php');

            console.log(response.data);
            setAgentData(response.data);


        } catch (error) {
            console.error('Error getting agent:', error);
            return {}; //Or handle error as needed
        }
    };

    const getLeaderboard = async () => {
        try {
            const loginToken = localStorage.getItem('loginToken');
            Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;

            const response = await Axios.post('bridge/leads/leaderboard.php');

            console.log(response.data);
            setLeaderboard(response.data);


        } catch (error) {
            console.error('Error getting leaderboard:', error);
            return {}; //Or handle error as needed
        }
    };

    const assignAgent = async (agent_id,lead_id) => {
        try {
            const values = {'agent_id':agent_id,'lead_id':lead_id};
            console.log(values);

            const loginToken = localStorage.getItem('loginToken');
            Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;

            const response = await Axios.post('bridge/leads/assign_agent.php',values);

            console.log(response.data);

        } catch (error) {
            console.error('Error assigning agent:', error);
            return {}; //Or handle error as needed
        }
    };

    const updateTask = async (lead_id,schedule_id,completed) => {
        try {
            const values = {'lead_id':lead_id,'schedule_id':schedule_id,'completed':completed};
            console.log(values);

            const loginToken = localStorage.getItem('loginToken');
            Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;

            const response = await Axios.post('bridge/leads/update_task.php',values);

            console.log(response.data);

        } catch (error) {
            console.error('Error updating task:', error);
            return {}; //Or handle error as needed
        }
    };

    const completeNextTask = async (lead_id,type) => {
        try {
            const values = {'lead_id':lead_id,'type':type};
            console.log(values);

            const loginToken = localStorage.getItem('loginToken');
            Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;

            const response = await Axios.post('bridge/leads/complete_next_task.php',values);

            console.log(response.data);

            return response.data && response.data.success && response.data.task_id ? response.data.task_id : false;

        } catch (error) {
            console.error('Error completing next task:', error);
            return {}; //Or handle error as needed
        }
    };

    const archiveLead = async (thisLead) => {
        try {
            const response = await updateLeadData(thisLead.id,'archived',1);

            if(response.success) getLeads();

        } catch (error) {
            console.error('Error archiving lead:', error);
            return {}; //Or handle error as needed
        }
    };

    const  updateLeadData = async (lead_id,field,value) => {
        try {
            const values = {'lead_id':lead_id,'field':field,'value':value};
            console.log(values);

            const loginToken = localStorage.getItem('loginToken');
            Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;

            const response = await Axios.post('bridge/leads/update_lead_data.php',values);

            console.log(response.data);

            if(response.data && response.data.success) getLeads();

            return response.data;

        } catch (error) {
            console.error('Error updating lead data:', error);
            return {}; //Or handle error as needed
        }
    };

    const addEmailScript = async (script_name, subject, script) => {
        try {
            const values = {'script_name':script_name,'subject':subject,'script':script};
            console.log(values);

            const loginToken = localStorage.getItem('loginToken');
            Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;

            const response = await Axios.post('bridge/leads/add_email_script.php',values);

            console.log(response.data);

            if(response.data.success) getEmailScripts();

            return response.data;

        } catch (error) {
            console.error('Error adding email script:', error);
            return {}; //Or handle error as needed
        }
    }

    const addNoteWrapper = (leadData,title,subtitle,note) => {
        const values = {'lead_id':leadData.id,'title':title,'subtitle':subtitle,'note':note};
        addNote(values);

        leadData.notes.push(values);
    }

    const addNote = async (values) => {
        try {
            
            console.log(values);

            const loginToken = localStorage.getItem('loginToken');
            Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;

            const response = await Axios.post('bridge/leads/add_note.php',values);

            console.log(response.data);

            return response.data;

        } catch (error) {
            console.error('Error adding note:', error);
            return {}; //Or handle error as needed
        }
    };

    const addLead = async (values) => {
        try {

            if(user && user.first_name && user.last_name) values.user_full_name = user.first_name + " " + user.last_name;
            
            console.log(values);

            const loginToken = localStorage.getItem('loginToken');
            Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;

            const response = await Axios.post('bridge/leads/add_lead.php',values);

            console.log(response.data);

            if(response.data.success) getLeads();

            return response.data;

        } catch (error) {
            console.error('Error adding lead:', error);
            return {}; //Or handle error as needed
        }
    };

    const sendSms = async (formValues,leadData) => {
        try {
            console.log(formValues);

            const loginToken = localStorage.getItem('loginToken');
            Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;

            const response = await Axios.post('bridge/twilio/send_sms.php',formValues);

            addNoteWrapper(leadData,"Sent SMS","",formValues.message);

            return response.data;


        } catch (error) {
            console.error('Error sending text:', error);
            return {}; //Or handle error as needed
        }
    };

    const sendPlainTextEmail = async (formValues,leadData) => {
        try {
            console.log(formValues);

            formValues.agent_name = leadData.agent_name;
            formValues.lead_id = leadData.id;

            const loginToken = localStorage.getItem('loginToken');
            Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;

            const response = await Axios.post('bridge/email/send_plaintext.php',formValues);

            addNoteWrapper(leadData,"Plain Text Email","",formValues.message);

            return response.data;

        } catch (error) {
            console.error('Error sending text:', error);
            return {}; //Or handle error as needed
        }
    };

    const getImageType = (title) => {
        var image = "note-icon.svg";
        switch (title) {
            case "Agent Note": image = "note-icon.svg"; break;
            case "Sent SMS": image = "send-icon.svg"; break;
            case "Plain Text Email": image = "envlope-icon.svg"; break;
            case "Phone Call": image = "phone-icon.svg"; break;
            default: image = "note-icon.svg"; break;
        }

        return image;
    }

    useEffect(() => {
        if(user && user.username && user.username != 'publicuser') {
            getLeadFields();
            getLeads();
            getEmailScripts();
            getTextScripts();
            getAgents();
            getLeaderboard();
        }
        console.log(user);
    },[user]);

    return (
        <>
            <LeadsContext.Provider value={{getLeads,singlePagination,setsinglePagination,activeTab,setActiveTab,searchText,setSearchText,filteredLeads,leadData,filteredArchivedLeadData,baseURL,setActiveLeadID,activeLeadID, emailScripts, textScripts, leadFields, agentData, sendSms, sendPlainTextEmail, assignAgent, updateTask, completeNextTask, updateLeadData, addNote, addEmailScript, addLead, leaderboard, archiveLead, getImageType, getLeadSourceData}}>
                {children}
            </LeadsContext.Provider>
        </>
    );
}

export default LeadsContextProvider