import React, { useEffect, useState } from 'react'
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { ValidationError } from '../Errors/ValidationError';
import Gallery from './Gallery';
const validationSchema = Yup.object().shape({
    vin: Yup.string()
        .matches(/^[A-HJ-NPR-Z0-9]{17}$/, 'Invalid Vin number')
        .required('VIN number is required'),
    // file: Yup.mixed().required('A file is required')
    //     .test(
    //         'fileSize',
    //         'File size is too large',
    //         value => value && value.size <= 5242880 // 5MB
    //     )
    //     .test(
    //         'fileType',
    //         'Unsupported file format',
    //         value => value && ['image/jpeg', 'image/png', 'application/pdf'].includes(value.type)
    //     )
});
const UploadVehiclesDetails = ({
    setImages,
    images,
    initialValues,
    uploadVehicleSubmit,
    setFormData,
    handleDrop,
    handleDragOver,
    handleImageChange,
    setCurrentStep,
    handelNevigateLink,
    stepSetup,
    propertyData
}) => {
    return (
        <>
            {/* {console.log("images", images)} */}
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, { resetForm }) => {
                    console.log(values)
                    resetForm();
                    uploadVehicleSubmit();
                    setFormData(values);
                    setCurrentStep(3);
                    handelNevigateLink()
                }}
            >
                {({ values, errors, touched, isValid }) => (
                    <Form className='custom-form form-style2 align-items-end row'>
                        <div>
                            <div className='lg-title'>Please provide VIN & vehicle photos</div>
                            <div className='appraisal-rbox mt-5 appraisal-upload-box'>
                                <div className='appraisal-md-title mb-4'>Upload Vehicles Details</div>
                                <div className='d-flex'>
                                    <div className='appraisal-left'>
                                        <div className='form-group mb-3'>
                                            <label className='as-label'>Vin</label>
                                            {stepSetup === "step1" ?
                                                <div className={`vin-box d-flex align-items-center ${touched.vin && !errors.vin && isValid && ("selected")}`}>

                                                    <div className='position-relative vin-box-left' onClick={handelNevigateLink}>
                                                        <input type='text' name="vin"
                                                            className='form-control text-uppercase'
                                                            placeholder="Enter VIN"
                                                        />
                                                    </div>
                                                    <button type='submit' className='enter-btn text-uppercase'>Enter</button>
                                                </div> :
                                                <div className={`vin-box d-flex align-items-center ${values.vin && !errors.vin && isValid && ("selected")}`}>
                                                    <div className='position-relative vin-box-left'>
                                                        {stepSetup ==="step3" ? 
                                                            <input type='text' className='form-control'
                                                            placeholder="Enter VIN"/> : ""
                                                        }
                                                            <Field type="text" name="vin" className={`form-control text-uppercase ${stepSetup ==="step3" ? "d-none": ""}`} placeholder="Enter VIN" />
                                                            {stepSetup ==="step3" ? "" :
                                                                values.vin && !errors.vin && isValid && (
                                                                    <div className='check-icon'></div>
                                                                )
                                                            }
                                                        {values.vin && !errors.vin && isValid && setCurrentStep(2)}
                                                    </div>
                                                    <button type='submit' className='enter-btn text-uppercase'>Enter</button>
                                                </div>
                                            }
                                            <ValidationError name="vin" />
                                        </div>
                                        <div className='form-group'>
                                            {stepSetup === "step3" ? 
                                                <Gallery propertyData={propertyData}/> :
                                            
                                                images && images.length > 0 ?
                                                <>
                                                    <div className="d-flex justify-content-between">
                                                        <div className="full-photo">
                                                            <a data-fancybox="gallery" href={images?.[0]} className='position-relative'>
                                                                <img className='w-100' src={images && images?.[0] !== undefined && images?.[0]} alt="img" />
                                                            </a>
                                                        </div>
                                                        <div className='photos-list'>
                                                            {images &&
                                                                images.slice(1).map((item, index) => {
                                                                    return (
                                                                        <div className="p-col w-100" key={index}>
                                                                            <a data-fancybox="gallery" href={item} className='position-relative'>
                                                                                <img src={item} alt="img" />
                                                                                {index >= 2 ? <div className='countimg'>+{images.length - 4}</div> : ""}
                                                                            </a>
                                                                        </div>
                                                                    );
                                                                })}
                                                        </div>
                                                    </div>
                                                    <div className='mt-2'>
                                                        <button className='appraisal-add-btn' type='button'>
                                                            <span>
                                                                <span className='appraisal-add-icon'></span> Add More Photos
                                                            </span>
                                                            <input
                                                                type="file"
                                                                accept="image/*"
                                                                multiple
                                                                onChange={(e) => handleImageChange(e.target.files)}
                                                                id="fileInput"
                                                            />
                                                        </button>
                                                    </div>
                                                </> :
                                                <>
                                                    {stepSetup === "step1" ?
                                                        <div className='drop-zone-box'>
                                                            <div
                                                                className="drop-zone text-uppercase text-center mb-15"
                                                                onDrop={handleDrop}
                                                                onDragOver={handleDragOver}
                                                            >
                                                                <div className='photo-icon  mb-15'></div>
                                                                <div className='mb-2'>Drag & Drop Photos</div>
                                                            </div>
                                                            <div className='text-center'>
                                                                <div className='browse-btn mb-3'>
                                                                    <span>Browse</span>
                                                                    <Field name="file">
                                                                        {({ field, form }) => (
                                                                            <input
                                                                                type="file"
                                                                                accept="image/*"
                                                                                multiple
                                                                                onChange={(e) => handleImageChange(e.target.files)}
                                                                                id="fileInput"
                                                                                className='d-none'
                                                                            />
                                                                        )}
                                                                    </Field>

                                                                </div>
                                                                <div className='info-text text-center'>You can upload up to 30 photos, max file size 5MB</div>
                                                            </div>
                                                        </div> :
                                                        <div className='drop-zone-box'>
                                                            <div
                                                                className="drop-zone text-uppercase text-center mb-15"
                                                                onDrop={handleDrop}
                                                                onDragOver={handleDragOver}
                                                            >
                                                                <div className='photo-icon  mb-15'></div>
                                                                <div className='mb-2'>Drag & Drop Photos</div>
                                                            </div>
                                                            <div className='text-center'>
                                                                <div className='browse-btn mb-3'>
                                                                    <span>Browse</span>
                                                                    <Field name="file">
                                                                        {({ field, form }) => (
                                                                            <input
                                                                                type="file"
                                                                                accept="image/*"
                                                                                multiple
                                                                                onChange={(e) => handleImageChange(e.target.files)}
                                                                                id="fileInput"
                                                                            />
                                                                        )}
                                                                    </Field>

                                                                </div>
                                                                <div className='info-text text-center'>You can upload up to 30 photos, max file size 5MB</div>
                                                            </div>
                                                        </div>
                                                    }
                                                    <ValidationError name="file" />
                                                </>
                                            }

                                            {/* <div className="image-boxes">
                                                {images && images.slice(0, 4).map((image, index) => (
                                                    <div key={index} className="image-box">
                                                        <img src={image} alt={`Image ${index + 1}`} />
                                                        {index === 3 &&
                                                            (<div className='totalcount'>{images.length - 4}</div>)
                                                        }
                                                    </div>
                                                ))}
                                            </div> */}
                                        </div>
                                    </div>
                                    <div className='appraisal-right'>
                                        <div className='appraisal-md-title mb-2'>2019 Toyota</div>
                                        <div className='appraisal-sm-title mb-3'>4Runner TRD Pro</div>
                                        <div className='appraisal-xs-title mb-4'>18,000 miles</div>
                                        <div className='appraisal-xxs-title mb-3'><span className='w-30 d-inline-block'>Color:</span> White / Black</div>
                                        <div className='appraisal-xxs-title mb-3'><span className='w-30 d-inline-block'>Condition:</span> Excellent</div>
                                        <div className='appraisal-xxs-title mb-3'><span className='w-30 d-inline-block'>Transmision:</span> Automatic</div>
                                        <div className='appraisal-xxs-title mb-3'><span className='w-30 d-inline-block'>Financing:</span> Loan</div>
                                        <div className='mt-5'>
                                            {stepSetup === "step1" ?
                                                <button className='gray-btn w-100 text-uppercase' disabled type='submit'>Submit</button> :
                                                <button className='accept-btn w-100 text-uppercase' type='submit'>Submit</button>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    )
}

export default UploadVehiclesDetails