import React, { useContext, useState } from 'react'
import mainLogo from '../../assets/img/main-logo.svg'
import { Field, Formik, Form, ErrorMessage } from 'formik'
import TextAreaField from '../Form/TextAreaField';
import * as yup from 'yup';
import { ValidationError } from '../Errors/ValidationError';
import { useNavigate, Link } from 'react-router-dom';

import { UserContext } from '../Context/UserContext';

const validationSchemaStep1 = yup.object({
    signin_username: yup.string().required("Please enter your username"),
    // Sorry! VIN must have 17 characters.
    signin_password: yup.string().required("Please enter your password"),
});
const Login = () => {
    const [formikData, setFormikData] = useState({
        signin_username: "",
        signin_password: ""
    });

    const navigate = useNavigate();

    const {loginUser, wait, loggedInCheck, forgotPassword, registrationForm} = useContext(UserContext);
    const [redirect, setRedirect] = useState(false);
    const [errMsg, setErrMsg] = useState(false);
    const [formData, setFormData] = useState({
        signin_username:'',
        signin_password:'',
        terms:true
    });

    const onChangeInput = (e) => {
        setFormData({
            ...formData,
            [e.target.name]:e.target.name == "terms" ? e.target.checked : e.target.value
        })
    }

    const submitForm = async (e) => {
        e.preventDefault();

        if(!formData.terms){
            setErrMsg('Please accept terms & conditions');
            return;
        }

        if(formData.signin_username.trim() === '' || formData.signin_password.trim() === ''){
            setErrMsg('Please Fill in all Required Fields!');
            return;
        }

        const data = await loginUser(formData);
        if(data.success){
            e.target.reset();
            setRedirect('Signing in...');
            await loggedInCheck();
            navigate("/");
            return;
        }
        setErrMsg(data.message);
    }

  return (
    <>
        <div className="page-login-bg page-signin">
            <div className="signin-header text-center">
                    <img src={mainLogo} alt='logo'/>
            </div>
            <div className="signin-container">
                <div className="signin-form">
                    <form className='custom-form' onSubmit={submitForm} method="post">
                        <div className='form-group mb-3'>
                            <label>Username</label>
                            <input type='text' name="signin_username" className='form-control' onChange={onChangeInput}/>
                        </div>
                        <div className='form-group mb-3'>
                            <label>Password</label>
                            <input type='password' name="signin_password" className='form-control' onChange={onChangeInput}/>
                        </div>
                        <div className="form-group mb-3">
                            <label className="custom-checkbox mb-1">
                                <input type="checkbox" id="remember_me"/>
                                <label htmlFor="remember_me">Remember Me</label>
                            </label>
                        </div>
                        <div className="form-actions mb-3">
                            <button type='submit' className='blue-btn w-100 text-center text-uppercase mb-3 btn-md'>Login</button>
                            <button className="forgot-password" type='button'>Forgot your password?</button>
                        </div> 
                        {/*<div className='d-flex align-items-center mt-5 justify-content-between'>
                            <div>
                                <button type='button' onClick={forgotPasswordModal} className='link-btn'>Forgot Password?</button>
                            </div>
                            <div>
                                 <Link to="" className='link-btn' onClick={openRegisterModal}>Apply to Join</Link> 
                                <button type='button' onClick={openRegisterModal} className='link-btn'>Apply to Join</button>
                            </div>
                        </div>*/}
                    </form>
                </div>
            </div>
        </div>
    </>
  )
}

export default Login