import React from 'react'
import FrontHeader from '../Common/FrontHeader'
import FrontFooter from '../Common/FrontFooter'

const FrontLayout = ({children}) => {
    document.body.classList.add('front-body');
  return (
    <>
        <FrontHeader/>
        {children}
        <FrontFooter/>
    </>
  )
}

export default FrontLayout