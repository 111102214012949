import { Routes, Route } from "react-router-dom";
import Leads from "../Pages/Leads";
import Dashboard from "../Pages/Dashboard";
import CommonLayout from "../Components/Layouts/CommonLayout";
import Reporting from "../Pages/Reporting";
import LeadActivity from "../Pages/LeadActivity";
import AvailableInventory from "../Pages/AvailableInventory";
import AppraiserSetup from "../Pages/AppraiserSetup";
import FrontDealStatus from "../Pages/FrontDealStatus";
import FrontDealStatusStep2 from "../Pages/FrontDealStatusStep2";
import FrontDealStatusStep3 from "../Pages/FrontDealStatusStep3";
import FrontDealStatusStep4 from "../Pages/FrontDealStatusStep4";
import Lead from "../Components/Leads/Lead";
import Login from "../Components/Common/Login";

import { UserContext } from "../Components/Context/UserContext";
import { useEffect, useContext, useState } from 'react';
import LeadSource from "../Pages/LeadSource";
import SendToAuction from "../Pages/SendToAuction";
import ManageWebsite from "../Pages/ManageWebsite";

const AppRoutes = () => {
    const { user, loggedInCheck } = useContext(UserContext);

    const [checkedLogin, setCheckedLogin] = useState(null);

    useEffect(() => {
		if (!checkedLogin) {
			checkLogin();
		}
	}, []);

	const checkLogin = async () => {
		console.log('checking login');
		await loggedInCheck();
		setCheckedLogin(true);
		console.log(user);
	};

    return (
        <Routes>
            {checkedLogin && user && user.username && user.username != 'publicuser' ? (
                <>
                    <Route exact path="/" Component={CommonLayout} />
                    {/* <Route exact path="leads" Component={Leads} /> */}
                    <Route exact path="reporting" Component={Reporting} />
                    <Route exact path="lead-activity" Component={LeadActivity} />
                    <Route exact path="available-inventory" Component={AvailableInventory} />
                    <Route exact path="appraiser-setup" Component={AppraiserSetup} />
                    <Route exact path="deal-status" Component={FrontDealStatus} />
                    <Route exact path="deal-status-step2" Component={FrontDealStatusStep2} />
                    <Route exact path="deal-status-step3" Component={FrontDealStatusStep3} />
                    <Route exact path="deal-status-step4" Component={FrontDealStatusStep4} />
                    <Route exact path="send-to-auction" Component={SendToAuction} />
                    <Route exact path="lead" Component={Lead} />
                    <Route exact path="lead-source" Component={LeadSource} />
                    <Route exact path="manage-website" Component={ManageWebsite} />
                    <Route exact path="login" Component={Login} />
                </>
            ) : (
            checkedLogin ?  
                (
                <>
                    <Route exact path="/" Component={Login} />
                </>
            ) : (
                <>
                    <Route exact path="/" Component={Login} />
                </>
            ))}
        </Routes>
    );
};

export default AppRoutes;
