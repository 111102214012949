import React from 'react'
import { getImages } from '../Const'
import { Link } from 'react-router-dom'

const FrontFooter = () => {
  return (
    <>
      <footer className="FooterMain p-5">
        <div className="container mxw-1199">
          <div className="FooterSec">
            <div className="footCon">
              <div className="footConWrap">
                <p className="footText">Sell us your new or used car with a hassle free process giving you a
                  quick 60 second offer. No more selling to the public or dealer trade. Get paid for selling
                  your vehicle with no pressure.</p>
                <div className="footTitle">
                  <div>We always love to hear from you</div>
                </div>
              </div>
            </div>
            <div className="footLogo">
              <Link to="/" title="Kent motor Club">
                <img src={getImages("footer-logo.svg")} alt="img-fluid" />
              </Link>
            </div>
            <div className="copyright">&copy; 2024 Kent motor Club</div>
            <div className="socialMeadia">
              <div className="socialTitle"> Follow Us</div>
              <div className="socialIcon">
                <a href="https://www.facebook.com/kentmotorclub/" target="_blank" title="Follow On Facebook" className="icon facebook">
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a href="https://www.instagram.com/kentmotorclub/" target="_blank" title="Follow On Instagram" className="icon instagram">
                  <i className="fab fa-instagram"></i>
                </a>
              </div>
              <div className="socialTitle" style={{ marginTop: "1rem", marginBottom: "0.5rem" }}>Contact Us</div>
              <a href="tel:+13303293633" className="footText" style={{ marginTop: "0.5rem" }}>+1(330) 329-3633</a>
            </div>
            <div className="poweredBy powered-by-marg-top" style={{ marginTop: "-4rem" }}>
              <p className="powered-by-text"> <span> Powered By : </span>
                <Link to="https://www.webloo.co/" target="_blank" className="power-by-img">
                  <img src={getImages('Webloo-logo-dark.svg')} alt="" />
                </Link>
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

export default FrontFooter