import React, { useState, useRef, useContext } from 'react'
import { Field, Formik, Form, ErrorMessage } from 'formik';

import TextAreaField from '../Form/TextAreaField';
import * as yup from 'yup';
import { ValidationError } from '../Errors/ValidationError';
import { LeadsContext } from '../Context/LeadsContext';

const validate_vin = (inputVIN) => {
    var vin = inputVIN.toLowerCase().trim();

    var patt = new RegExp("^[^Wioq]{17}$");

    if (!patt.test(vin)) {
        return false;
    }

    var weights = [8, 7, 6, 5, 4, 3, 2, 10, 0, 9, 8, 7, 6, 5, 4, 3, 2];

    var transliterations = [];

    transliterations["a"] = 1;
    transliterations["b"] = 2;
    transliterations["c"] = 3;
    transliterations["d"] = 4;
    transliterations["e"] = 5;
    transliterations["f"] = 6;
    transliterations["g"] = 7;
    transliterations["h"] = 8;
    transliterations["j"] = 1;
    transliterations["k"] = 2;
    transliterations["l"] = 3;
    transliterations["m"] = 4;
    transliterations["n"] = 5;
    transliterations["p"] = 7;
    transliterations["r"] = 9;
    transliterations["s"] = 2;
    transliterations["t"] = 3;
    transliterations["u"] = 4;
    transliterations["v"] = 5;
    transliterations["w"] = 6;
    transliterations["x"] = 7;
    transliterations["y"] = 8;
    transliterations["z"] = 9;

    var sum = 0;
    var i = 0;

    for (i = 0; i < vin.length; i++) {
        // loop through characters of VIN
        // add transliterations * weight of their positions to get the sum
        if (isNaN(vin[i])) {
        sum += transliterations[vin[i]] * weights[i];
        } else {
        sum += parseInt(vin[i]) * weights[i];
        }
    }

    var checkdigit = sum % 11;

    if (checkdigit == 10) {
        checkdigit = "x";
    }

    return checkdigit == vin[8];
}


const validationSchemaStep1 = yup.object({
    vin: yup.string().required("Please enter your VIN").test(
        "valid-vin",
        "Please enter a valid VIN.",
        (value) => validate_vin(value)
      ),
    miles: yup.number().required("Please enter a mileage").min(1,'Mileage must be greater than 0'),
    email: yup.string().nullable().email(),
});

const validationSchemaStep2 = yup.object({
    first_name: yup.string().required("Please enter first name"),
    last_name: yup.string().required("Please enter last name"),
    email: yup.string().required("Please enter email").nullable().email("Please enter email"),
    phone: yup.string().required("Please enter phone number"),
});

const QuickUpModal = ({ close }) => {

    const {addLead} = useContext(LeadsContext);

    const [formikData, setFormikData] = useState({
        vin: "",
        miles: 0
    });
    const [formikData1, setFormikData1] = useState({
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        note: ""
    });

    const formikRef = useRef(null);

    const [activeAccordian, setActiveAccordian] = useState(0);

    const handleSubmit = async (values) => {
        console.log(values);

        const data = await addLead(values);

        if(data.success) {
            close();
            alert("Lead added");
        } else {
            alert("Error adding lead");
        }
    }

    return (
        <>

            <div className="modal-header">
                <h1 className="modal-title text-uppercase" id="staticBackdropLabel">
                    Quick Up
                </h1>
                <button className="sm-box-close" type="button" onClick={close}>
                    <i className="fa-solid fa-xmark"></i>
                </button>
            </div>
            <div className="modal-body px-0 py-3">
                <div className="accordion quick-accordion" id="accordionExample">
                    <div className="accordion-item mb-2">
                        <h2 className="accordion-header" id="headingOne">
                            <button className="accordion-button d-flex align-itmes-center justify-content-between w-100" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                <div>STEP 1</div>
                                <div>Vehicle Info</div>
                            </button>
                        </h2>
                        <div id="collapseOne" className={`accordion-collapse collapse ${activeAccordian == 0 ? 'show' : ''}`} aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                            <div className="accordion-body py-5">
                                <div className='p-3'>
                                    <Formik
                                        initialValues={formikData}
                                        validationSchema={validationSchemaStep1}
                                        onSubmit={(values, { resetForm }) => {
                                            console.log(values);
                                            setActiveAccordian(1);
                                        }}
                                        enableReinitialize={true}
                                        innerRef={formikRef}
                                    >
                                        {({ values }) => (
                                            <Form className='custom-form form-style2 align-items-end row'>
                                                <div className='form-group col-md-5'>
                                                    <label>ENTER VIN NUMBER</label>
                                                    {/* <input type='text' className='form-control' placeholder='Enter vin here ...' /> */}
                                                    <Field name="vin" type="text" className='form-control' placeholder='Enter vin here ...'/>
                                                    
                                                </div>
                                                <div className='form-group col-md-2'>
                                                    <label>MILES</label>
                                                    <Field name="miles" type="text" className='form-control' placeholder='0'/>
                                                </div>
                                                <div className='form-group col-md-5'>
                                                    <button className='blue-btn w-100 text-uppercase lg-btn' type='submit'>Enter</button>
                                                </div>
                                                <ValidationError name="vin"/>
                                                <ValidationError name="miles"/>
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item mb-2">
                        <h2 className="accordion-header" id="headingTwo">
                            <button className="accordion-button collapsed d-flex align-itmes-center justify-content-between w-100" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                <div>STEP 2</div>
                                <div>Customer Info</div>
                            </button>
                        </h2>
                        <div id="collapseTwo" className={`accordion-collapse collapse ${activeAccordian == 1 ? 'show' : ''}`} aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                            <div className="accordion-body py-4">
                                <div className='p-0'>
                                    <Formik
                                        initialValues={formikData1}
                                        validationSchema={validationSchemaStep2}
                                        onSubmit={(values, { resetForm }) => {
                                            console.log(formikRef.current.values,values);
                                            handleSubmit({...formikRef.current.values,...values});
                                        }}
                                        enableReinitialize={true}
                                    >
                                        {({ values }) => (
                                            <Form className='custom-form form-style2 row'>
                                                <div className='form-group col-md-6 mb-3'>
                                                    <label>FIRST NAME</label>
                                                    <Field name="first_name" type="text" className='form-control'/>
                                                    <ValidationError name="first_name"/>
                                                </div>
                                                <div className='form-group col-md-6 mb-3'>
                                                    <label>LAST NAME</label>
                                                    <Field name="last_name"  type="text" className='form-control'/>
                                                    <ValidationError name="last_name"/>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className='form-group mb-3'>
                                                        <label>EMAIL</label>
                                                        <Field name="email" type="text" className='form-control'/>
                                                        <ValidationError name="email"/>
                                                    </div>
                                                    <div className='form-group mb-3'>
                                                        <label>PHONE</label>
                                                        <Field name="phone" type="text" className='form-control'/>
                                                        <ValidationError name="phone"/>
                                                    </div>
                                                </div>
                                                <div className='form-group col-md-6 mb-3'>
                                                    <label>NOTES</label>
                                                    <TextAreaField name="note" className='form-control h-122'></TextAreaField>
                                                </div>
                                                <div className='form-group col-12 text-center'>
                                                    <button className='blue-btn mxw-240 w-100 text-uppercase lg-btn' type='submit'>Update</button>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default QuickUpModal