import {  useState, useContext, useEffect } from 'react';
import { LeadsContext } from "../Context/LeadsContext";

const TextLead = ({ close, leadData }) => {

    const { textScripts, leadFields, sendSms } = useContext(LeadsContext);

    const [selectedOption, setSelectedOption] = useState(0);

    const [textBody,setTextBody] = useState("");

    const handleSelectChange = (value) => {
        setSelectedOption(value);
        if (value !== null && Array.isArray(textScripts) && value in textScripts) {
            var this_script = textScripts[value].script;

            leadFields.map((field) => {
                this_script = this_script.replaceAll('[' + field + ']',leadData[field] || "");
            });

            setTextBody(this_script);
        }
    };   

    const [customerName,setCustomerName] = useState("");

    const [customerPhone,setCustomerPhone] = useState("");

    const sendText = async (e) => {
        e.preventDefault();
        
        const formData = Object.fromEntries(new FormData(e.target));
        
        const response = await sendSms(formData,leadData);

        if(response && response.success) {
            close();
            alert('Your text has been sent');
          } else alert('Error sending text');
    }

    const [textHistory, setTextHistory] = useState([]);

    useEffect(() => {
        if(leadData) {
            setCustomerName(leadData.first_name + " " + leadData.last_name);
            setCustomerPhone(leadData.phone);

            handleSelectChange(selectedOption);

            if(leadData.notes) {
              var text_history = [];
      
              leadData.notes.map((v,i) => {
                if(v.title == "Sent SMS" || v.title == "Received SMS") {
      
                  const createdAtDate = new Date(v.created_at + " UTC");
      
                  v.time = createdAtDate.toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
                  v.date = createdAtDate.toLocaleString('en-US', { year: "2-digit", month: "2-digit", day: "numeric" });

                  v.received = v.title == "Received SMS";
      
                  text_history.push(v);
                }
              });
      
              setTextHistory(text_history.reverse());
            }
        }
    }, [leadData]);

    useEffect(() => {
        if(textScripts && Array.isArray(textScripts)) handleSelectChange(0);
    }, [textScripts]);
  
    return (
        <>
            {/* {console.log("textLeadItem", textLeadItem)}
            {console.log("formikData", formikData)} */}

                <form className='custom-form form-style2' onSubmit={(e) => sendText(e)}>
                    <div className="modal-header">
                        <h1 className="modal-title text-uppercase" id="staticBackdropLabel">Text Lead</h1>
                        <button className="sm-box-close" type="button" onClick={() => {
                            //resetForm(); // Reset the form when close button is clicked
                            close();
                        }}><i className="fa-solid fa-xmark"></i></button>
                    </div>
                    <div className="modal-body px-3 py-4">
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className='form-group mb-3'>
                                    <label>CUSTOMER NAME</label>
                                    <input type="text" name="name" className="form-control" value={customerName || ""} onChange={(e) => setCustomerName(e.target.value)}/>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group mb-3'>
                                    <label>PHONE#</label>
                                    <input type="text" name="phone" className="form-control" value={customerPhone || ""} onChange={(e) => setCustomerPhone(e.target.value)}/>
                                </div>
                            </div>
                            <div className='col-12 mb-3'>
                                <div className='form-group'>
                                    <label>TEXT HISTORY</label>
                                    {/* <Field type="text" name="texthirstory" className="form-control" /> */}
                                    <div className="modal-chat-sec">
                                        <div className="modal-chat-sec-scroll" id="text-history">
                                        {textHistory.map((v,i) => {
                                            return (
                                                <div key={i}>
                                                    <div className="chat-date">{v.date}</div>
                                                    <div className="message-wrap">
                                                        <div className={v.received ? "other-message" : "me-message"}>{v.note}</div>
                                                        <div className={v.received ? "other-msg-time" : "me-msg-time"}>{v.time}</div>
                                                    </div>
                                                </div>
                                            )
                                        })}
   
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group mb-3'>
                                    <label>SELECT SCRIPT</label>
                                    <select name="script" className="form-control"
                                        onChange={(e) => handleSelectChange(e.target.value)}
                                        value={selectedOption}>
                                            {textScripts.map((script,i) => 
                                                <option key={i} value={i}>{script.script_name}</option>
                                            )}
                                    </select>
                                </div>
                                <div className='text-center d-none d-md-block'>
                                    <button className='blue-btn text-uppercase' type='submit'>
                                        <i className="bi bi-envelope-fill me-1"></i> Send Text
                                    </button>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group mb-3'>
                                    <label>MESSAGE</label>
                                    <textarea className="msgitem msgItem1 form-control h260" name="message" value={textBody} onChange={(e) => setTextBody(e.target.value)}></textarea>
                                </div>
                            </div>
                            <div className='col-12 text-center d-md-none'>
                                <button className='blue-btn text-uppercase mnw-170' type='submit'>
                                    <i className="bi bi-envelope-fill me-1"></i> Send Text
                                </button>
                            </div>
                        </div>
                    </div >
                </form>

        </>
    )
}

export default TextLead