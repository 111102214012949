import React, { useContext, useEffect, useRef, useState } from "react";
import { getImages } from "../Components/Const";
import { LeadsContext } from "../Components/Context/LeadsContext";
import LeadItem from "../Components/Leads/LeadItem";
import ModalLayout from "../Components/Modals/ModalLayout";
import PlainTextEmail from "../Components/Modals/PlainTextEmail";
import CreateNotesModal from "../Components/Leads/CreateNotesModal";
import ScriptModal from "../Components/Modals/ScriptModal";
import TextLead from "../Components/Leads/TextLead";
import AutoComplete from "../Components/Common/AutoComplete";
import WaitingResponseModal from "../Components/Leads/WaitingResponseModal";

const Leads = ({ handleBackBtn, filteredLeads }) => {

  const { singlePagination, activeLeadID } = useContext(LeadsContext);

  useEffect(() => {
    console.log("leads useeffect", filteredLeads);
  }, []);

  /*plain text email modal*/
  const [plainTextEmail, setPlainTextEmail] = useState(false);
  const [selectedLead, setSelectedLead] = useState({});

  const [selectedScript, setSelectedScript] = useState(null);

  const openPlainTextEmail = (selectedLead, selectedScript = null) => {
    setSelectedScript(selectedScript)
    setPlainTextEmail(true);
    setSelectedLead(selectedLead);
  };
  const paintextEmailClose = () => {
    setPlainTextEmail(false);
  };
  /*plain text email modal*/

  /*add script modal*/

  const [addScriptModal, setScriptModal] = useState(false);

  const scriptModalClose = () => {
    setScriptModal(false);
  };

  /*add script modal*/

  /*expand lead section*/

  const [openLead, setOpenLead] = useState(null);

  useEffect(() => {
    handleAccordionClick(activeLeadID);
  }, [activeLeadID])


  const handleAccordionClick = (lead_ui_id) => {
    setOpenLead(openLead === lead_ui_id ? null : lead_ui_id);
  };

  /*expand lead section*/

  /*create note modal*/

  const [createNoteOpen, setCreateNoteOpen] = useState(false);
  const [noteLeadData, setNoteLeadData] = useState([]);
  const [openNotesMobModal, setOpenNotesMobModal] = useState(false);
  const createNoteOpenModal = (leadData) => {
    setCreateNoteOpen(true);
    setNoteLeadData(leadData);
    setOpenNotesMobModal(false)
    document.body.classList.remove('mob-overflow-body');
  };
  const createNoteCloseModal = () => {
    setCreateNoteOpen(false);
  };

  const handleNotesMobileModal = () => {
    setOpenNotesMobModal(true);
    document.body.classList.add('mob-overflow-body');
  };
  const closeNotesMobModal = () => {
    setOpenNotesMobModal(false);
    document.body.classList.remove('mob-overflow-body');
  };
  //console.log("openNotesMobModal", openNotesMobModal)
  /*create note modal*/

  /*text email modal*/

  const [textLead, setTextLead] = useState(false);
  const [currentPageData, setCurrentPageData] = useState([]);

  const textLeadModal = (selectedLead) => {
    setTextLead(true);
    setSelectedLead(selectedLead)
  };
  const textLeadCloseModal = () => {
    setTextLead(false);
  };

  /*text email modal*/

  // Pagination 
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 20;
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;

  useEffect(() => {
    setCurrentPage(1);
    setCurrentPageData(filteredLeads?.slice(startIndex, endIndex));
  }, [filteredLeads]);

  useEffect(() => {
    setCurrentPageData(filteredLeads?.slice(startIndex, endIndex));
  }, [currentPage]);

  const maxPaginationLength = 8;
  const totalPages = Math.ceil(filteredLeads?.length / pageSize);
  // Function to handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  // Function to handle next page
  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  // Function to handle previous page
  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const [paginationButtons, setPaginationButtons] = useState([]);

  useEffect(() => {

    const buttons = [];
    let startPage, endPage;

    if (totalPages <= maxPaginationLength) {
      startPage = 1;
      endPage = totalPages;
    } else {
      const halfMax = Math.floor(maxPaginationLength / 2);
      if (currentPage <= halfMax) {
        startPage = 1;
        endPage = maxPaginationLength;
      } else if (currentPage + halfMax >= totalPages) {
        startPage = totalPages - maxPaginationLength + 1;
        endPage = totalPages;
      } else {
        startPage = currentPage;
        endPage = currentPage + Math.floor(maxPaginationLength - 1);
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      buttons.push(i);
    }
    setPaginationButtons(buttons);

  }, [singlePagination, filteredLeads]);

  const [openLeadFilter, setOpenLeadFilter] = useState(false)
  const handleLeadFilter = () => {
    setOpenLeadFilter(!openLeadFilter)
  }
  const [openMobArrow, setOpenMobArrow] = useState(false);
  const [mobLeadItem, setMobLeadItem] = useState('');
  const handleMobArrow = (leadItem) => {
    setOpenMobArrow(true);
    setMobLeadItem(leadItem);
  }
  const closeMobItem = () => {
    setOpenMobArrow(false);
    document.body.classList.remove('mob-overflow-body');
  }
  const cancelMobLeadItem = () => {
    setMobLeadItem('');
  }
  // Waiting Response
  const [dealStageModal, setDealStageModal] = useState(false);

  const [dealStageLeadData, setDealStageLeadData] = useState([]);

  const openDealStageModal = (leadData) => {
    setDealStageLeadData(leadData);
    setDealStageModal(true);
    document.body.classList.add('overflow-body');
  }
  const closeDealStageModal = () => {
    setDealStageModal(false);
    document.body.classList.remove('overflow-body');
  }

  return (
    <>
      {/* {console.log("currentItems", currentItems)} */}
      <div className="lead-list-block">
        <div className='d-lg-none'>
          <button className='mob-back-btn' type='button' onClick={handleBackBtn}><span><img src={getImages('left-arrow.svg')} /></span> Back</button>
        </div>
        <div className='d-flex align-items-center justify-content-between filter-block lead-filter-block'>
          <div className="d-flex align-items-center justify-content-between">
            <div className='main-heading'>ACTIVE LEADS</div>
            <div className="d-lg-none text-end">
              <button type="button" onClick={handleLeadFilter} className="blue-transparent-btn text-uppercase filter-btn"><img src={getImages('filter-icon.svg')} /> Filters</button>
            </div>
          </div>

          <div className={`d-inline-flex align-items-center list_filter ${openLeadFilter && 'selected'}`}>
            <div className='d-flex align-items-center filter-col'>
              <label>DATE FILTER</label>
              <select className="form-control">
                <option value="all days">All Days</option>
              </select>
            </div>
            <div className="d-flex align-items-center filter-col ms-4">
              <label>SORT BY</label>
              <select className="form-control">
                <option value="name">Name</option>
              </select>
            </div>
            <div className="d-flex align-items-center filter-col ms-4">
              <label>RANGE</label>
              <select className="form-control">
                <option value="high to low">High to Low</option>
              </select>
            </div>
          </div>
          <div className="d-lg-none search-for-mob">
            <AutoComplete
              openMobArrow={openMobArrow}
              handleMobArrow={handleMobArrow}
            />
          </div>
        </div>
        <div className="custom-table">
          {filteredLeads && !filteredLeads.length > 0 ?
            <div className="text-white text-center py-5 my-5">Loading...</div>
            :
            <>
              <table className="w-100">
                <thead>
                  <tr>
                    <td className="p-0">
                      <table className="w-100">
                        <thead>
                          <tr>
                            <th style={{ width: "5%" }}>&nbsp;</th>
                            <th style={{ width: "18%" }}>Customer Name</th>
                            <th style={{ width: "15%" }}>Vehicle Info</th>
                            <th className="text-center" style={{ width: "7%" }}>
                              Source
                            </th>
                            <th className="text-center" style={{ width: "7%" }}>
                              Deal Stage
                            </th>
                            <th className="text-center" style={{ width: "7%" }}>
                              Appraisal
                            </th>
                            <th className="text-center" style={{ width: "7%" }}>
                              Email Sent
                            </th>
                            <th className="text-center" style={{ width: "7%" }}>
                              Phone Call
                            </th>
                            <th className="text-center" style={{ width: "6%" }}>
                              Notes
                            </th>
                            <th className="text-center" style={{ width: "7%" }}>
                              Timer
                            </th>
                            <th className="text-center" style={{ width: "16%" }}>
                              Assigned To
                            </th>
                          </tr>
                        </thead>
                      </table>
                    </td>
                  </tr>
                </thead>
                <tbody className="accordion custom-accordion" id="accordionLeads">
                  <>
                    {currentPageData && currentPageData.map((leadData, index) =>
                      <LeadItem
                        leadData={leadData}
                        key={index}
                        openPlainTextEmail={openPlainTextEmail}
                        handleAccordionClick={handleAccordionClick}
                        openLead={openLead}
                        createNoteOpenModal={createNoteOpenModal}
                        textLeadModal={textLeadModal}
                        handleMobArrow={handleMobArrow}
                        openDealStageModal={openDealStageModal}
                        openMobArrow={openMobArrow}
                        mobLeadItem={mobLeadItem}
                        cancelMobLeadItem={cancelMobLeadItem}
                        handleNotesMobileModal={handleNotesMobileModal}
                        openNotesMobModal={openNotesMobModal}
                        closeNotesMobModal={closeNotesMobModal}
                      />
                    )}

                  </>
                </tbody>
              </table>
              {/* Pagination */}
              <div className='text-center pagination-block my-5'>
                <nav>
                  <ul className="pagination">
                    {/* Render previous button */}
                    <li className="page-item back-item" key={'start'}>
                      <a className={`page-link ${currentPage === 1 ? "disabled" : ""}`} onClick={prevPage} disabled={currentPage === 1}>
                        Back
                      </a>
                    </li>
                    {/*console.log(singlePagination)*/}
                    {/* Render pagination buttons */}
                    {!singlePagination && paginationButtons.map((v, i) => {
                      return (
                        <li className="page-item" key={v}>
                          <a key={v} onClick={() => handlePageChange(v)} className={v === currentPage ? "active page-link" : "page-link"}>
                            {v}
                          </a>
                        </li>
                      )
                    })

                    }
                    {singlePagination && <a key={1} className={"active page-link"}>{1}</a>}
                    <li className="page-item next-item">
                      <a className={`page-link ${currentPage === totalPages ? "disabled" : ""}`} onClick={nextPage} disabled={currentPage === totalPages}>
                        Next
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </>
          }
          {/* <div className="lead-item" ref={lastItemRef}></div> */}
        </div>
      </div>
      {/* <!-- Modal --> */}
      <ModalLayout open={plainTextEmail} close={paintextEmailClose}>
        <PlainTextEmail
          leadData={selectedLead}
          onAddScriptBtnClick={() => {
            setScriptModal(true);
          }}
          close={paintextEmailClose}
          selectedScript={selectedScript}
        />
      </ModalLayout>
      <ModalLayout open={addScriptModal} close={scriptModalClose} >
        <ScriptModal
          close={scriptModalClose} />
      </ModalLayout>
      <ModalLayout open={createNoteOpen} cls="mxw-550" close={createNoteCloseModal}>
        <CreateNotesModal close={createNoteCloseModal} leadData={noteLeadData} />
      </ModalLayout>

      <ModalLayout open={textLead} cls="mxw-750" close={textLeadCloseModal}>
        <TextLead
          close={textLeadCloseModal}
          leadData={selectedLead}
        />
      </ModalLayout>
      {/* <MobLeadItem
        openPlainTextEmail={openPlainTextEmail}
        handleAccordionClick={handleAccordionClick}
        openLead={openLead}
        createNoteOpenModal={createNoteOpenModal}
        textLeadModal={textLeadModal}
        openMobArrow={openMobArrow}
        leadData={mobLeadItem}
        closeMobItem={closeMobItem}
        openNotesMobModal={openNotesMobModal}
        handleNotesMobileModal={handleNotesMobileModal}
        closeNotesMobModal={closeNotesMobModal}
        openDealStageModal={openDealStageModal}
      /> */}


      <ModalLayout open={dealStageModal} cls="mxw-750" waitingCls="update-deal-bg" close={closeDealStageModal}>
        <WaitingResponseModal close={closeDealStageModal} leadData={dealStageLeadData}/>
      </ModalLayout>

    </>
  );
};

export default Leads;
