import React, { useState, useEffect } from "react";
import * as d3 from "d3";

const BarChart = () => {
  const [data, setData] = useState([
    {
      name: "Ferrari",
      value: 50,
    },
    {
      name: "Maserati",
      value: 20,
    },
    {
      name: "Porsche",
      value: 40,
    },
    {
      name: "Lamborghini",
      value: 80,
    },
    {
      name: "Volvo",
      value: 70,
    },
    {
      name: "Audi",
      value: 60,
    },
  ]);

  useEffect(() => {
    const margin = { top: 20, right: 20, bottom: 30, left: 35 };
    const width = 470 - margin.left - margin.right;
    const height = 263 - margin.top - margin.bottom;

    const x = d3.scaleBand().range([0, width]).padding(0.35);
    const y = d3.scaleLinear().range([height, 0]);

    // Remove existing SVG
    d3.select(".bar-chart svg").remove();

    const svg = d3
      .select(".bar-chart")
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    // Define the gradient
    const gradient = svg
      .append("defs")
      .append("linearGradient")
      .attr("id", "bar-gradient")
      .attr("x1", "0%")
      .attr("y1", "0%")
      .attr("x2", "0%")
      .attr("y2", "100%");

    gradient
      .append("stop")
      .attr("offset", "0%")
      .attr("stop-color", "#79bb30")
      .attr("stop-opacity", 1);

    gradient
      .append("stop")
      .attr("offset", "100%")
      .attr("stop-color", "rgb(121 187 48 / 20%)")
      .attr("stop-opacity", 1);
    const maxRoundedValue = 20 * Math.ceil(d3.max(data, (d) => d.value) / 20);
    const roundedDomain = [0, maxRoundedValue];
    x.domain(data.map((d) => d.name));
    y.domain([0, 100]);;

    svg
      .selectAll(".bar")
      .data(data)
      .enter()
      .append("rect")
      .attr("class", "bar")
      .attr("x", (d) => x(d.name))
      .attr("width", x.bandwidth())
      .attr("y", (d) => y(d.value))
      .attr("height", (d) => height - y(d.value))
      .style("fill", "url(#bar-gradient)");

    svg
      .append("g")
      .attr("transform", "translate(0," + height + ")")
      .call(d3.axisBottom(x).tickSizeOuter(0));

    svg.append("g").call(d3.axisLeft(y).ticks(5).tickSizeOuter(0));

    const yAxis = svg
      .append("g")
      .call(d3.axisLeft(y).ticks(5).tickSizeOuter(0)); 

    yAxis.selectAll(".tick").each(function (d) {
        svg
          .append("line")
          .attr("x1", 0)
          .attr("y1", y(d))
          .attr("x2", width)
          .attr("y2", y(d))
          .attr("stroke", "rgb(255 255 255 / 50%)")
          .attr("stroke-width", 0.5)
      });
      d3.selectAll(".domain").remove();
      svg.selectAll(".tick line").remove();
        svg.selectAll(".tick text")
            .style("fill", "white"); 
  }, [data]);

  return (
    <div
      className="bar-chart"
      style={{
        width: "550px",
        margin: "0 auto",
      }}
    ></div>
  );
};

export default BarChart;
