import React, { useState } from 'react'
import Layout from '../Components/Layouts/Layout'
import { Field, Formik, Form } from 'formik';
import { getImages } from '../Components/Const'
import PhotosUpload from '../Components/SendToAuction/PhotosUpload'
import ModalLayout from '../Components/Modals/ModalLayout'
import ShortVinModal from '../Components/SendToAuction/ShortVinModal'
import * as yup from 'yup';


const validate_vin = (inputVIN) => {
    var vin = inputVIN.toLowerCase().trim();

    var patt = new RegExp("^[^Wioq]{17}$");

    if (!patt.test(vin)) {
        return false;
    }

    var weights = [8, 7, 6, 5, 4, 3, 2, 10, 0, 9, 8, 7, 6, 5, 4, 3, 2];

    var transliterations = [];

    transliterations["a"] = 1;
    transliterations["b"] = 2;
    transliterations["c"] = 3;
    transliterations["d"] = 4;
    transliterations["e"] = 5;
    transliterations["f"] = 6;
    transliterations["g"] = 7;
    transliterations["h"] = 8;
    transliterations["j"] = 1;
    transliterations["k"] = 2;
    transliterations["l"] = 3;
    transliterations["m"] = 4;
    transliterations["n"] = 5;
    transliterations["p"] = 7;
    transliterations["r"] = 9;
    transliterations["s"] = 2;
    transliterations["t"] = 3;
    transliterations["u"] = 4;
    transliterations["v"] = 5;
    transliterations["w"] = 6;
    transliterations["x"] = 7;
    transliterations["y"] = 8;
    transliterations["z"] = 9;

    var sum = 0;
    var i = 0;

    for (i = 0; i < vin.length; i++) {
        // loop through characters of VIN
        // add transliterations * weight of their positions to get the sum
        if (isNaN(vin[i])) {
        sum += transliterations[vin[i]] * weights[i];
        } else {
        sum += parseInt(vin[i]) * weights[i];
        }
    }

    var checkdigit = sum % 11;

    if (checkdigit == 10) {
        checkdigit = "x";
    }

    return checkdigit == vin[8];
}
const validationSchemaStep = yup.object({
    vin: yup.string().required("Please enter your VIN").test(
        "valid-vin",
        "Please enter a valid VIN.",
        (value) => validate_vin(value)
      ),
    miles: yup.number().required("Please enter a mileage").min(1,'Mileage must be greater than 0'),
    email: yup.string().nullable().email(),
});
const SendToAuction = () => {
    const [openShortVinModal, setOpenShortVinModal] = useState(false);
    const handleShortVinModal = () => {
        setOpenShortVinModal(true);
        document.body.classList.add('overflow-body');
    }
    const closeShortVinModal = () => {
        setOpenShortVinModal(false);
        document.body.classList.remove('overflow-body');
    }
    const [formikData, setFormikData] = useState({
        vin: "",
        trim: "",
        body_style: "",
        ext_color: "",
        int_color: "",
        transmission: "",
        drivetrain: "",
        msrp: "",
        mileage: "",
        reserve_price: "$150,000",
        buy_now_price: "$175,000",
        showReserve: "",
        auction_duration: "",
        auction_seats: "",
    });
    const [vinCheck, setVinCheck] = useState(false);
    const handleVinCheck = () => {
        setVinCheck(true);
    }
  return (
    <>
        <Layout>
            <div className='main-block lead-source-wrap send-auction-wrap pt-5'>
                <div className='container mxw-1220'>
                    <div className='d-flex align-items-center justify-content-between reporting-header'>
                        <div className='lg-title text-uppercase font-26'>Send to Live Auction</div>
                        <div className='send-processbar d-flex align-item-center'>
                            <div className='circle text-start active'>
                                <span className='sp-label'>Vehicle Details</span>
                            </div>
                            <div className='circle text-end'>
                                <span className='sp-label'>Review Auction</span>
                            </div>
                        </div>
                    </div>
                    <div className='dark-theme mb-5'>
                        <div className='gray-box-header text-uppercase'>Vehicle Details</div>
                        <div className='gray-box w-100 border-radius-0 custom-form send-form-block'>
                            <Formik
                                initialValues={formikData}
                                validationSchema={validationSchemaStep}
                                onSubmit={(values, { resetForm }) => {
                                    console.log(values);
                                }}
                                enableReinitialize={true}
                            >
                                {({ values }) => (
                                    <Form>
                                        <div className='row mlr-55'>
                                            <div className='col-lg-6'>
                                                <div className='send-vin-number mb-4'>
                                                    <div className='w-100'><label>VIN Number</label></div>
                                                    <div className="d-flex align-items-center mb-2">
                                                        <Field name="vin" type="text" className='form-control' placeholder='ABCD1234567891122'/>
                                                        <button className='blue-btn text-uppercase lg-btn' type='button' onClick={handleVinCheck}><img src={getImages('check-white.svg')}/></button>
                                                    </div>
                                                    <div className='info-text text-start font-12 mt-2'>Tip: Enter VIN and we’ll pre-fill the rest for you.</div>
                                                </div>
                                            </div>
                                            <div className={`col-lg-6 ${!vinCheck ? "add-or-before" :"" } `}>
                                                {!vinCheck ? 
                                                    <div className='mb-5'>
                                                        <div className='w-100'><label>&nbsp;</label></div>
                                                        <button type='button' className='blue-transparent-btn text-uppercase w-100'>Search from Inventory</button>
                                                    </div> : 
                                                    
                                                    <div className='mb-5'>
                                                        <div className='text-center mt-1'><label className='fw-500'>Vehicle Matched!</label></div>
                                                        <div className='md-title text-green text-center fw-500 mt-2 text-uppercase'>2015 Maserati Gran Turismo Sport</div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        <div className='row mlr-55'>
                                            <div className='col-lg-6'>
                                                <div className='row'>
                                                    <div className='col-md-6 col-6 form-group'>
                                                        <label>Trim</label>
                                                        <Field name="trim" as="select" className='form-control'>
                                                            <option>Select</option>
                                                            <option>Sport</option>
                                                            <option>Deluxe</option>
                                                            <option>Custom</option>
                                                            <option>Limited</option>
                                                        </Field>
                                                    </div>
                                                    <div className='col-md-6 col-6 form-group'>
                                                        <label>Body Style</label>
                                                        <Field name="body_style" as="select" className='form-control'>
                                                            <option>Select</option>
                                                            <option>Sedan</option>
                                                            <option>Coupe</option>
                                                            <option>Wagon</option>
                                                            <option>Hatch</option>
                                                            <option>SUV</option>
                                                        </Field>
                                                    </div>
                                                    <div className='col-md-6 form-group'>
                                                        <div className='row'>
                                                            <div className='col-6'>
                                                                <label>Ext. Color</label>
                                                                <Field name="ext_color" as="select" className='form-control'>
                                                                    <option>Select</option>
                                                                    <option>Beige</option>
                                                                    <option>Black</option>
                                                                    <option>Tan</option>
                                                                    <option>Brown</option>
                                                                    <option>Ivory</option>
                                                                    <option>White</option>
                                                                    <option>Gray</option>
                                                                </Field>
                                                            </div>
                                                            <div className='col-6'>
                                                                <label>Int. Color</label>
                                                                <Field name="int_color" as="select" className='form-control'>
                                                                    <option>Select</option>
                                                                    <option>Beige</option>
                                                                    <option>Black</option>
                                                                    <option>Tan</option>
                                                                    <option>Brown</option>
                                                                    <option>Ivory</option>
                                                                    <option>White</option>
                                                                    <option>Gray</option>
                                                                </Field>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6 form-group'>
                                                        <div className='row'>
                                                            <div className='col-6'>
                                                                <label>Transmission</label>
                                                                <Field name="transmission" as="select" className='form-control'>
                                                                    <option>Select</option>
                                                                    <option>Manual</option>
                                                                    <option>Automatic</option>
                                                                    <option>Dual-clutch</option>
                                                                    <option>Hybrid</option>
                                                                </Field>
                                                            </div>
                                                            <div className='col-6'>
                                                                <label>Drivetrain</label>
                                                                <Field name="drivetrain" as="select" className='form-control'>
                                                                    <option>Select</option>
                                                                    <option>AWD</option>
                                                                    <option>FWD</option>
                                                                    <option>RWD</option>
                                                                </Field>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6 col-6 form-group'>
                                                        <label>MSRP</label>
                                                        <Field name="msrp" type="text" className='form-control'/>
                                                    </div>
                                                    <div className='col-md-6 col-6 form-group'>
                                                        <label>Mileage</label>
                                                        <Field name="mileage" type="text" className='form-control'/>
                                                    </div>
                                                </div>
                                                <div className='sm-title text-white fw-400 mt-5'>Short VIN or no VIN? <button type="button" className='text-green text-uppercase ps-4 click-here-btn' onClick={handleShortVinModal}>Click Here</button></div>
                                            </div>
                                            <div className='col-lg-6'>
                                                <div className='send-reserve-box'>
                                                    <div className='form-group'>
                                                        <label>Reserve Price</label>
                                                        <Field name="reserve_price" type="text" className='form-control sr-control'/>
                                                        <div className='info-text text-start font-12 mt-2'>This is the minimum price you’re willing to accept</div>
                                                    </div>
                                                    <div className='form-group'>
                                                        <label>Buy it Now Price</label>
                                                        <Field name="buy_now_price" type="text" className='form-control sr-control'/>
                                                    </div>
                                                    <div className='form-group'>
                                                        <label className="custom-checkbox">
                                                            <Field type="checkbox" name="showReserve" id="chk_Show_Reserve" />
                                                            <label htmlFor="chk_Show_Reserve"> Show Reserve</label>
                                                        </label>
                                                        <div className='info-text text-start font-12 mt-0'>Reserve will be displayed on vehicle details page</div>
                                                    </div>
                                                    <div className='form-group'>
                                                        <label>Auction Duration 
                                                            <span className="info-msg">												
                                                                <img className="ico_info" src={getImages('ico-info.svg')} alt="info" />
                                                                <span className='info-pos'>
                                                                    <h6>WEBSITE Visits</h6>
                                                                    <p>Number of visitors to your website during Current period.</p>
                                                                </span>
                                                            </span>
                                                        </label>
                                                        <Field name="auction_duration" as="select" className='form-control'>
                                                            <option>Select</option>
                                                        </Field>
                                                    </div>
                                                    <div className='form-group'>
                                                        <label>Auction Seats
                                                            <span className="info-msg">												
                                                                <img className="ico_info" src={getImages('ico-info.svg')} alt="info" />
                                                                <span className='info-pos'>
                                                                    <p>Number of visitors to your website during Current period.</p>
                                                                </span>
                                                            </span>
                                                        </label>
                                                        <Field name="auction_seats" as="select" className='form-control'>
                                                            <option>Select</option>
                                                        </Field>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <PhotosUpload/>
                                        <div className='text-end'>
                                            <button type='submit' className='blue-btn text-uppercase lg-btn finish-btn' disabled>Finish</button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
        <ModalLayout open={openShortVinModal} cls="mxw-800 theme-black-modal" close={closeShortVinModal}>
            <ShortVinModal close={closeShortVinModal} />
        </ModalLayout>
    </>
  )
}

export default SendToAuction