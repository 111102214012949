import React, {useContext, useEffect, useState} from 'react'
import { getImages } from '../Const'
import { LeadsContext } from '../Context/LeadsContext'

const LeadReplies = ({ close,setReplyCount }) => {

    const {leadData} = useContext(LeadsContext);

    const [leadReplies, setLeadReplies] = useState([]);

    useEffect(() => {
        var notes = [];

        leadData.map((v,i) => {
            if(v.notes && Array.isArray(v.notes)) {
                v.notes.map((w,j) => {
                    if(w.lead_reply) {
                        var this_note = w;
                        this_note.lead_id = v.lead_id;
                        this_note.full_name = v.full_name;
                        this_note.vehicle_name = v.vehicle_name;
                        notes.push(w);
                    }
                })
            }
        });

        setReplyCount(notes.length);
        setLeadReplies(notes);
    },[leadData]);

    return (
        <>
            <div className="modal-header">
                <h1 className="modal-title text-uppercase" id="staticBackdropLabel">
                    Lead Replies
                </h1>
                <button className="sm-box-close" type="button" onClick={close}>
                    <i className="fa-solid fa-xmark"></i>
                </button>
            </div>
            <div className="modal-body px-0">
                <div className='custom-table2 with-white-bg task-box'>
                    <table className='w-100'>
                        <thead>
                            <tr>
                                <th className='text-center'>&nbsp;</th>
                                <th>Type</th>
                                <th className='text-center'>Date</th>
                                <th className='text-center'>Name</th>
                                <th className='text-center'>Vehicle Details</th>
                                <th className='text-center'>Note</th>
                            </tr>
                        </thead>
                        <tbody >
                            {leadReplies && leadReplies.map((v,i) => {
                                return (
                                    <tr key={i}>
                                        <td>
                                            <div className='d-flex align-items-center'>
                                                <div className='mnw-25px text-center inv-title1 fw-500 dgray'>{i + 1}</div>
                                            </div>
                                        </td>
                                        <td className='inv-title1 text-center dgray'>{v.date}</td>
                                        <td className='inv-title1 text-center dgray'>{v.created_at}</td>
                                        <td className='inv-title1 text-center dgray'>{v.full_name}</td>
                                        <td className='inv-title1 text-center dgray'>{v.vehicle_name}</td>
                                        <td className='inv-title1 text-center dgray'>{v.trimmed_note}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default LeadReplies