import React, { useState } from 'react'
import { getImages } from '../Const'
import { Field, Formik, Form } from 'formik';
import * as yup from 'yup';

const validationSchemaStep = yup.object({
    
});
const ShortVinModal = ({close}) => {
    const [formikData, setFormikData] = useState({
        vinNumber: "",
        Year: "",
        make: "",
        model: "",
        trim: "",
        body_style: "",
        vehicleCondition: "",
        ext_color: "",
        int_color: "",
        transmission: "",
        drivetrain: "",
        msrp: "",
        mileage: "",
    });
    return (
        <>
            <div className='modal-body p-0'>
                <Formik
                    initialValues={formikData}
                    validationSchema={validationSchemaStep}
                    onSubmit={(values, { resetForm }) => {
                        console.log("values", values);
                    }}
                    enableReinitialize={true}
                >
                    {({ values }) => (
                        <Form>
                            <div className='modal-header position-relative'>
                                <div className='md-title text-center text-white fw-400 w-100'>Short VIN or no VIN</div>
                                <button className="sm-box-close" type="button" onClick={close}><img src={getImages('white-close.svg')} /></button>
                            </div>
                            <div className='modal-body p-5'>
                                <div className='custom-form send-form-block mxw-500'>
                                    <div className='row'>
                                        <div className='col-12 form-group'>
                                            <label>VIN Number</label>
                                            <Field name="vinNumber" type="text" className='form-control'/>
                                        </div>
                                        <div className='col-md-6 col-6 form-group'>
                                            <label>Year</label>
                                            <Field name="year" type="text" className='form-control'/>
                                        </div>
                                        <div className='col-md-6 col-6 form-group'>
                                            <label>Make</label>
                                            <Field name="make" type="text" className='form-control'/>
                                        </div>
                                        <div className='col-md-6 col-6 form-group'>
                                            <label>Model</label>
                                            <Field name="model" type="text" className='form-control'/>
                                        </div>
                                        <div className='col-md-6 col-6 form-group'>
                                            <label>Trim</label>
                                            <Field name="trim" as="select" className='form-control'>
                                                <option>Select</option>
                                                <option>Sport</option>
                                                <option>Deluxe</option>
                                                <option>Custom</option>
                                                <option>Limited</option>
                                            </Field>
                                        </div>
                                        <div className='col-md-6 col-6 form-group'>
                                            <label>Body Style</label>
                                            <Field name="body_style" as="select" className='form-control'>
                                                <option>Select</option>
                                                <option>Sedan</option>
                                                <option>Coupe</option>
                                                <option>Wagon</option>
                                                <option>Hatch</option>
                                                <option>SUV</option>
                                            </Field>
                                        </div>
                                        <div className='col-md-6 col-6 form-group'>
                                            <label>Vehicle Condition</label>
                                            <Field name="vehicleCondition" as="select" className='form-control'>
                                                <option>Select</option>
                                            </Field>
                                        </div>
                                        <div className='col-md-6 form-group'>
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <label>Ext. Color</label>
                                                    <Field name="ext_color" as="select" className='form-control'>
                                                        <option>Select</option>
                                                        <option>Beige</option>
                                                        <option>Black</option>
                                                        <option>Tan</option>
                                                        <option>Brown</option>
                                                        <option>Ivory</option>
                                                        <option>White</option>
                                                        <option>Gray</option>
                                                    </Field>
                                                </div>
                                                <div className='col-6'>
                                                    <label>Int. Color</label>
                                                    <Field name="int_color" as="select" className='form-control'>
                                                        <option>Select</option>
                                                        <option>Beige</option>
                                                        <option>Black</option>
                                                        <option>Tan</option>
                                                        <option>Brown</option>
                                                        <option>Ivory</option>
                                                        <option>White</option>
                                                        <option>Gray</option>
                                                    </Field>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-6 form-group'>
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <label>Transmission</label>
                                                    <Field name="transmission" as="select" className='form-control'>
                                                        <option>Select</option>
                                                        <option>Manual</option>
                                                        <option>Automatic</option>
                                                        <option>Dual-clutch</option>
                                                        <option>Hybrid</option>
                                                    </Field>
                                                </div>
                                                <div className='col-6'>
                                                    <label>Drivetrain</label>
                                                    <Field name="drivetrain" as="select" className='form-control'>
                                                        <option>Select</option>
                                                        <option>AWD</option>
                                                        <option>FWD</option>
                                                        <option>RWD</option>
                                                    </Field>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-6 col-6 form-group'>
                                            <label>MSRP</label>
                                            <Field name="msrp" type="text" className='form-control'/>
                                        </div>
                                        <div className='col-md-6 col-6 form-group'>
                                            <label>Mileage</label>
                                            <Field name="mileage" type="text" className='form-control'/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='modal-footer d-flex align-items-center justify-content-between'>
                                <button type='button' className='switch-back-vin'>Switch back to regular VIN</button>
                                <div>
                                    <button type='submit' className='blue-btn lg-btn switch-vin-btn'>Save</button>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </>
    )
}

export default ShortVinModal