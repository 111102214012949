import { Field, Formik, Form } from 'formik'
import TextAreaField from '../Form/TextAreaField'
import { useContext, useState } from 'react';
import { LeadsContext } from '../Context/LeadsContext';
import { UserContext } from '../Context/UserContext';

const CreateNotesModal = ({close,leadData}) => {

    const [note, setNote] = useState("");

    const {addNote} = useContext(LeadsContext);
    const {user} = useContext(UserContext);

    const submitNoteForm = async (e) => {
        e.preventDefault();

        const formData = Object.fromEntries(new FormData(e.target));

        const response = await addNote(formData, leadData);

        if (response && response.success) {
            //close();
            //alert('Your email has been sent');

            setNote("");

            leadData.notes.push(formData);
        } else alert('Error adding note');
    }

    return (
        <>

            <div className="modal-header">
                <h1 className="modal-title text-uppercase" id="staticBackdropLabel">Create Note</h1>
                <button className="sm-box-close" type="button" onClick={close}><i className="fa-solid fa-xmark"></i></button>
            </div>
            <div className="modal-body px-0 py-4">
                <form className="modal-body px-0 py-4" onSubmit={(e) => submitNoteForm(e)}>
                        <input type="hidden" name="lead_id" value={leadData.id || ""} />
                        <input type="hidden" name="title" value="Agent Note" />
                        <input type="hidden" name="subtitle" value={user && user.first_name && user.last_name ? user.first_name + " " + user.last_name : ""} />
                        <div className='form-group mb-3'>
                            <textarea className="form-control h180" placeholder="" name="note" value={note} onChange={(e) => setNote(e.target.value)} />
                        </div>
                        <div className='mt-2 text-end'>
                            <button className='blue-btn text-uppercase' type='submit'>Save</button>
                        </div>
                </form>
            </div>
        </>
    )
}

export default CreateNotesModal