import thumbImg1 from '../../assets/img/photo-main.png'
import thumbImg2 from '../../assets/img/thumb1.png'
import thumbImg3 from '../../assets/img/thumb2.png'
import Fancybox from './Fancybox'

const Gallery = ({ propertyData, liveAuction, landDealLoading }) => {
    const imgs = propertyData && propertyData.length > 0 ? propertyData : [
        thumbImg1,
        thumbImg2,
        thumbImg3,
        thumbImg1,
        thumbImg2,
        thumbImg3,
        thumbImg1,
        thumbImg2,
        thumbImg3,
    ]
    const moreImg = imgs.length - 4;
    return (
        <>
            {/* {liveAuction ? '' : (<div className="box-title text-uppercase">Photos</div>)} */}
            {console.log("propertyData", propertyData)}
            <Fancybox
                options={{
                    Carousel: {
                        infinite: false,
                    },
                }}
            >
                {landDealLoading ?
                    "Loading" :
                    <>
                        <div className="d-flex justify-content-between">
                            <div className="full-photo">
                                <a data-fancybox="gallery" href={imgs?.[0]} className='position-relative'>
                                    <img src={propertyData && propertyData?.[0] !== undefined ? propertyData?.[0] : thumbImg1} alt="th img" />
                                </a>
                            </div>
                            <div className='photos-list'>
                                {imgs &&
                                    imgs.slice(1).map((item, index) => {
                                        return (
                                            <div className="p-col w-100" key={index}>
                                                <a data-fancybox="gallery" href={item} className='position-relative'>
                                                    <img src={item} alt="th img" />
                                                    {index >= 2 ? <div className='countimg'>+{moreImg}</div> : ""}
                                                </a>
                                            </div>
                                        );
                                    })}
                            </div>
                        </div>
                    </>
                }
            </Fancybox>
        </>
    )
}

export default Gallery