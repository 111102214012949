import React, { useState, useContext, useEffect } from 'react'
import Layout from '../Components/Layouts/Layout'
import TopSection from '../Components/Common/TopSection'
import DatePicker from 'react-multi-date-picker'
import { getImages } from '../Components/Const'
import LeadSourceItem from '../Components/LeadSource/LeadSourceItem'

import { LeadsContext } from '../Components/Context/LeadsContext';
import LeadSourceStaticData from '../Components/LeadSource/LeadSourceStaticData'

const LeadSource = () => {
    const [values, setValues] = useState([]);
    
    const {leadData, getLeadSourceData} = useContext(LeadsContext);

    const [openLead, setOpenLead] = useState(null);
    const handleAccordionClick = (lead_ui_id) => {
        setOpenLead(openLead === lead_ui_id ? null : lead_ui_id);
    };
    const [openMobArrow, setOpenMobArrow] = useState(false);
    const [mobLeadItem, setMobLeadItem] = useState('');
    const handleMobArrow = (leadItem) => {
        setOpenMobArrow(true);
        setMobLeadItem(leadItem);
        document.body.classList.add('mob-overflow-body');
    }
    const cancelMobLeadItem = () => {
        setMobLeadItem('');
        document.body.classList.remove('mob-overflow-body');
    }

    const [leadSourceData,setLeadSourceData] = useState([]);

    useEffect(() => {
        setLeadSourceData(getLeadSourceData(leadData))
    },[leadData]);

    const dateChange = (dates) => { 
		console.log("datepicker changed", dates, 0 in dates ? dates[0].unix : "", 1 in dates ? dates[1].unix : "");

        setLeadSourceData(getLeadSourceData(leadData,0 in dates ? dates[0].unix : "", 1 in dates ? dates[1].unix : ""));

        setValues(dates);
	}

    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    const today = new Date();
    var recentMonths = []

    for (var i = 0; i < 4; i++) {
        recentMonths.push({'month':today.getMonth() - i,'month_text':monthNames[today.getMonth() - i],'month_start':new Date(today.getFullYear(), today.getMonth() - i, 1),'month_end':new Date(today.getFullYear(), today.getMonth() - i + 1, 0)});
    }    

    const monthSelect = (e) => { 

        setLeadSourceData(getLeadSourceData(leadData,recentMonths[e.target.value]['month_start'].getTime() / 1000,recentMonths[e.target.value]['month_end'].getTime() / 1000));

        setValues([recentMonths[e.target.value]['month_start'],recentMonths[e.target.value]['month_end']]);
	}

    const clearDatePicker = () => {
        setLeadSourceData(getLeadSourceData(leadData));
        setValues([]);
    }

    return (
        <>
            {/* {console.log("leadSourceData", leadSourceData)} */}
            <Layout>
                <TopSection />
                <div className='main-block lead-source-wrap'>
                    <div className='container-fluid'>
                        <div className='d-flex align-items-center justify-content-between reporting-header'>
                            <div className='lg-title text-uppercase font-26'>GP by Lead Source</div>
                            <div className={`d-inline-flex align-items-center list_filter`}>
                                <div className='d-flex align-items-center filter-col me-5'>
                                    <label>Month</label>
                                    <select className="form-control" onChange={monthSelect}>
                                        {recentMonths.map((v,i) => 
                                            <option value={i} key={i}>{v.month_text}</option>
                                        )}                                        
                                    </select>
                                </div>
                                <div className='d-flex align-items-center'>
                                    <div className="pd-xs-title me-4">DATE RANGE</div>
                                    <div className="date-box">
                                        <div className="d-flex align-items-center hc-box">
                                            <div className="icon">
                                                <img className="ico_date" src={getImages('ico-date.svg')} alt='date' />
                                            </div>
                                            <div className='datepicker-box'>
                                                <DatePicker
                                                    value={values}
                                                    onChange={dateChange}
                                                    range
                                                    numberOfMonths={2}
                                                    showOtherDays
                                                    placeholder="MM/DD/YY ~ MM/DD/YY"
                                                />
                                                {/*<input type="text" className="date-input" name="daterange" value="" placeholder='MM/DD/YY' />
                                            </div>
                                            <div className='arrow-icon'>
                                                <img src={getImages('icon-calendar-arrow.png')} alt='arrow' />
                                            </div>
                                            <div>
                                                <input type="text" className="date-input" name="daterange" value="" placeholder='MM/DD/YY' /> */}
                                            </div>
                                            <div className="icon">
                                                <img className="ico_close dt-picker-clear" src={getImages('ico-close.svg')} alt="close" onClick={clearDatePicker}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="custom-table lead-source-table">
                            <table className="w-100">
                                <thead>
                                    <tr>
                                        <td className="p-0">
                                            <table className="w-100">
                                                <thead>
                                                    <tr>
                                                        <th style={{ width: "5%" }}>&nbsp;</th>
                                                        <th style={{ width: "10%" }}>&nbsp;</th>
                                                        <th style={{ width: "25%" }}>Source</th>
                                                        <th className="text-center" style={{ width: "15%" }}>
                                                            Leads
                                                        </th>
                                                        <th className="text-center" style={{ width: "15%" }}>
                                                            Sales
                                                        </th>
                                                        <th className="text-center" style={{ width: "15%" }}>
                                                            Total Revenue
                                                        </th>
                                                        <th className="text-center" style={{ width: "15%" }}>
                                                            Gross Profit
                                                        </th>
                                                    </tr>
                                                </thead>
                                            </table>
                                        </td>
                                    </tr>
                                </thead>
                                <tbody className="accordion custom-accordion" id="accordionLeads">
                                    <LeadSourceStaticData 
                                            handleAccordionClick={handleAccordionClick}
                                            openLead={openLead}
                                            handleMobArrow={handleMobArrow}
                                            mobLeadItem={mobLeadItem}
                                            cancelMobLeadItem={cancelMobLeadItem}
                                            />
                                    {leadSourceData && Object.keys(leadSourceData).map((key, i) =>
                                        <LeadSourceItem
                                            key={i}
                                            leadSourceId={i}
                                            leadSource={leadSourceData[key]}
                                            handleAccordionClick={handleAccordionClick}
                                            openLead={openLead}
                                            handleMobArrow={handleMobArrow}
                                            mobLeadItem={mobLeadItem}
                                            cancelMobLeadItem={cancelMobLeadItem}
                                        />
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default LeadSource