import React, { useState, useContext } from 'react'
import * as yup from 'yup';
import { UserContext } from '../Context/UserContext';
const AddAppraiserModal = ({ close, }) => {

    const {addUser} = useContext(UserContext);

    const [formData, setFormData] = useState({
        first_name:'',
        last_name:'',
        email:'',
        phone:''
    });

    const onChangeInput = (e) => {
        setFormData({
            ...formData,
            [e.target.name]:e.target.value
        })
    }

    const submitForm = async (e) => {
        e.preventDefault();

        console.log(formData);

        const data = await addUser(formData);

        console.log(data);

        if(data.message) alert(data.message.replaceAll('\\n','\n'));


        /*const data = await loginUser(formData);
        if(data.success){
            e.target.reset();
            setRedirect('Signing in...');
            await loggedInCheck();
            navigate("/");
            return;
        }
        setErrMsg(data.message);*/
    }

    return (
        <>
            <form className='custom-form form-style2' onSubmit={submitForm}>
                <div className="modal-header">
                    <h1 className="modal-title text-uppercase">
                        Add Appraiser
                    </h1>
                    <button className="sm-box-close" type="button" onClick={close}>
                        <i className="fa-solid fa-xmark"></i>
                    </button>
                </div>
                <div className="modal-body pd-25-65">
                    <div className='form-group mb-3'>
                        <label className='text-uppercase'>First Name</label>
                        <input name="first_name" type="text" className='form-control' onChange={onChangeInput}/>
                    </div>
                    <div className='form-group mb-3'>
                        <label className='text-uppercase'>Last Name</label>
                        <input name="last_name" type="text" className='form-control' onChange={onChangeInput}/>
                    </div>
                    <div className='form-group mb-3'>
                        <label className='text-uppercase'>Email</label>
                        <input name="email" type="text" className='form-control' onChange={onChangeInput}/>
                    </div>
                    <div className='form-group mb-4'>
                        <label className='text-uppercase'>Cell PH#</label>
                        <input name="phone" type="text" className='form-control' onChange={onChangeInput}/>
                    </div>
                    <div className='form-group text-end'>
                        <button className='blue-btn mnw-170 text-center text-uppercase' type='submit'>ADD APPRAISER</button>
                    </div>
                </div>
            </form>
        </>
    );
};

export default AddAppraiserModal;
