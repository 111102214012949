import React, { useState } from 'react'
import Layout from '../Components/Layouts/Layout'
import { getImages } from '../Components/Const'
import CommunityUpload from '../Components/ManageWebsite/CommunityUpload'
const initialTabs = [
  { id: "AboutUs-tab", target: "#AboutUs-tab-pane", label: "About Us", content: <div className='text-white'>About Us Content...</div>, active: false, isEditing: false },
  {
    id: "Community-tab", target: "#Community-tab-pane", label: "Community", 
    content: (
      <div>
        <CommunityUpload />
        <div className='text-area-block'>
          {["Text Area 1", "Text Area 2", "Text Area 3"].map((text, index) => (
            <div className='text-area-row mb-4' key={index}>
              <div className='md-title fw-500 text-white mb-3'>
                {text}
                <span className="info-msg mw-info-msg">
                  <img className="ico_info" src={getImages('ico-info.svg')} alt="info" />
                  <span className='info-pos'>
                    <p>Number of visitors to your website during the current period.</p>
                  </span>
                </span>
              </div>
              <div className='com-text-box'>
                <div className="com-text-scroll">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    ),
    active: true,
  },
  { id: "ContactUs-tab", target: "#ContactUs-tab-pane", label: "Contact Us", content: <div className='text-white'>Contact Us Content...</div>, active: false, isEditing: false },
  { id: "DMs-tab", target: "#DMs-tab-pane", label: "DMs", content: <div className='text-white'>DMs Content...</div>, active: false, isEditing: false },
  { id: "SomePageName-tab", target: "#SomePageName-tab-pane", label: "Some Page Name", content: <div className='text-white'>Some Page Name Content...</div>, active: false, isEditing: false },
];
const ManageWebsite = () => {
  const [tabs, setTabs] = useState(initialTabs);
  const handleDuplicate = (index) => {
    const newTabs = [...tabs];
    const tabToDuplicate = { ...newTabs[index] };
  
    const newId = `${tabToDuplicate.id}-copy-${index + 1}`;
    tabToDuplicate.id = newId;
    tabToDuplicate.target = `#${newId}-pane`;
    tabToDuplicate.label = `${tabToDuplicate.label} (Copy)`;
    tabToDuplicate.active = false;
    tabToDuplicate.isEditing = false;
    newTabs.splice(index + 1, 0, tabToDuplicate);
    newTabs.forEach((tab, i) => {
      tab.active = i === index;
    });
    setTabs(newTabs);
  };
  const handleEditStart = (index) => {
    const newTabs = tabs.map((tab, i) => ({
      ...tab,
      isEditing: i === index,
    }));
    setTabs(newTabs);
  };
  const handleChangeTabName = (event, index) => {
    const newTabs = [...tabs];
    newTabs[index].label = event.target.value;
    setTabs(newTabs);
  };
  const handleSaveTabName = (index) => {
    const newTabs = tabs.map((tab, i) => ({
      ...tab,
      isEditing: false,
    }));
    setTabs(newTabs);
  };
  const handleDelete = (index) => {
    const newTabs = tabs.filter((_, i) => i !== index);
    setTabs(newTabs);
  };
  const handleCreateNewItem = () => {
    const newId = `newTab-${tabs.length + 1}`;
    const newTab = {
      id: newId,
      target: `#${newId}-pane`,
      label: `New Tab ${tabs.length + 1}`,
      content: <div className='text-white'>New Tab {tabs.length + 1} Content...</div>,
      active: true,
      isEditing: false,
    };
    const newTabs = tabs.map((tab) => ({ ...tab, active: false }));
    newTabs.push(newTab);
    setTabs(newTabs);
  };
  return (
    <>
      <Layout>
        <div className='main-block dark-theme'>
          <div className='container-fluid'>
            <div className='d-flex manage-website-flex'>
              <div className='mw-left'>
                <div className='sm-title fw-400 nunito-sans text-white mb-4'>Manage Website Pages</div>
                <div className='mw-menu'>
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  {tabs.map((tab, index) => (
                    <li className="nav-item position-relative" role="presentation" key={index}>
                      {tab.isEditing ? (
                      <input
                        type="text"
                        value={tab.label}
                        onChange={(e) => handleChangeTabName(e, index)}
                        onBlur={() => handleSaveTabName(index)}
                        onKeyPress={(e) => {
                          if (e.key === 'Enter') handleSaveTabName(index); 
                        }}
                        className="nav-link"
                        autoFocus
                      />
                    ) : (
                        <>
                          <button
                            className={`nav-link ${tab.active ? 'active' : ''}`}
                            id={tab.id}
                            data-bs-toggle="tab"
                            data-bs-target={tab.target}
                            type="button"
                            role="tab"
                            aria-controls={tab.target.replace("#", "")}
                            aria-selected={tab.active ? "true" : "false"}
                          >
                            {tab.label}
                          </button>
                          <div className="dropdown-center mw-dropdown">
                            <button
                              className="btn btn-secondary dropdown-toggle"
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <img src={getImages('dots.png')} alt='dot'/>
                            </button>
                            <ul className="dropdown-menu">
                              <li><button className="dropdown-item" type='button' onClick={() => handleDuplicate(index)}>Duplicate</button></li>
                              <li><button className="dropdown-item" type='button' onClick={() => handleEditStart(index)}>Edit</button></li>
                              <li><button className="dropdown-item text-red" type='button' onClick={() => handleDelete(index)}>Delete</button></li>
                            </ul>
                          </div>
                        </>
                      )}
                    </li>
                  ))}
                </ul>
                  <div className='mt-4'>
                    <button type='button' className='sm-title nunito-sans add-new-page-btn' onClick={handleCreateNewItem}><img src={getImages('green-icon-plus.svg')} /> Add New Page</button>
                  </div>
                </div>
              </div>
              <div className='mw-right'>
                <div className='lg-title text-white text-uppercase mb-4 mt-2'>Community</div>
                <div className="tab-content" id="myTabContent">
                  {tabs.map((tab, index) => (
                    <div
                      key={index}
                      className={`tab-pane fade ${tab.active ? 'show active' : ''}`}
                      id={tab.target.replace("#", "")}
                      role="tabpanel"
                      aria-labelledby={tab.id}
                      tabIndex="0"
                    >
                      {tab.content}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default ManageWebsite