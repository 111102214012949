import React, { useContext, useEffect, useState } from 'react'
import { LeadsContext } from '../Context/LeadsContext';

const WaitingResponseModal = ({ close, leadData }) => {
    const {updateLeadData} = useContext(LeadsContext);

    const [percent, setPercent] = useState(20);

    useEffect(() => {
        const chartElement = document.querySelector('.progress-pie-chart');
        const percentValue = parseFloat(percent);

        const deg = 360 * percent / 100;

        if (percentValue > 50) {
            chartElement.classList.add('gt-50');
        } else {
            chartElement.classList.remove('gt-50');
        }

        const ppcProgressFill = document.querySelector('.ppc-progress-fill');
        ppcProgressFill.style.transform = `rotate(${deg}deg)`;
    }, [percent]);

    const dealStages = [
        'Waiting Response', 
        'Agent Responded', 
        'Appraisal Given', 
        'Appraisal Accepted', 
        'Passed to Finalizers'
    ];

    const [selectedRadioIndex, setSelectedRadioIndex] = useState(null);
    
    const handleRadioChange = (index) => {
        setSelectedRadioIndex(index);
    };

    const handleSubmitRadioSmt = async () => {
        console.log(selectedRadioIndex);
        if (selectedRadioIndex !== null) {
            const response = await updateLeadData(leadData.id,"deal_stage",selectedRadioIndex);
            console.log(response);

            leadData.deal_stage = selectedRadioIndex;
            close();
        } else {
            console.log('No stage selected');
        }
    };

    useEffect(() => {
        setSelectedRadioIndex(leadData.deal_stage);
    },[leadData])

    return (
        <>
            <div className='custom-form form-style2'>
                <div className="modal-header">
                    <h1 className="modal-title text-uppercase" id="staticBackdropLabel">Update Deal Stage</h1>
                    <button className="sm-box-close" type="button" onClick={() => {
                        close();
                    }}><i className="fa-solid fa-xmark"></i></button>
                </div>
                <div className="modal-body p-5">
                    <div className='update-deal-radio'>
                        {dealStages.map((item, index)=>
                            <div className='custom-radio mb-3' key={index}>
                                <input
                                    type="radio"
                                    id={`${item?.replace(/\s+/g, '')}chk`}
                                    name='UpdateDealStage'
                                    checked={selectedRadioIndex === index}
                                    onChange={() => handleRadioChange(index)}
                                    value={selectedRadioIndex === index ? "complete" : "incomplete"}
                                />
                                <label htmlFor={`${item?.replace(/\s+/g, '')}chk`}>
                                    <div className='d-inline-flex align-items-center'>
                                        <div className={`progress-pie-chart ${index + 1 > 3 ? 'gt-50': ''} me-5 position-relative`} data-percent={(index + 1) * 20}>
                                            <span className="label">{index + 1}</span>
                                            <div className="ppc-progress">
                                                <div className="ppc-progress-fill" style={{ transform: `rotate(${(index + 1) * 72}deg)` }}></div>
                                            </div>
                                            <div className="ppc-percents">
                                                <div className="pcc-percents-wrapper"></div>
                                            </div>
                                        </div>
                                        <div className='xsm-title'>{item}</div>
                                    </div>
                                </label>
                            </div>
                        )}
                    </div>
                    <div className='text-end'>
                        <button className='blue-btn mnw-170 text-center text-uppercase mt-5' onClick={handleSubmitRadioSmt}>Save</button>
                    </div>
                </div >
            </div>
        </>
    )
}

export default WaitingResponseModal