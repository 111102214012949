import React, { useState } from "react";
import Layout from '../Components/Layouts/Layout'
import TopSection from '../Components/Common/TopSection'
import { getImages } from '../Components/Const'
import { MultiSelect } from "react-multi-select-component";
import DatePicker, { Calendar } from 'react-multi-date-picker'
import SmallLineChart from "../Components/Charts/SmallLineChart";
import Scatterplot from "../Components/Charts/ScatterPlot";
import FullWidthScollerPlot from "../Components/Charts/FullWidthScollerPlot";
const groupOptions = [
    { label: "Jenna S", value: "Jenna S" },
    { label: "Dana B", value: "Dana B" },
    { label: "Gregg H", value: "Gregg H" },
];
const typeOptions = [
    { label: "Jenna S", value: "Jenna S" },
    { label: "Dana B", value: "Dana B" },
    { label: "Gregg H", value: "Gregg H" },
];
const agentOptions = [
    { label: "Jenna S", value: "Jenna S" },
    { label: "Dana B", value: "Dana B" },
    { label: "Gregg H", value: "Gregg H" },
];
const barData1 = [
    { x: 'Jan 2024', y: 2350 },
    { x: 'Feb 2024', y: 1502 },
    { x: 'Mar 2024', y: 3750 },
    { x: 'Apr 2024', y: 1250 },
    { x: 'May 2024', y: 1850 },
    { x: 'June 2024', y: 2250 },
]
const barData2 = [
    { x: 'Jan 2024', y: 4567 },
    { x: 'Feb 2024', y: 3456 },
    { x: 'Mar 2024', y: 1234 },
    { x: 'Apr 2024', y: 2345 },
    { x: 'May 2024', y: 4567 },
    { x: 'June 2024', y: 4321 },
]
const LeadActivity = () => {
    const [groupSelected, setGroupSelected] = useState([]);
    const [typeSelected, setTypeSelected] = useState([]);
    const [agentSelected, setAgentSelected] = useState([]);
    const [values, setValues] = useState([])
    const [activeTab, setActiveTab] = useState('tab1');
    return (
        <>
            <Layout>
                <TopSection />
                <div className='main-block py-4'>
                    <div className='container-fluid'>
                        {/* <div className='gray-box'> */}
                        <div className='d-flex justify-content-between la-header'>
                            <div className='w-50'>
                                <form className='custom-form'>
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <label className='cs-label text-uppercase'>GROUP</label>
                                            <MultiSelect
                                                options={groupOptions}
                                                value={groupSelected}
                                                onChange={setGroupSelected}
                                                labelledBy="Give Me the VIN"
                                            />
                                        </div>
                                        <div className='col-md-4'>
                                            <label className='cs-label text-uppercase'>Type</label>
                                            <MultiSelect
                                                options={typeOptions}
                                                value={typeSelected}
                                                onChange={setTypeSelected}
                                                labelledBy="APPRAISAL REQUEST"
                                            />
                                        </div>
                                        <div className='col-md-4'>
                                            <label className='cs-label text-uppercase'>Agent</label>
                                            <MultiSelect
                                                options={agentOptions}
                                                value={agentSelected}
                                                onChange={setAgentSelected}
                                                labelledBy="Select Agent"
                                            />
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className='text-end'>
                                <div className='d-inline-flex align-items-center gray-box px-4 py-3'>
                                    <div className="pd-xs-title me-4">DATE RANGE</div>
                                    <div className="date-box">
                                        <div className="d-flex align-items-center hc-box">
                                            <div className="icon">
                                                <img className="ico_date" src={getImages('ico-date.svg')} alt='date' />
                                            </div>
                                            <div className='datepicker-box'>
                                                <DatePicker
                                                    value={values}
                                                    onChange={setValues}
                                                    range
                                                    numberOfMonths={2}
                                                    showOtherDays
                                                    placeholder="MM/DD/YY ~ MM/DD/YY"
                                                />
                                                {/*<input type="text" className="date-input" name="daterange" value="" placeholder='MM/DD/YY' />
                                        </div>
                                        <div className='arrow-icon'>
                                            <img src={getImages('icon-calendar-arrow.png')} alt='arrow' />
                                        </div>
                                        <div>
                                            <input type="text" className="date-input" name="daterange" value="" placeholder='MM/DD/YY' /> */}
                                            </div>
                                            <div className="icon">
                                                <img className="ico_close dt-picker-clear" src={getImages('ico-close.svg')} alt="close" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='lg-title text-uppercase font-26 mt-4'>Lead Activity</div>
                        <div className='pd-sm-title text-blue pt-4 text-uppercase'>Select an Indicator to view the chart</div>
                        <div className='pd-xs-title text-white pt-3'>Select an Indicator to view the chart</div>
                        {/* </div> */}
                        <div className='mt-5'>
                            <nav>
                                <div class="nav nav-tabs la-block la-tabs-nav justify-content-between" id="nav-tab" role="tablist">
                                    <button class="nav-link active" onClick={() => setActiveTab('tab1')} id="nav-leadsPerDay-tab" data-bs-toggle="tab" data-bs-target="#nav-leadsPerDay" type="button" role="tab" aria-controls="nav-leadsPerDay" aria-selected="true">
                                        <div className='gray-box p-3'>
                                            <div className='pd-sm-title text-white text-uppercase'>LEADS PER DAY</div>
                                            <div className='la-sbox-value text-white mt-1'>3,400</div>
                                            <div className='mt-2'>
                                                <SmallLineChart data={barData1} width={142} height={37} lineColor="rgb(121, 187, 48)"
                                                    xAxisColor="#fff"
                                                    yAxisColor="#fff"
                                                />
                                            </div>
                                        </div>
                                    </button>
                                    <button class="nav-link" onClick={() => setActiveTab('tab2')} id="nav-callsMode-tab" data-bs-toggle="tab" data-bs-target="#nav-callsMode" type="button" role="tab" aria-controls="nav-callsMode" aria-selected="false">
                                        <div className='gray-box p-3'>
                                            <div className='pd-sm-title text-white text-uppercase'>CALLS MADE</div>
                                            <div className='la-sbox-value red mt-1'>393</div>
                                            <div className='mt-2'>
                                                <SmallLineChart data={barData2} width={142} height={37} lineColor="rgb(202, 48, 1)"
                                                    xAxisColor="#fff"
                                                    yAxisColor="#fff"
                                                />
                                            </div>
                                        </div>
                                    </button>
                                    <button class="nav-link" onClick={() => setActiveTab('tab3')} id="nav-emailsSent-tab" data-bs-toggle="tab" data-bs-target="#nav-emailsSent" type="button" role="tab" aria-controls="nav-emailsSent" aria-selected="false">
                                        <div className='gray-box p-3'>
                                            <div className='pd-sm-title text-white text-uppercase'>EMAILS SENT</div>
                                            <div className='la-sbox-value text-white mt-1'>11,455</div>
                                            <div className='mt-2'>
                                                <SmallLineChart data={barData1} width={142} height={37} lineColor="rgb(121, 187, 48)"
                                                    xAxisColor="#fff"
                                                    yAxisColor="#fff"
                                                />
                                            </div>
                                        </div>
                                    </button>
                                    <button class="nav-link" onClick={() => setActiveTab('tab4')} id="nav-emailsReceived-tab" data-bs-toggle="tab" data-bs-target="#nav-emailsReceived" type="button" role="tab" aria-controls="nav-emailsReceived" aria-selected="false">
                                        <div className='gray-box p-3'>
                                            <div className='pd-sm-title text-white text-uppercase'>EMAILS RECEIVED</div>
                                            <div className='la-sbox-value text-white mt-1'>3,554</div>
                                            <div className='mt-2'>
                                                <SmallLineChart data={barData2} width={142} height={37} lineColor="rgb(121, 187, 48)"
                                                    xAxisColor="#fff"
                                                    yAxisColor="#fff"
                                                />
                                            </div>
                                        </div>
                                    </button>
                                    <button class="nav-link" onClick={() => setActiveTab('tab5')} id="nav-textSent-tab" data-bs-toggle="tab" data-bs-target="#nav-textSent" type="button" role="tab" aria-controls="nav-textSent" aria-selected="false">
                                        <div className='gray-box p-3'>
                                            <div className='pd-sm-title text-white text-uppercase'>TEXT SENT</div>
                                            <div className='la-sbox-value text-white mt-1'>6,334</div>
                                            <div className='mt-2'>
                                                <SmallLineChart data={barData1} width={142} height={37} lineColor="rgb(121, 187, 48)"
                                                    xAxisColor="#fff"
                                                    yAxisColor="#fff"
                                                />
                                            </div>
                                        </div>
                                    </button>
                                    <button class="nav-link" onClick={() => setActiveTab('tab6')} id="nav-appraisalsGiven-tab" data-bs-toggle="tab" data-bs-target="#nav-appraisalsGiven" type="button" role="tab" aria-controls="nav-appraisalsGiven" aria-selected="false">
                                        <div className='gray-box p-3'>
                                            <div className='pd-sm-title text-white text-uppercase'>APPRAISALS GIVEN</div>
                                            <div className='la-sbox-value text-white mt-1'>1,744</div>
                                            <div className='mt-2'>
                                                <SmallLineChart data={barData2} width={142} height={37} lineColor="rgb(121, 187, 48)"
                                                    xAxisColor="#fff"
                                                    yAxisColor="#fff"
                                                />
                                            </div>
                                        </div>
                                    </button>
                                    <button class="nav-link" onClick={() => setActiveTab('tab7')} id="nav-vehiclesPurchased-tab" data-bs-toggle="tab" data-bs-target="#nav-vehiclesPurchased" type="button" role="tab" aria-controls="nav-vehiclesPurchased" aria-selected="false">
                                        <div className='gray-box p-3'>
                                            <div className='pd-sm-title text-white text-uppercase'>VEHICLES PURCHASED</div>
                                            <div className='la-sbox-value text-white mt-1'>4,223</div>
                                            <div className='mt-2'>
                                                <SmallLineChart data={barData1} width={142} height={37} lineColor="rgb(121, 187, 48)"
                                                    xAxisColor="#fff"
                                                    yAxisColor="#fff"
                                                />
                                            </div>
                                        </div>
                                    </button>
                                </div>
                            </nav>
                            <div class="tab-content" id="nav-tabContent">
                                {activeTab === 'tab1' && (
                                    <div class="tab-pane fade show active">
                                        <div className='gray-box p-4 mt-4'>
                                            <div className="rp-sm-title">Leads Per Day</div>
                                            <div className='mt-4 border-b pb-4'>
                                                <FullWidthScollerPlot data={barData1} width="100%" height={400} isVisible={true} svgColor="rgb(121, 187, 48)" 
                                                    xAxisColor="#fff"
                                                    yAxisColor="#fff"
                                                />
                                            </div>
                                            <div className='mt-4 mb-4 d-flex align-items-center la-info-flex'>
                                                <div className='la-info-left pe-5'>
                                                    <div className='row'>
                                                        <div className='col'>
                                                            <div className='rp-value'>2,092</div>
                                                            <div className='mt-4 text-center'>
                                                                <button className='white-transparent-btn' type="button">Vehicles with vin</button>
                                                            </div>
                                                        </div>
                                                        <div className='col'>
                                                            <div className='rp-value'>2,092</div>
                                                            <div className='mt-4 text-center'>
                                                                <button className='white-transparent-btn' type="button">Vehicles without vin</button>
                                                            </div>
                                                        </div>
                                                        <div className='col'>
                                                            <div className='rp-value'>2,092</div>
                                                            <div className='mt-4 text-center'>
                                                                <button className='white-transparent-btn' type="button">Trade-in-Req</button>
                                                            </div>
                                                        </div>
                                                        <div className='col'>
                                                            <div className='rp-value'>2,092</div>
                                                            <div className='mt-4 text-center'>
                                                                <button className='white-transparent-btn' type="button">Just Appraisal</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='la-info-right border-l'>
                                                    <div className='total-label'>TOTAL</div>
                                                    <div className='total-value mt-2'>3,345</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {activeTab === 'tab2' && (
                                    <div class="tab-pane fade show active">
                                        <div className='gray-box p-4 mt-4'>
                                            <div className="rp-sm-title">Calls Mode</div>
                                            <div className='mt-4 border-b pb-4'>
                                                <FullWidthScollerPlot data={barData2} width="100%" height={400} isVisible={true} svgColor="rgb(202, 48, 1)" 
                                                    xAxisColor="#fff"
                                                    yAxisColor="#fff"
                                                />
                                            </div>
                                            <div className='mt-4 mb-4 d-flex align-items-center la-info-flex'>
                                                <div className='la-info-left pe-5'>
                                                    <div className='row'>
                                                        <div className='col'>
                                                            <div className='rp-value'>2,092</div>
                                                            <div className='mt-4 text-center'>
                                                                <button className='white-transparent-btn' type="button">Vehicles with vin</button>
                                                            </div>
                                                        </div>
                                                        <div className='col'>
                                                            <div className='rp-value'>2,092</div>
                                                            <div className='mt-4 text-center'>
                                                                <button className='white-transparent-btn' type="button">Vehicles without vin</button>
                                                            </div>
                                                        </div>
                                                        <div className='col'>
                                                            <div className='rp-value'>2,092</div>
                                                            <div className='mt-4 text-center'>
                                                                <button className='white-transparent-btn' type="button">Trade-in-Req</button>
                                                            </div>
                                                        </div>
                                                        <div className='col'>
                                                            <div className='rp-value'>2,092</div>
                                                            <div className='mt-4 text-center'>
                                                                <button className='white-transparent-btn' type="button">Just Appraisal</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='la-info-right border-l'>
                                                    <div className='total-label'>TOTAL</div>
                                                    <div className='total-value mt-2'>3,345</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {activeTab === 'tab3' && (
                                    <div class="tab-pane fade show active">
                                        <div className='gray-box p-4 mt-4'>
                                            <div className="rp-sm-title">EMAILS Sent</div>
                                            <div className='mt-4 border-b pb-4'>
                                                <FullWidthScollerPlot data={barData1} width="100%" height={400} isVisible={true} svgColor="rgb(121, 187, 48)" 
                                                    xAxisColor="#fff"
                                                    yAxisColor="#fff"
                                                />
                                            </div>
                                            <div className='mt-4 mb-4 d-flex align-items-center la-info-flex'>
                                                <div className='la-info-left pe-5'>
                                                    <div className='row'>
                                                        <div className='col'>
                                                            <div className='rp-value'>2,092</div>
                                                            <div className='mt-4 text-center'>
                                                                <button className='white-transparent-btn' type="button">Vehicles with vin</button>
                                                            </div>
                                                        </div>
                                                        <div className='col'>
                                                            <div className='rp-value'>2,092</div>
                                                            <div className='mt-4 text-center'>
                                                                <button className='white-transparent-btn' type="button">Vehicles without vin</button>
                                                            </div>
                                                        </div>
                                                        <div className='col'>
                                                            <div className='rp-value'>2,092</div>
                                                            <div className='mt-4 text-center'>
                                                                <button className='white-transparent-btn' type="button">Trade-in-Req</button>
                                                            </div>
                                                        </div>
                                                        <div className='col'>
                                                            <div className='rp-value'>2,092</div>
                                                            <div className='mt-4 text-center'>
                                                                <button className='white-transparent-btn' type="button">Just Appraisal</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='la-info-right border-l'>
                                                    <div className='total-label'>TOTAL</div>
                                                    <div className='total-value mt-2'>3,345</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {activeTab === 'tab4' && (
                                    <div class="tab-pane fade show active">
                                        <div className='gray-box p-4 mt-4'>
                                            <div className="rp-sm-title">EMAILS RECEIVED</div>
                                            <div className='mt-4 border-b pb-4'>
                                                <FullWidthScollerPlot data={barData2} width="100%" height={400} isVisible={true} svgColor="rgb(121, 187, 48)" 
                                                    xAxisColor="#fff"
                                                    yAxisColor="#fff"
                                                />
                                            </div>
                                            <div className='mt-4 mb-4 d-flex align-items-center la-info-flex'>
                                                <div className='la-info-left pe-5'>
                                                    <div className='row'>
                                                        <div className='col'>
                                                            <div className='rp-value'>2,092</div>
                                                            <div className='mt-4 text-center'>
                                                                <button className='white-transparent-btn' type="button">Vehicles with vin</button>
                                                            </div>
                                                        </div>
                                                        <div className='col'>
                                                            <div className='rp-value'>2,092</div>
                                                            <div className='mt-4 text-center'>
                                                                <button className='white-transparent-btn' type="button">Vehicles without vin</button>
                                                            </div>
                                                        </div>
                                                        <div className='col'>
                                                            <div className='rp-value'>2,092</div>
                                                            <div className='mt-4 text-center'>
                                                                <button className='white-transparent-btn' type="button">Trade-in-Req</button>
                                                            </div>
                                                        </div>
                                                        <div className='col'>
                                                            <div className='rp-value'>2,092</div>
                                                            <div className='mt-4 text-center'>
                                                                <button className='white-transparent-btn' type="button">Just Appraisal</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='la-info-right border-l'>
                                                    <div className='total-label'>TOTAL</div>
                                                    <div className='total-value mt-2'>3,345</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {activeTab === 'tab5' && (
                                    <div class="tab-pane fade show active">
                                        <div className='gray-box p-4 mt-4'>
                                            <div className="rp-sm-title">Text Sent</div>
                                            <div className='mt-4 border-b pb-4'>
                                                <FullWidthScollerPlot data={barData1} width="100%" height={400} isVisible={true} svgColor="rgb(121, 187, 48)" 
                                                    xAxisColor="#fff"
                                                    yAxisColor="#fff"
                                                />
                                            </div>
                                            <div className='mt-4 mb-4 d-flex align-items-center la-info-flex'>
                                                <div className='la-info-left pe-5'>
                                                    <div className='row'>
                                                        <div className='col'>
                                                            <div className='rp-value'>2,092</div>
                                                            <div className='mt-4 text-center'>
                                                                <button className='white-transparent-btn' type="button">Vehicles with vin</button>
                                                            </div>
                                                        </div>
                                                        <div className='col'>
                                                            <div className='rp-value'>2,092</div>
                                                            <div className='mt-4 text-center'>
                                                                <button className='white-transparent-btn' type="button">Vehicles without vin</button>
                                                            </div>
                                                        </div>
                                                        <div className='col'>
                                                            <div className='rp-value'>2,092</div>
                                                            <div className='mt-4 text-center'>
                                                                <button className='white-transparent-btn' type="button">Trade-in-Req</button>
                                                            </div>
                                                        </div>
                                                        <div className='col'>
                                                            <div className='rp-value'>2,092</div>
                                                            <div className='mt-4 text-center'>
                                                                <button className='white-transparent-btn' type="button">Just Appraisal</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='la-info-right border-l'>
                                                    <div className='total-label'>TOTAL</div>
                                                    <div className='total-value mt-2'>3,345</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {activeTab === 'tab6' && (
                                    <div class="tab-pane fade show active">
                                        <div className='gray-box p-4 mt-4'>
                                            <div className="rp-sm-title">Appraisals Given</div>
                                            <div className='mt-4 border-b pb-4'>
                                                <FullWidthScollerPlot data={barData2} width="100%" height={400} isVisible={true} svgColor="rgb(121, 187, 48)" 
                                                    xAxisColor="#fff"
                                                    yAxisColor="#fff"
                                                />
                                            </div>
                                            <div className='mt-4 mb-4 d-flex align-items-center la-info-flex'>
                                                <div className='la-info-left pe-5'>
                                                    <div className='row'>
                                                        <div className='col'>
                                                            <div className='rp-value'>2,092</div>
                                                            <div className='mt-4 text-center'>
                                                                <button className='white-transparent-btn' type="button">Vehicles with vin</button>
                                                            </div>
                                                        </div>
                                                        <div className='col'>
                                                            <div className='rp-value'>2,092</div>
                                                            <div className='mt-4 text-center'>
                                                                <button className='white-transparent-btn' type="button">Vehicles without vin</button>
                                                            </div>
                                                        </div>
                                                        <div className='col'>
                                                            <div className='rp-value'>2,092</div>
                                                            <div className='mt-4 text-center'>
                                                                <button className='white-transparent-btn' type="button">Trade-in-Req</button>
                                                            </div>
                                                        </div>
                                                        <div className='col'>
                                                            <div className='rp-value'>2,092</div>
                                                            <div className='mt-4 text-center'>
                                                                <button className='white-transparent-btn' type="button">Just Appraisal</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='la-info-right border-l'>
                                                    <div className='total-label'>TOTAL</div>
                                                    <div className='total-value mt-2'>3,345</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {activeTab === 'tab7' && (
                                    <div class="tab-pane fade show active">
                                        <div className='gray-box p-4 mt-4'>
                                            <div className="rp-sm-title">Vehicles Purchased</div>
                                            <div className='mt-4 border-b pb-4'>
                                                <FullWidthScollerPlot data={barData1} width="100%" height={400} isVisible={true} svgColor="rgb(121, 187, 48)" 
                                                    xAxisColor="#fff"
                                                    yAxisColor="#fff"
                                                />
                                            </div>
                                            <div className='mt-4 mb-4 d-flex align-items-center la-info-flex'>
                                                <div className='la-info-left pe-5'>
                                                    <div className='row'>
                                                        <div className='col'>
                                                            <div className='rp-value'>2,092</div>
                                                            <div className='mt-4 text-center'>
                                                                <button className='white-transparent-btn' type="button">Vehicles with vin</button>
                                                            </div>
                                                        </div>
                                                        <div className='col'>
                                                            <div className='rp-value'>2,092</div>
                                                            <div className='mt-4 text-center'>
                                                                <button className='white-transparent-btn' type="button">Vehicles without vin</button>
                                                            </div>
                                                        </div>
                                                        <div className='col'>
                                                            <div className='rp-value'>2,092</div>
                                                            <div className='mt-4 text-center'>
                                                                <button className='white-transparent-btn' type="button">Trade-in-Req</button>
                                                            </div>
                                                        </div>
                                                        <div className='col'>
                                                            <div className='rp-value'>2,092</div>
                                                            <div className='mt-4 text-center'>
                                                                <button className='white-transparent-btn' type="button">Just Appraisal</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='la-info-right border-l'>
                                                    <div className='total-label'>TOTAL</div>
                                                    <div className='total-value mt-2'>3,345</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default LeadActivity