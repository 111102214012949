import React, { useEffect } from 'react'

const ModalLayout = ({ children, open, cls, close, waitingCls}) => {
  useEffect(() => {
    const handleEscapeKey = (event) => {
      if (event.keyCode === 27 && open) {
        close(); // Close the modal if Escape key is pressed and modal is open
      }
    };
    document.addEventListener('keydown', handleEscapeKey);
    return () => {
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, [open, close]);
  return (
    <>
      <div className={`"modal fade custom-modal ${cls ? cls : "mxw-980"} ${open ? "show" : ""}`} id="commonModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className={`overlay-modal cs-overflow-modal ${open ? "open" : ""}`} onClick={close}></div>
        <div className={`modal-dialog modal-dialog-centered ${waitingCls ? '' : 'modal-dialog-scrollable'}`}>
          <div className={`modal-content ${waitingCls ? waitingCls : ''}`}>
            {children}
          </div>
        </div>
      </div>
    </>
  )
}

export default ModalLayout