import React, { useContext, useState } from 'react'
import { getImages } from '../Const'
import { UserContext } from '../Context/UserContext'
import { Link, NavLink } from 'react-router-dom';

const Header = () => {

    const { user, logout } = useContext(UserContext);
    const [isMob, setisMob] = useState(false);
    const onChangeBodyClass = (value) => {
        setisMob(value);
        value
            ? document.body.classList.add('overflow-active')
            : document.body.removeAttribute('class');
    };

    return (
        <>
            <header className="header-wrap">
                <div className="container-fluid">
                    <div className="header-block d-flex align-items-center">
                        <div className="header-left">
                            <Link to="/" className="main-logo">
                                <img src={getImages('main-logo.svg')} alt='logo' />
                            </Link>
                        </div>
                        <div className="header-right d-flex align-items-center justify-content-between menu-block">
                            <div className={`d-flex w-100 align-items-center justify-content-between menus-for-mobile ${isMob ? 'active' : ''}`}>
                                <div className="main-menu">
                                    <ul>
                                        <li>
                                            <NavLink to="/">Dashboard</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/lead-activity">Activity Tracking</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/reporting">Reporting</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/lead-source">GP Attribution</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/lead">Leads</NavLink>
                                        </li>
                                    </ul>
                                </div>
                                <div className="login-block">
                                    {/*<div className="btn-group custom-dropdown">
                                        <button type="button" className="btn btn-secondary dropdown-toggle text-green" data-bs-toggle="dropdown" aria-expanded="false">
                                            <img src={getImages('buil-icon.svg')}/> Little Rock, AR
                                        </button>
                                        <div className="dropdown-menu dropdown-menu-end">
                                            <button className="dropdown-item" type="button">Action</button>
                                            <button className="dropdown-item" type="button">Another action</button>
                                            <button className="dropdown-item" type="button">Something else here</button>
                                        </div>
                                    </div>*/}
                                    <div className="btn-group custom-dropdown ms-3">
                                        <button type="button" className="btn btn-secondary dropdown-toggle text-green" data-bs-toggle="dropdown" aria-expanded="false">
                                            <img src={getImages('user-icon.svg')} /> Welcome {user && user.first_name}
                                        </button>
                                        <div className="dropdown-menu dropdown-menu-end">
                                            <ul>
                                                <li className='cd-title'>My Account</li>
                                                <li>
                                                    <a href='#'>My Profile</a>
                                                </li>
                                                <li>
                                                    <a href='#'>Preferences</a>
                                                </li>
                                                <hr/>
                                                <li className='cd-title'>Manage Content</li>
                                                <li>
                                                    <a href='#'>CRM</a>
                                                </li>
                                                <li>
                                                    <NavLink to="/manage-website">Website</NavLink>
                                                </li>
                                                <li>
                                                    <a href='#'>Inventory</a>
                                                </li>
                                                <hr/>
                                                <li>
                                                    <button className="dropdown-item" type="button" onClick={() => logout()}>Logout</button>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={`overflow-bg d-none ${isMob ? 'active' : ''
                                }`}
                                onClick={() => onChangeBodyClass(!isMob)}
                            ></div>
                            <div className={`mobile-inner-header ${isMob ? 'active' : ''}`}
                                onClick={() => onChangeBodyClass(!isMob)}>
                                <div className={`mobile-inner-header-icon mobile-inner-header-icon-out ${isMob ? 'active' : ''
                                    }`}>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}

export default Header