import React, { useState } from 'react'
import { getImages } from '../Const'
import LeadSourceAccordion from './LeadSourceAccordion';
import { numberFormatter } from '../../utils/Utils';

const LeadSourceItem = ({
        leadSourceId,
        leadSource, 
        openLead, 
        handleAccordionClick, 
        handleMobArrow,
        mobLeadItem,
        cancelMobLeadItem
    }) => {

    const getLeadSource = (source) => {
        //var num = Math.floor(Math.random() * 6);
        var image = getImages('phone-lead-icon.svg');
        switch(source) {
            case 'phone': image = getImages('phone-lead-icon.svg'); break;
            case 'third_party': image = getImages('community-lead-icon.svg'); break;
            case 'organic': image = getImages('seo-lead-icon.svg'); break;
            case 'walk_in': image = getImages('store-lead-icon.svg'); break;
            case 'direct': image = getImages('direct-lead-icon.svg'); break;
            case 'adwords': image = getImages('google-lead-icon.svg'); break;
            default: image = getImages('phone-lead-icon.svg'); break;
        }

        return image;
    }

    const getSourceName = (source) => {
        //var num = Math.floor(Math.random() * 6);
        var source_name = "Other";
        switch(source) {
            case 'phone': source_name = "Phone Call"; break;
            case 'third_party': source_name = "Third Party"; break;
            case 'organic': source_name = "Organic SEO"; break;
            case 'walk_in': source_name = "Walk In"; break;
            case 'direct': source_name = "Direct"; break;
            case 'adwords': source_name = "AdWords"; break;
            default: source_name = "Other"; break;
        }

        return source_name;
    }

    return (
        <>
            <tr id={leadSourceId}>
                <td className='mob-lead-item d-lg-none mb-3 px-0'>
                    <div>
                        <table className='w-100'>
                            <tbody>
                                <tr>
                                    <td className='w-100'>
                                        <div className='d-flex align-items-center'>
                                            <div>
                                                <div className='pd-box-icon position-relative'>
                                                    <img src={getLeadSource(leadSource.source)} alt='icon'/>
                                                </div>
                                            </div>
                                            <div className='pd-sm-title text-white ps-3'>{getSourceName(leadSource.source)}</div>
                                            <div className='ms-auto'>
                                                <button className="mob-lead-btn"
                                                    type="button"
                                                    onClick={()=>{handleMobArrow(leadSourceId); handleAccordionClick(leadSourceId);}}
                                                >
                                                    <span className='btn-arrow' type='button'></span>
                                                </button>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </td>
                <td className={`p-0 col-parent ${mobLeadItem === leadSourceId ? 'parent-mob-active':''}`}>
                    <div className="accordion-item">
                        <button className='mob-back-btn p-0 d-lg-none' type='button' onClick={cancelMobLeadItem}><span className='ps-0'><img src={getImages('left-arrow.svg')} /></span> Back</button>
                        <table className='w-100'>
                            <tbody>
                                <tr>
                                    <td className='mob-d-none ps-4' style={{ width: "5%" }}>
                                        <h2 className="accordion-header p-0">
                                            <button className={`accordion-button p-0 ${openLead === leadSourceId ? "" : "collapsed"}`}
                                                type="button"
                                                onClick={() => handleAccordionClick(leadSourceId)}
                                            >
                                                <span className='btn-arrow accordionTitle' type='button'></span>
                                            </button>
                                        </h2>
                                    </td>
                                    <td style={{ width: "10%" }}>
                                        <div className='d-flex align-items-center'>

                                            <div className='pd-box-icon position-relative'>
                                                <img src={getLeadSource(leadSource.source)} alt='icon' />
                                            </div>
                                            <div className='pd-content ps-4 d-none d-lg-block'>
                                                <div className='pd-sm-title'>{}</div>
                                            </div>
                                        </div>
                                    </td>
                                    <td style={{ width: "25%" }} className='text-start'>{getSourceName(leadSource.source)}</td>
                                    <td style={{ width: "15%" }}>
                                        <div className='d-lg-none'>Leads</div>
                                        <div>{leadSource.total_leads}</div>
                                    </td>
                                    <td style={{ width: "15%" }}>
                                        <div className='d-lg-none'>Sales</div>
                                        <div>{leadSource.leads.length}</div>
                                    </td>
                                    <td style={{ width: "15%" }}>
                                        <div className='d-lg-none'>Total Revenue</div>
                                        <div>{numberFormatter(leadSource.total_revenue,true)}</div>
                                    </td>
                                    <td style={{ width: "15%" }} className='text-green'>
                                        <div className='d-lg-none'>Gross Profit</div>
                                        <div>{numberFormatter(leadSource.gross_profit,true)}</div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        {openLead === leadSourceId ? (
                            <div className="accordion-collapse collapse show">
                                <div className="accordion-body p-0">
                                    <LeadSourceAccordion
                                        leadData={leadSource.leads}
                                    />
                                </div>
                            </div>
                        ) : ""}
                    </div>
                </td>
            </tr>
        </>
    )
}

export default LeadSourceItem