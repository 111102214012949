import React, { useContext, useEffect, useState } from 'react'
import Layout from './Layout'
import Leads from '../../Pages/Leads'
import Dashboard from '../../Pages/Dashboard'
import Scoreboard from '../../Pages/Scoreboard'
import TopSection from '../Common/TopSection'
import { LeadsContext } from '../Context/LeadsContext'
import { getImages } from '../Const'


const CommonLayout = ({ children }) => {
    const { activeTab, filteredLeads, filteredArchivedLeadData } = useContext(LeadsContext);
    const [tabs, setTabs] = useState(false)
    const [dashTabActive, setDashTabActive] = useState('');
    const handleDashTab = (tabsName) => {
        setDashTabActive(tabsName)
    }
    const handleBackBtn = () => {
        setDashTabActive('')
    }

    // Mobile View
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const checkScreenWidth = () => {
      const currentWidth = window.innerWidth;
      setScreenWidth(currentWidth);

      if (currentWidth < 991) {
        handleDashTab('streetLeads');
      }
    };

    // Check screen width on component mount
    checkScreenWidth();

    // Add event listener for resize
    window.addEventListener('resize', checkScreenWidth);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', checkScreenWidth);
    };
  }, []);
    return (
        <>
            <Layout> 
                <TopSection />
                <div className='main-block dark-theme'>
                    <div className='custom-tabs'>
                        <nav className='mn-tabs'>
                            <div className='container-fluid'>
                                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                    <button className={`nav-link ${(tabs && !activeTab)?'active':""}`} id="nav-dashboard-tab" data-bs-toggle="tab" data-bs-target="#nav-dashboard" type="button" role="tab" aria-controls="nav-dashboard" aria-selected="true" onClick={()=>handleDashTab('scoreboard')}>
                                        <div className='mob_tab_icon d-lg-none mb-3'><img src={getImages('scoreboard_icon.svg')}/></div>Scoreboard
                                    </button>
                                    <button className={`nav-link ${(activeTab && !tabs)?"active":""} `} id="nav-leads-tab" data-bs-toggle="tab" data-bs-target="#nav-leads" type="button" role="tab" aria-controls="nav-leads" aria-selected="false" onClick={()=>handleDashTab('streetLeads')}><div className='mob_tab_icon d-lg-none mb-3'><img src={getImages('street_leads_icon.svg')}/></div>Street Leads</button>
                                    {/* <button className={`nav-link`} id="nav-appointments-tab" data-bs-toggle="tab" data-bs-target="#nav-appointments" type="button" role="tab" aria-controls="nav-appointments" aria-selected="false" onClick={()=>handleDashTab('dealerLeads')}><div className='mob_tab_icon d-lg-none mb-3'><img src={getImages('dealer_leads_icon.svg')}/></div>Team Scoreboard</button> */}
                                    <button className={`nav-link ms-auto`} id="nav-archivedleads-tab" data-bs-toggle="tab" data-bs-target="#nav-archivedleads" type="button" role="tab" aria-controls="nav-archivedleads" aria-selected="false" onClick={()=>handleDashTab('archivedLeads')}><div className='mob_tab_icon d-lg-none mb-3'><img src={getImages('reporting_icon.svg')}/></div>Archived Leads</button>
                                </div>
                            </div>
                        </nav>
                        <div className='container-fluid'>
                            <div className="tab-content main-tab-content" id="nav-tabContent">
                                <div className={`tab-pane fade ${dashTabActive === "scoreboard" && 'selected'}`} id="nav-dashboard" role="tabpanel" aria-labelledby="nav-dashboard-tab" tabIndex="0">
                                    <Dashboard handleBackBtn={handleBackBtn}/>
                                </div>
                                <div className={`tab-pane fade ${dashTabActive === "streetLeads" && 'selected'} ${activeTab?"show active":""}`} id="nav-leads" role="tabpanel" aria-labelledby="nav-leads-tab" tabIndex="0">
                                    <Leads 
                                        handleBackBtn={handleBackBtn} 
                                        filteredLeads={filteredLeads}
                                    />
                                </div>
                                <div className={`tab-pane fade ${dashTabActive === "dealerLeads" && 'selected'}`} id="nav-appointments" role="tabpanel" aria-labelledby="nav-appointments-tab" tabIndex="0">
                                    <Scoreboard handleBackBtn={handleBackBtn} />
                                </div>
                                <div className={`tab-pane fade ${dashTabActive === "archivedLeads" && 'selected'}`} id="nav-archivedleads" role="tabpanel" aria-labelledby="nav-archivedleads-tab" tabIndex="0">
                                    <Leads handleBackBtn={handleBackBtn} filteredLeads={filteredArchivedLeadData}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default CommonLayout
