import React, { useState } from 'react'
import FrontLayout from '../Components/Layouts/FrontLayout'
import Gallery from '../Components/Front/Gallery'
import UploadVehiclesDetails from '../Components/Front/UploadVehiclesDetails'
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';

const FrontDealStatus = () => {
    const [uploadVehicleBlock, setUploadVehicleBlock] = useState(false)
    const uploadVehicleSubmit = () => {
        setUploadVehicleBlock(true)
    }
    // Upload Vehicle Details
    const [formData, setFormData] = useState("");
    const [images, setImages] = useState([]);
    const [file, setFile] = useState(null); 
    const initialValues = {
        vin: '',
        file: images || ''
    };
    const handleCopyClick = () => {
        const vinCode = formData.vin || "";
        const textarea = document.createElement('textarea');
        textarea.value = vinCode;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand('copy');
        document.body.removeChild(textarea);
        toast.success("VIN code copied!", {
            position:'top-right'
        })
    };

    // vin Form Block
    const handleDrop = (event) => {
        event.preventDefault();
        const files = event.dataTransfer.files;
        handleImageChange(files);
    };
    const handleDragOver = (event) => {
        event.preventDefault();
    };
    const handleImageChange = (selectedFiles) => {
        let imagesFiles = 30;
        if (selectedFiles.length >= imagesFiles) {
            alert("Maximum number of images (30) reached.");
            return;
        }
        const newImages = [];
        const remainingSlots = imagesFiles - images.length;
        for (let i = 0; i < selectedFiles.length && i < remainingSlots; i++) {
            const file = selectedFiles[i];
            if (file.size <= 5 * 1024 * 1024) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    newImages.push(e.target.result);
                    if (newImages.length === selectedFiles.length) {
                        setImages((prevImages) => [...prevImages, ...newImages]);
                    }
                };
                reader.readAsDataURL(file);
            } else {
                alert(`File ${file.name} exceeds the 5MB size limit and will not be uploaded.`);
            }
        }
    };
    // Setps 
    const [currentStep, setCurrentStep] = useState(1);
    const [stepSetup, setStepSetup] = useState("step1");
    const navigate = useNavigate()
    const handelNevigateLink = () => {
        navigate("/deal-status-step2")
    }
  return (
    <>
        <ToastContainer />
        <FrontLayout>
            <div className='front-main-wrap'>
                <div className='container mxw-1080'>
                    {!uploadVehicleBlock ? 
                        <UploadVehiclesDetails
                            images={images}
                            setImages={setImages}
                            file={file}
                            setFile={setFile}
                            initialValues={initialValues}
                            uploadVehicleSubmit={uploadVehicleSubmit}
                            setFormData={setFormData}
                            handleDrop={handleDrop}
                            handleDragOver={handleDragOver}
                            handleImageChange={handleImageChange}
                            setCurrentStep={setCurrentStep}
                            handelNevigateLink={handelNevigateLink}
                            stepSetup={stepSetup}
                        /> : 
                        <div>
                            <div className='lg-title'>Thank You for Your Appraisal Request!</div>
                            <div className='d-flex appraisal-flex mt-5'>
                                <div className='appraisal-left'>
                                    <Gallery propertyData={images}/>
                                    <div className='mt-3'>
                                        <button className='appraisal-add-btn' type='button'>
                                            <span>
                                                <span className='appraisal-add-icon'></span> Add More Photos
                                            </span>
                                            <input
                                                type="file"
                                                accept="image/*"
                                                multiple
                                                onChange={(e) => handleImageChange(e.target.files)}
                                                id="fileInput"
                                            />
                                        </button>
                                    </div>
                                </div>
                                <div className='appraisal-right'>
                                    <div className='appraisal-rbox'>
                                        <div className='appraisal-md-title mb-2'>2019 Toyota</div>
                                        <div className='appraisal-sm-title mb-3'>4Runner TRD Pro</div>
                                        <div className='appraisal-xs-title mb-4'>18,000 miles</div>
                                        <div className='appraisal-xxs-title mb-3'>Excellent Condition</div>
                                        <div className='appraisal-xxs-title'>No Accidents</div>
                                        <div className='mt-82'>
                                            <span className='appraisal-code w-100'>
                                                {formData.vin || ""}
                                                <button className='appraisal-copy-icon' type='button' onClick={handleCopyClick}></button>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    <div className='deal-status-box mt-35'>
                        <div className='lg-title text-center font-32'>Deal Status</div>
                        <div className="steps-block d-flex mt-4">
                            <div className={`step-col ${currentStep === 1 && 'current'} ${currentStep >= "1" && "active"}`}>
                                <span className="count-num">Appraisal <br/>Submitted</span>
                                <div className='white-circle'></div>
                            </div>
                            <div className={`step-col ${currentStep === 2 && 'current'}  ${currentStep >= "2" && "active"}`}>
                                <span className="count-num">Offer <br/>Sent</span>
                                <div className='white-circle'></div>
                            </div>
                            <div className={`step-col ${currentStep === 3 && 'current'}  ${currentStep >= "3" && "active"}`}>
                                <span className="count-num">Offer <br/>Accepted</span>
                                <div className='white-circle'></div>
                            </div>
                            <div className={`step-col ${currentStep === 4 && 'current'}  ${currentStep >= "4" && "active"}`}>
                                <span className="count-num">Paperwork <br/>Finalized</span>
                                <div className='white-circle'></div>
                            </div>
                            <div className={`step-col ${currentStep === 5 && 'current'}  ${currentStep >= "5" && "active"}`}>
                                <span className="count-num">Pick-up and <br/>Payment</span>
                                <div className='white-circle'></div>
                            </div>
                        </div>
                        <div className='text-center mt-5'>
                            <div className='our-offer-box text-center'>
                                <div className='offer-title'>Our Offer</div>
                                <div className='offer-amount mt-2'>$41,500</div>
                            </div>
                        </div>
                        <div className='text-center mt-5'>
                            <button className='decline-btn mnw-270 text-uppercase' type='button'>Decline</button>
                            <button className='accept-btn mnw-270 text-uppercase ms-4' type='button'>Accept</button>
                        </div>
                    </div>
                    <div className='appraisal-msg-box mt-50'>
                        <div>
                            Hi Kyndell,<br/><br/>
                            We received your request to sell your car.  Reply to this email to communicate directly to your GMTV buyer, or reply to the text your buyer will contact you from during business hours. <br/><br/>
                            We look forward to a quick, and smooth transaction on your vehicle  2017 Ford Escape.  Solid communication is the KEY for this transaction.  It begins in providing pictures, to answering particulars with your buyer, scheduling pickup with our logistics staff, down to precisely how do you want your check made out.<br/><br/>
                            Sincerely,<br/>
                            John Clay Wolfe 
                        </div>
                        <div className='d-flex align-items-center justify-content-between mt-5'>
                            <div>
                                <button className='blue-transparent-btn dark-trans-btn text-uppercase mnw-270'>Text Us</button>
                            </div>
                            <div>
                                <button className='blue-transparent-btn dark-trans-btn text-uppercase mnw-270'>Call Us</button>
                            </div>
                            <div>
                                <button className='blue-transparent-btn dark-trans-btn text-uppercase mnw-270'>Email Us</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </FrontLayout>
    </>
  )
}

export default FrontDealStatus