import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

const SmallLineChart = ({ data, width, height, xAxisColor, yAxisColor, lineColor }) => {
  const margin = { top: 10, right: 10, bottom: 0, left: 15 };
  const boundsWidth = width ;
  const boundsHeight = height ;
  const svgRef = useRef();
  useEffect(() => {
    if (!data) return;

    const svg = d3.select(svgRef.current);

    const x = d3
      .scaleBand()
      .domain(data.map(d => d.x))
      .range([0, boundsWidth])
      .padding(0.1);

    const maxRoundedValue = 20 * Math.ceil(d3.max(data, (d) => d.y) / 20);
    const roundedDomain = [0, maxRoundedValue];
    const y = d3
      .scaleLinear()
      .domain(roundedDomain)
      .nice()
      .range([boundsHeight, 10]);
    const area = d3
      .area()
      .x(d => x(d.x) + x.bandwidth() / 2)
      .y0(boundsHeight)
      .y1(d => y(d.y));

    // Calculate average
    const average = d3.mean(data, d => d.y);

    svg
      .selectAll('.line')
      .data(data.slice(1))
      .enter()
      .append('line')
      .attr('class', 'line')
      .attr('x1', (d, i) => x(data[i].x) + x.bandwidth() / 2)
      .attr('y1', (d, i) => y(data[i].y))
      .attr('x2', (d, i) => x(data[i + 1].x) + x.bandwidth() / 2)
      .attr('y2', (d, i) => y(data[i + 1].y))
      .attr('stroke', lineColor)
      .attr('stroke-width', 2)
      .attr('fill', 'none');

    const yAxis = svg
      .append("g")
      .call(d3.axisLeft(y).ticks(5).tickSizeOuter(0)); 
      
    d3.selectAll('.domain').remove();
    svg.selectAll('.tick line').remove();
  }, [data, width, height, xAxisColor, yAxisColor]);

  return (
    <>
      <svg ref={svgRef} width={width} height={height}></svg>
    </>
  );
};

export default SmallLineChart;
