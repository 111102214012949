import React, { useState } from 'react'
import Layout from '../Components/Layouts/Layout'
import { getImages } from '../Components/Const'
import TopSection from '../Components/Common/TopSection'
import DatePicker, { Calendar } from 'react-multi-date-picker'
import Scatterplot from '../Components/Charts/ScatterPlot'
import BarChart from '../Components/Charts/BarChart'

const barData = [
    { x:'Jan', y: 30 },
    { x: 'Fab', y: 80 },
    { x: 'Mar', y: 34 },
    { x: 'Apr', y: 53 },
    { x: 'May', y: 52 },
    { x: 'Jun', y: 9 },
  ]
const Reporting = () => {
    const [values, setValues] = useState([])
    return (
        <>
            <Layout>
                <TopSection />
                <div className='main-block py-4 dark-theme'>
                    <div className='gray-box mxw-1180'>
                        <div className='d-flex align-items-center justify-content-between reporting-header'>
                            <div className='lg-title text-uppercase font-26'>Reporting: Month to Date</div>
                            <div className='d-flex align-items-center'>
                                <div className="pd-xs-title me-4">DATE RANGE</div>
                                <div className="date-box">
                                    <div className="d-flex align-items-center hc-box">
                                        <div className="icon">
                                            <img className="ico_date" src={getImages('ico-date.svg')} alt='date' />
                                        </div>
                                        <div className='datepicker-box'>
                                            <DatePicker
                                                value={values}
                                                onChange={setValues}
                                                range
                                                numberOfMonths={2}
                                                showOtherDays
                                                placeholder="MM/DD/YY ~ MM/DD/YY" 
                                            /> 
                                             {/*<input type="text" className="date-input" name="daterange" value="" placeholder='MM/DD/YY' />
                                        </div>
                                        <div className='arrow-icon'>
                                            <img src={getImages('icon-calendar-arrow.png')} alt='arrow' />
                                        </div>
                                        <div>
                                            <input type="text" className="date-input" name="daterange" value="" placeholder='MM/DD/YY' /> */}
                                        </div>
                                        <div className="icon">
                                            <img className="ico_close dt-picker-clear" src={getImages('ico-close.svg')} alt="close" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row mlr-20 mt-30'>
                            <div className='col-md-6'>
                                <div className='rp-box'>
                                    <div className='d-flex align-items-center'>
                                        <div className='rp-col w-30'>
                                            <div className='rp-label mb-3'>Vehicles <br />Appraised</div>
                                            <div className='rp-value'>500</div>
                                        </div>
                                        <div className='rp-col w-40'>
                                            <div className='rp-label mb-3'>Avg. Appraisal <br />Amount</div>
                                            <div className='rp-value'>$65,500</div>
                                        </div>
                                        <div className='rp-col w-30'>
                                            <div className='rp-label mb-3'>Appraisal <br />Success Rate</div>
                                            <div className='rp-value'>21%</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='rp-box'>
                                    <div className='d-flex align-items-center'>
                                        <div className='rp-col w-65'>
                                            <div className='rp-label text-white'>Vehicles Bought: <span className='text-blue'>On Track MTD</span></div>
                                            <div className='rp-mtd-value text-center'><span className='text-blue'>180</span> / 250</div>
                                            <div className='d-flex align-items-center'>
                                                <div className='pd-xs-title w-50 text-center'>Bought</div>
                                                <div className='pd-xs-title w-50 text-center'>Remaining</div>
                                            </div>
                                        </div>
                                        <div className='rp-col w-35'>
                                            <div className='rp-label mb-3'>Weekdays</div>
                                            <div className='rp-value'>21%</div>
                                            <div className='pd-xs-title text-center'>Remaining</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row mlr-20 mt-30'>
                            <div className='col-md-6'>
                                <div className='rp-box'>
                                    <div className='rp-sm-title'>Appraisal Volume Trend</div>
                                    <div className='mt-4'>
                                        <Scatterplot data={barData} width="100%" height={240}
                                            xAxisColor="#fff"
                                            yAxisColor="#fff"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='rp-box'>
                                    <div className='rp-sm-title'>Appraisal Price Trend</div>
                                    <div className='mt-4'>
                                        <Scatterplot data={barData} width="100%" height={240}
                                            xAxisColor="#fff"
                                            yAxisColor="#fff"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row mlr-20 mt-30'>
                            <div className='col-md-6'>
                                <div className='rp-box'>
                                    <div className='rp-sm-title'>List of Recent Appraisals</div>
                                    <div className='mt-4 recent-appraisal-table mb-4'>
                                        <table className='w-100'>
                                            <tr>
                                                <th>2015 Maserati Gran Turismo Sport</th>
                                                <td>$84,575</td>
                                            </tr>
                                            <tr>
                                                <th>2017 Audi S8 4.0T Plus</th>
                                                <td>$115,900</td>
                                            </tr>
                                            <tr>
                                                <th>2017 Maserati Quattroporte GTS GranSport RWD</th>
                                                <td>$145,500</td>
                                            </tr>
                                            <tr>
                                                <th>2017 Aston Martin Rapide S Base</th>
                                                <td>$206,000</td>
                                            </tr>
                                            <tr>
                                                <th>2016 Ferrari Spyder Sport</th>
                                                <td>$189,600</td>
                                            </tr>
                                            <tr>
                                                <th>2017 Audi S8 4.0T Plus</th>
                                                <td>$115,900</td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='rp-box'>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <div className='rp-sm-title'>Appraisals by Make</div>
                                        <div className='pd-sm-title text-blue fw-400'>Other Makes: 83</div>
                                    </div>
                                    <div className='mt-4'>
                                        <BarChart/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default Reporting