import React from 'react'
import { numberFormatter } from '../../utils/Utils';
import { getImages } from '../Const';

const LeadSourceAccordion = ({ leadData }) => {
    return (
        <>
            <div className='inside-cat-table'>
                <table className='w-100'>
                    <tbody>
                        <tr>
                            <td style={{width: "5%"}}></td>
                            <td style={{width: "10%"}} className='text-start'>
                                <img src={getImages('autotrader-1.png')}/>
                            </td>
                            <td style={{width: "25%"}} className='text-start'>Autotrader</td>
                            <td style={{width: "15%"}}>4</td>
                            <td style={{width: "15%"}}>1</td>
                            <td style={{width: "15%"}}>$40,000</td>
                            <td style={{width: "15%"}} className='text-green'>$12,000</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className='inside-ls-table'>
                <table className='w-100'>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Vehicle</th>
                            <th>Date</th>
                            <th>Stock #</th>
                            <th>Gross Profit</th>
                        </tr>
                    </thead>
                    <tbody>
                        {leadData.map((lead, i) =>
                            <tr>
                                <td data-title="Name" className='text-white'>{lead.full_name}</td>
                                <td data-title="Vehicle" className='text-white'>{lead.vehicle_name}</td>
                                <td data-title="Date" className='text-nowrap'>{lead.created_at.substring(0, 10)}</td>
                                <td data-title="Stock #">{lead.full_name}</td>
                                <td style={{width: '19%'}} data-title="Gross Profit" className='text-white font-15 text-center'>{numberFormatter(lead.gross_profit, true)}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default LeadSourceAccordion