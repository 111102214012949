import React, {useContext, useEffect, useState} from 'react'
import { getImages } from '../Const'
import { LeadsContext } from '../Context/LeadsContext'

const TaskModal = ({ close,setTaskCount }) => {

    const {leadData, updateTask} = useContext(LeadsContext);

    const [taskStates, setTaskStates] = useState(leadData.tasks);

    const handleRadioChange = (task_data, index, value) => {
        const newTaskStates = [...taskStates];
        newTaskStates[index]['completed'] = value;
        setTaskStates(newTaskStates);

        updateTask(task_data.lead_id, task_data.id, value);
    };

    const removeComplete = () => {
        const newTaskStates = [];

        taskStates.map((v,i) => {
            if(!v.completed) newTaskStates.push(v);
        });

        setTaskStates(newTaskStates);
    }

    useEffect(() => {
        var tasks = [];

        leadData.map((v,i) => {
            if(v.tasks && Array.isArray(v.tasks) && 0 in v.tasks && !v.tasks[0].completed) {
                var this_task = v.tasks[0];
                this_task.lead_id = v.lead_id;
                this_task.full_name = v.full_name;
                this_task.vehicle_name = v.vehicle_name;
                tasks.push(this_task);
            }
        });

        setTaskCount(tasks.length);
        setTaskStates(tasks);
    },[leadData]);

    return (
        <>
            <div className="modal-header">
                <h1 className="modal-title text-uppercase" id="staticBackdropLabel">
                    Tasks
                </h1>
                <button className="sm-box-close" type="button" onClick={close}>
                    <i className="fa-solid fa-xmark"></i>
                </button>
            </div>
            <div className="modal-body px-0">
                <div className='custom-table2 with-white-bg task-box'>
                    <table className='w-100'>
                        <thead>
                            <tr>
                                <th className='text-center'>&nbsp;</th>
                                <th>Task</th>
                                <th className='text-center'>Date</th>
                                <th className='text-center'>Name</th>
                                <th className='text-center'>Vehicle Details</th>
                            </tr>
                        </thead>
                        <tbody >
                            {taskStates && taskStates.map((v,i) => {
                                const checked = v.completed != 0 && v.completed == 1;
                                return (
                                    <tr key={i}>
                                        <td className="tm-col-1">
                                            <label className="custom-checkbox">
                                                <input type="checkbox" id={`leadTask${i}`} checked={checked} value={checked ? "complete" : "incomplete"} onChange={(e) => {handleRadioChange(v, i, e.target.checked)}}/>
                                                <label htmlFor={`leadTask${i}`}></label>
                                            </label>
                                        </td>
                                        <td>
                                            <div className='d-flex align-items-center'>
                                                <div className='mnw-25px text-center inv-title1 fw-500 dgray'>{i + 1}</div>
                                                <div className='mnw-50px text-center'><span className='blue-envlope-icon'></span></div>
                                                <div>
                                                    <div className='inv-title1 fw-500 dgray'>Day {v.day}</div>
                                                    <div className='inv-title2 gray'>{v.text}</div>
                                                </div>
                                            </div>
                                        </td>
                                        <td className='inv-title1 text-center dgray'>{v.date}</td>
                                        <td className='inv-title1 text-center dgray'>{v.full_name}</td>
                                        <td className='inv-title1 text-center dgray'>{v.vehicle_name}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
                <div className='mt-4 d-flex align-items-center justify-content-between px-5'>
                    <div>
                        <button className="text-nowrap blue-transparent-btn dark-transparent-btn text-uppercase py-2" type="button"><span className="blue-add-icon"></span> Add Task Reminder</button>
                    </div>
                    <div>
                        <button className="blue-btn text-uppercase" type="button" onClick={(e) => {removeComplete();}}>Remove Complete</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TaskModal