import React from 'react'
import { getImages } from '../Const'

const FrontHeader = () => {
  return (
    <>
    <header className="header-wrap">
        <div className='header-top'>
            <div className="container mxw-1199 text-end"><span>Sell Your Car Today&nbsp;&nbsp;</span> 800-249-1095</div>
        </div>
        <div className="container mxw-1199">
            <div className="header-block d-flex align-items-center justify-content-between">
                <div className="header-left">
                    <a href="index.html" className="main-logo">
                        <img src={getImages('white-logo.svg')} alt='logo'/>
                    </a>
                </div>
                <div className="header-right text-end">
                    <div className="main-menu d-inline-flex">
                        <ul>
                            <li>
                                <a href="#">Home</a>
                            </li>
                            <li>
                                <a href="#">FAQ</a>
                            </li>
                            <li>
                                <a href="#">Reviews</a>
                            </li>
                            <li>
                                <a href="#">About</a>
                            </li>
                            <li>
                                <a href="#">Locations</a>
                            </li>
                            <li>
                                <a href="#">Jobs</a>
                            </li>
                            <li>
                                <a href="#">Contact Us</a>
                            </li>
                            <li>
                                <a href="#">Radio Shows</a>
                            </li>
                            <li>
                                <a href="#">Dealer</a>
                            </li>
                            <li>
                                <a href="#">TV</a>
                            </li>
                        </ul>
                    </div>
                    {/* <div className="login-block">
                        <div className="btn-group custom-dropdown">
                            <button type="button" className="btn btn-secondary dropdown-toggle text-green" data-bs-toggle="dropdown" aria-expanded="false">
                                <img src={getImages('buil-icon.svg')}/> Little Rock, AR
                            </button>
                            <div className="dropdown-menu dropdown-menu-end">
                                <button className="dropdown-item" type="button">Action</button>
                                <button className="dropdown-item" type="button">Another action</button>
                                <button className="dropdown-item" type="button">Something else here</button>
                            </div>
                        </div>
                        <div className="btn-group custom-dropdown ms-3">
                            <button type="button" className="btn btn-secondary dropdown-toggle text-green" data-bs-toggle="dropdown" aria-expanded="false">
                                <img src={getImages('user-icon.svg')}/> Welcome Chad
                            </button>
                            <div className="dropdown-menu dropdown-menu-end">
                                <button className="dropdown-item" type="button">Action</button>
                                <button className="dropdown-item" type="button">Another action</button>
                                <button className="dropdown-item" type="button">Something else here</button>
                            </div>
                        </div>
                    </div> */}
                    <div className="mobile-inner-header d-none">
                        <div className="mobile-inner-header-icon mobile-inner-header-icon-out">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
    </>
  )
}

export default FrontHeader