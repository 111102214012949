import {useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { LeadsContext } from "../Context/LeadsContext";
import { getImages } from "../Const";

const AutoComplete = ({openMobArrow, handleMobArrow = () => {}}) => {
    const { setActiveLeadID,setActiveTab,filteredLeads,searchText,setSearchText } = useContext(LeadsContext);

    const [showLeads,setShowLeads] = useState(false);
 
    const handleChange = (e) => {
        setSearchText(e.target.value);
    };

    const carIdSearch =(id)=>{
        setActiveLeadID(id)
        setActiveTab(true);
    }

    const showLeadTimeout = () => {
        setShowLeads(true);
        setTimeout(function() {
            // setShowLeads(false);
        }, 5000);
    }

    useEffect(()=>{
        if(searchText) showLeadTimeout();
    },[searchText])
    

    return (
        <>
            <div className="search-box position-relative ms-5" onMouseEnter={() => setShowLeads(true)} onMouseLeave={() => showLeadTimeout()}>
                <div>
                    <input type='text'
                        className='form-control'
                        placeholder='Search'
                        onChange={handleChange}
                        value={searchText} />
                    <span className='search-icon'><i className="fa-solid fa-magnifying-glass"></i></span>
                </div>
                <div
                    className={`cs-search-list ${searchText && showLeads ? "d-none d-lg-block" : "d-none"}`}
                    // style={{
                    //     display: searchText && showLeads ? "d-none d-lg-block" : "d-none"
                    // }}
                >
                    {filteredLeads.length === 0 ? (
                        <div className="autocomplete-items text-center pd-sm-title">Data not found !</div>
                    ) : (
                        filteredLeads.filter((lead,index) => { return index >= 5 ? false : true; }).map((option, index) => (
                            <div key={index} style={{cursor:"pointer"}} onClick={()=>carIdSearch(option.lead_ui_id)}>
                                <div className="autocomplete-items d-flex align-items-center">
                                    {option?.photos ? 
                                        <div className="cssl-img position-relative">
                                            <img src={option.photos.split(',')[0].trim()} alt="" />
                                            {option.photos?.length > 1 ? <div className='vi-count'>+{option.photos?.split(",").length}</div> :""}
                                        </div> :
                                        <div className='pd-no-img position-relative no-photo-pos'>
                                            <img src={getImages("thumb-no-photo.png")} alt='thumb' />
                                        </div>
                                    }
                                    <div className="cssl-content">
                                        <div className="pd-sm-title">{option?.year} {option?.make}</div>
                                        <div className="pd-xs-title my-2">{option?.first_name} {option.last_name}</div>
                                        <div className="pd-email">{option.Email}</div>
                                    </div>
                                    {/* }
                                    <div className="cssl-content">
                                        <div className="pd-sm-title">{option?.year} {option?.make}</div>
                                        <div className="pd-xs-title my-2">{option?.first_name} {option.last_name}</div>
                                        <div className="pd-email">{option.Email}</div>
                                    </div> */}
                                </div>
                            </div>
                        ))
                    )}
                </div>
                <div
                    className="cs-search-list d-lg-none"
                    style={{
                        display: searchText && showLeads ? "block" : "none"
                    }}
                >
                    {filteredLeads.length === 0 ? (
                        <div className="autocomplete-items text-center pd-sm-title">Data not found !</div>
                    ) : (
                        filteredLeads.filter((lead,index) => { return index >= 5 ? false : true; }).map((option, index) => (
                            <div key={index} style={{cursor:"pointer"}} onClick={()=>{handleMobArrow(option); setShowLeads(false); setSearchText("")}}>
                                <div className="autocomplete-items d-flex align-items-center">
                                    {option?.photos ? 
                                        <div className="cssl-img position-relative">
                                            <img src={option.photos.split(',')[0].trim()} alt="" />
                                            {option.photos?.length > 1 ? <div className='vi-count'>+{option.photos?.split(",").length}</div> :""}
                                        </div> :
                                        <div className='pd-no-img position-relative no-photo-pos'>
                                            <img src={getImages("thumb-no-photo.png")} alt='thumb' />
                                        </div>
                                    }
                                    <div className="cssl-content">
                                        <div className="pd-sm-title">{option?.year} {option?.make}</div>
                                        <div className="pd-xs-title my-2">{option?.first_name} {option.last_name}</div>
                                        <div className="pd-email">{option.Email}</div>
                                    </div>
                                    {/* }
                                    <div className="cssl-content">
                                        <div className="pd-sm-title">{option?.year} {option?.make}</div>
                                        <div className="pd-xs-title my-2">{option?.first_name} {option.last_name}</div>
                                        <div className="pd-email">{option.Email}</div>
                                    </div> */}
                                </div>
                            </div>
                        ))
                    )}
                </div>
            </div>
        </>
    );
};

export default AutoComplete;
