import React, {useContext} from 'react'
import { getImages } from '../Components/Const'
import { LeadsContext } from '../Components/Context/LeadsContext';

const Scoreboard = ({ handleBackBtn }) => {

    const { leaderboard } = useContext(LeadsContext);

    return (
        <>
            <div className='scoreboard-block'>
                <div className='scoreboard-box'>
                    <div className='d-lg-none mob-scoreboard-title px-3 py-2'>
                        <button className='mob-back-btn p-0' type='button' onClick={handleBackBtn}><span className='ps-0'><img src={getImages('left-arrow.svg')} /></span> Back</button>
                        <div className='main-heading text-uppercase'>Rankings</div>
                    </div>
                    <div className='scoreboard-top pt-5 px-5 d-none d-lg-block'>
                        <div className='d-flex align-items-center justify-content-between'>
                            <div className='d-flex align-items-center'>
                                <div className='scoreboard-icon'>
                                    <img src={getImages('trophy-icon.svg')} alt='trophy' />
                                </div>
                                <div className='ps-4'>
                                    <div className='lg-title'>Team Scoreboard</div>
                                    <div className='xs-title text-white fw-300 font-15 my-1'>Current rankings month to date <button type='button' className='adjusttime-btn'>Adjust Time Frame</button></div>
                                </div>
                            </div>
                            <div className='product-img pe-5'>
                                <img src={getImages('porsche.png')} alt='porsche' />
                            </div>
                        </div>
                    </div>
                    <div className='scoreboard-bottom pt-1 px-5 pb-50'>
                        <div className="d-flex align-items-start custom-pills">
                            <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                <button className="nav-link active" id="v-pills-OverallPoints-tab" data-bs-toggle="pill" data-bs-target="#v-pills-OverallPoints" type="button" role="tab" aria-controls="v-pills-OverallPoints" aria-selected="true"><span className='pill-icon pill-icon1'></span> Overall Points</button>
                                <button className="nav-link" id="v-pills-CarsBought-tab" data-bs-toggle="pill" data-bs-target="#v-pills-CarsBought" type="button" role="tab" aria-controls="v-pills-CarsBought" aria-selected="false"><span className='pill-icon pill-icon2'></span> Cars Bought</button>
                                <button className="nav-link" id="v-pills-AppraisalsMade-tab" data-bs-toggle="pill" data-bs-target="#v-pills-AppraisalsMade" type="button" role="tab" aria-controls="v-pills-AppraisalsMade" aria-selected="false"><span className='pill-icon pill-icon3'></span> Appraisals Made</button>
                                <button className="nav-link" id="v-pills-AppraisalsAccepted-tab" data-bs-toggle="pill" data-bs-target="#v-pills-AppraisalsAccepted" type="button" role="tab" aria-controls="v-pills-AppraisalsAccepted" aria-selected="false"><span className='pill-icon pill-icon4'></span> Appraisals Accepted</button>
                            </div>
                            <div className='scoreboard-top pt-5 px-5 d-lg-none'>
                                <div className='d-flex align-items-center'>
                                    <div className='scoreboard-icon'>
                                        <img src={getImages('trophy-icon.svg')} alt='trophy' />
                                    </div>
                                    <div className='ps-4'>
                                        <div className='lg-title'>Team Scoreboard</div>
                                    </div>
                                </div>

                                <div className='d-inline-flex align-items-center justify-content-between'>
                                    <div className='xs-title text-white fw-300 font-15 my-1'>
                                        Current rankings month to date <br />
                                        <button type='button' className='adjusttime-btn ps-0 mt-3'>Adjust Time Frame</button>
                                    </div>
                                    <div className='product-img ps-5'>
                                        <img src={getImages('porsche.png')} alt='porsche' />
                                    </div>
                                </div>
                            </div>
                            <div className="tab-content ps-5" id="v-pills-tabContent">
                                <div className="tab-pane fade show active" id="v-pills-OverallPoints" role="tabpanel" aria-labelledby="v-pills-OverallPoints-tab" tabIndex="0">
                                    <div className='pills-table'>
                                        {leaderboard.map((v,i) => 
                                            <table className='w-100' key={i}>
                                                <tbody>
                                                    <tr>
                                                        <td className='w-120 pe-0'>
                                                            <div className='d-flex align-items-center justify-content-between'>
                                                                <div className='pill-count blue-bg'>{v.place}</div>
                                                                <div className='pill-border-r'></div>
                                                            </div>
                                                        </td>
                                                        <td className='w-50'>
                                                            <span className='pill-short-circle'>{v.initials}</span>
                                                            <span className='pill-title ms-5'>{v.full_name}</span>
                                                        </td>

                                                        <td className='text-end'>
                                                            <div className='d-inline-flex align-items-center'>
                                                                <div className='pill-label d-none d-lg-block'>Total:</div>
                                                                <div className='pill-price ps-5 pe-4'>{v.score}</div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        )}
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="v-pills-CarsBought" role="tabpanel" aria-labelledby="v-pills-CarsBought-tab" tabIndex="0">...</div>
                                <div className="tab-pane fade" id="v-pills-AppraisalsMade" role="tabpanel" aria-labelledby="v-pills-AppraisalsMade-tab" tabIndex="0">...</div>
                                <div className="tab-pane fade" id="v-pills-AppraisalsAccepted" role="tabpanel" aria-labelledby="v-pills-AppraisalsAccepted-tab" tabIndex="0">...</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Scoreboard