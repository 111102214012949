import React, { useContext, useEffect, useState } from 'react'
import Layout from '../Layouts/Layout';
import TopSection from '../Common/TopSection';
import { LeadsContext } from '../Context/LeadsContext';
import DataTable, { createTheme } from 'react-data-table-component';

const Export = ({ onExport }) => <div className="d-none d-lg-block"><button onClick={e => onExport(e.target.value)} className="blue-btn" type="button"><span className=""></span>Export</button></div>;

    

const Lead = ({}) => {
    const [data,setData] = useState({});
    const { filteredLeads} = useContext(LeadsContext);

    useEffect(() => {
        setData(filteredLeads);        
    }, [filteredLeads]);

    createTheme('solarized', {
        text: {
          primary: '#ffffff',
          secondary: '#ffffff',
        },
        background: {
          default: '#353a40',
        },
        context: {
          background: '#cb4b16',
          text: '#FFFFFF',
        },
        divider: {
          default: '#757575',
        },
        action: {
          button: 'rgba(0,0,0,.54)',
          hover: 'rgba(0,0,0,.08)',
          disabled: 'rgba(0,0,0,.12)',
        },
      }, 'dark');
    
    const columns = [
        {
            name: 'Customer Name',
            selector: row => row.full_name,
            sortable: true,
        },
        {
            name: 'Vehicle Info',
            selector: row => row.vehicle_name,
            sortable: true,
        },
        {
            name: 'Deal Stage',
            selector: row => row.year,
            sortable: true,
        },
        {
            name: 'Appraisal',
            selector: row => row.year,
            sortable: true,
        },
        {
            name: 'Email Sent',
            selector: row => row.Email,
            sortable: true,
        },
        {
            name: 'Phone Call',
            selector: row => row.Phone,
            sortable: true,
        },
        {
            name: 'Notes',
            selector: row => row.condition,
            sortable: true,
        },
        {
            name: 'Timer',
            selector: row => row.mileage,
            sortable: true,
        },
        {
            name: 'Assigned To',
            selector: row => row.agent_email,
            sortable: true,
        },
    ];

    function exportTableToCSV(array) {

    
        // Temporary delimiter characters unlikely to be typed by keyboard
        // This is to avoid accidentally splitting the actual contents
        const tmpColDelim = String.fromCharCode(11); // vertical tab character
        const tmpRowDelim = String.fromCharCode(0); // null character
        const keys = Object.keys(array[0]);

        // actual delimiter characters for CSV format
        const colDelim = '","';
        const rowDelim = '"\r\n"';

        array.unshift(keys);
    
          // Grab text from table into CSV formatted string
        var csv = '"' + array.map(function(row,i) { 
            return Object.values(row).map(function(col,j) { 
                return (col + "").replace(/"/g, '""'); // escape double quotes    
            }).join(tmpColDelim);
    
        }).join(tmpRowDelim)
        .split(tmpRowDelim).join(rowDelim)
        .split(tmpColDelim).join(colDelim) + '"';
    
        return csv;
      }

    function downloadCSV(array) {
        const link = document.createElement('a');
        let csv = exportTableToCSV(array);
        if (csv == null) return;

        const filename = 'export.csv';

        var csvData = 'data:application/csv;charset=utf-8,' + encodeURIComponent(csv);

        link.setAttribute('href', csvData);
        link.setAttribute('download', filename);
        link.click();
    }   

    const actionsMemo = React.useMemo(() => <Export onExport={() => downloadCSV(data)} />, []);

    return (
        <>
            <Layout>
            <TopSection/>
                <div className='main-block'>
                    <div className='container-fluid'>
                    
                    <DataTable title="ACTIVE LEADS" columns={columns} data={data} actions={actionsMemo} theme="solarized" />;

                    </div>
                </div>
            </Layout>
        </>
    )
}

export default Lead;