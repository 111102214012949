import React, { useState } from 'react'
import { getImages } from '../Components/Const'
import DatePicker, { Calendar } from 'react-multi-date-picker'
import avatar_img from '../assets/img/1.jpg'
import icon1 from '../assets/img/icon-check-blue.svg'
import icon2 from '../assets/img/icon-call.svg'
import icon3 from '../assets/img/icon-email.svg'
import icon4 from '../assets/img/icon-message.svg'
import icon5 from '../assets/img/calculator.svg'
import icon6 from '../assets/img/handoffs.svg'

const Dashboard = ({handleBackBtn}) => {
  const [values, setValues] = useState([])
  const [openScoreboard, setOpenScoreboard] = useState('apprisalPhase');
  const handleScoreboard = (scoreboardTitle) => {
    setOpenScoreboard(scoreboardTitle)
  }
  return (
    <>
      <div className='dash-scoreboard-box'>
        <div className='d-lg-none '>
          <button className='mob-back-btn' type='button' onClick={handleBackBtn}><span><img src={getImages('left-arrow.svg')}/></span> Back</button>
        </div>
        <div className='d-flex align-items-center justify-content-between filter-block'>
          <div className='main-heading text-uppercase'>
            Scoreboard
          </div>
          <div className='d-flex align-items-center'>
            <div className='d-flex align-items-center filter-col me-4'>
              <label className='text-uppercase'>Agent</label>
              <select className="form-control">
                <option value="all days">Select</option>
              </select>
            </div>
            <div className='d-flex align-items-center'>
              <div className="pd-xs-title me-4">DATE RANGE</div>
              <div className="date-box">
                <div className="d-flex align-items-center hc-box">
                  <div className="icon">
                    <img className="ico_date" src={getImages('ico-date.svg')} alt='date' />
                  </div>
                  <div className='datepicker-box'>
                    <DatePicker
                      value={values}
                      onChange={setValues}
                      range
                      numberOfMonths={2}
                      showOtherDays
                      placeholder="MM/DD/YY ~ MM/DD/YY"
                    />
                  </div>
                  <div className="icon">
                    <img className="ico_close dt-picker-clear" src={getImages('ico-close.svg')} alt="close" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-4'>
            <div className='gray-box p-4 mb-4'>
              <div className='main-heading text-uppercase'><span className='star-icon'></span> OUTPUT RANKING</div>
              <div className='small-tabs mt-4'>
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button className="nav-link position-relative active" id="Closes-tab" data-bs-toggle="tab" data-bs-target="#Closes-tab-pane" type="button" role="tab" aria-controls="Closes-tab-pane" aria-selected="true">
                      <div className='pill-count sm-pill-count blue-bg'>4th</div>
                      Closes
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button className="nav-link position-relative" id="Appraisals-tab" data-bs-toggle="tab" data-bs-target="#Appraisals-tab-pane" type="button" role="tab" aria-controls="Appraisals-tab-pane" aria-selected="false">
                      <div className='pill-count sm-pill-count blue-bg'>8th</div>
                      Appraisals
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button className="nav-link position-relative" id="Emails-tab" data-bs-toggle="tab" data-bs-target="#Emails-tab-pane" type="button" role="tab" aria-controls="Emails-tab-pane" aria-selected="false">
                      <div className='pill-count sm-pill-count blue-bg'>5th</div>
                      Emails
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button className="nav-link position-relative" id="Text-tab" data-bs-toggle="tab" data-bs-target="#Text-tab-pane" type="button" role="tab" aria-controls="Text-tab-pane" aria-selected="false">
                      <div className='pill-count sm-pill-count blue-bg'>6th</div>
                      Text
                    </button>
                  </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                  <div className="tab-pane fade show active" id="Closes-tab-pane" role="tabpanel" aria-labelledby="Closes-tab" tabIndex="0">
                    <div className='cs-table'>
                      <table className='w-100'>
                        <tbody>
                          <tr>
                            <td className='w-35px pe-0 text-center fw-600 text-blue'>1st</td>
                            <td>
                              <div className='d-flex align-items-center'>
                                <div className='avatar sm-avatar me-2'>
                                  <img src={avatar_img} />
                                </div>
                                <div className='avatar-label'>Cal</div>
                              </div>
                            </td>
                            <td className='cst-total text-blue'>1,200</td>
                          </tr>
                          <tr>
                            <td className='w-35px pe-0 text-center fw-600'>2st</td>
                            <td>
                              <div className='d-flex align-items-center'>
                                <div className='avatar sm-avatar me-2'>
                                  <img src={avatar_img} />
                                </div>
                                <div className='avatar-label'>Donna</div>
                              </div>
                            </td>
                            <td className='cst-total'>1,100</td>
                          </tr>
                          <tr>
                            <td className='w-35px pe-0 text-center fw-600'>3rd</td>
                            <td>
                              <div className='d-flex align-items-center'>
                                <div className='avatar sm-avatar me-2'>
                                  <img src={avatar_img} />
                                </div>
                                <div className='avatar-label'>Arthur</div>
                              </div>
                            </td>
                            <td className='cst-total'>1,000</td>
                          </tr>
                          <tr className='active'>
                            <td className='w-35px pe-0 text-center fw-600'>4th</td>
                            <td>
                              <div className='d-flex align-items-center'>
                                <div className='avatar sm-avatar me-2'>
                                  <img src={avatar_img} />
                                </div>
                                <div className='avatar-label'>You</div>
                              </div>
                            </td>
                            <td className='cst-total'>900</td>
                          </tr>
                          <tr>
                            <td className='w-35px pe-0 text-center fw-600'>5th</td>
                            <td>
                              <div className='d-flex align-items-center'>
                                <div className='avatar sm-avatar me-2'>
                                  <img src={avatar_img} />
                                </div>
                                <div className='avatar-label'>Jaime</div>
                              </div>
                            </td>
                            <td className='cst-total'>800</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="tab-pane fade" id="Appraisals-tab-pane" role="tabpanel" aria-labelledby="Appraisals-tab" tabIndex="0">...</div>
                  <div className="tab-pane fade" id="Emails-tab-pane" role="tabpanel" aria-labelledby="Emails-tab" tabIndex="0">...</div>
                  <div className="tab-pane fade" id="Text-tab-pane" role="tabpanel" aria-labelledby="Text-tab" tabIndex="0">...</div>
                </div>
              </div>
            </div>
            <div className='gray-box p-4'>
              <div className='main-heading text-uppercase mb-4'><span className='shape-icon'></span> Response Rates</div>
              <div className='cs-table rr-line-height text-white'>
                <table className='w-100'>
                  <thead>
                    <tr>
                      <th>Method</th>
                      <th className='text-center'>Total</th>
                      <th className='text-center'>Ratio</th>
                      <th className='text-center'>Benchmark</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Phone Call Backs</td>
                      <td className='text-center'>18 of 135</td>
                      <td className='text-center green'>13.4%</td>
                      <td className='text-center'>12%</td>
                    </tr>
                    <tr>
                      <td>Template Email</td>
                      <td className='text-center'>24 of 200</td>
                      <td className='text-center red'>8.5%</td>
                      <td className='text-center'>10%</td>
                    </tr>
                    <tr>
                      <td>Plain Text Email</td>
                      <td className='text-center'>25 of 259</td>
                      <td className='text-center green'>14.9%</td>
                      <td className='text-center'>12%</td>
                    </tr>
                    <tr>
                      <td>Text Responses</td>
                      <td className='text-center'>24 of 200</td>
                      <td className='text-center red'>8.5%</td>
                      <td className='text-center'>10%</td>
                    </tr>
                    <tr>
                      <td>Something Other</td>
                      <td className='text-center'>25 of 259</td>
                      <td className='text-center green'>14.9%</td>
                      <td className='text-center'>12%</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className='col-lg-8'>
            <div className='d-lg-none px-3 pt-4 mob-tar-heading'>
              <div className='main-heading text-uppercase mb-3 tar-heading'><span className='outbound-icon'></span> SCOREBOARD</div>
              <div className='mt-4 scoreboard-btns'>
                <button type='button' className={`scb-btn ${openScoreboard === 'apprisalPhase' && 'selected'}`} onClick={()=>handleScoreboard('apprisalPhase')}>APPRAISAL PHASE</button>
                <button type='button' className={`scb-btn ${openScoreboard === 'closingPhase' && 'selected'}`} onClick={()=>handleScoreboard('closingPhase')}>Closing PHASE</button>
              </div>
            </div>
            <div className={`gray-box scoreboard-gray-box p-4 pb-5 mb-4 d-none d-lg-block ${openScoreboard === 'apprisalPhase' && 'selected'}`}>
              <div className='main-heading text-uppercase mb-3 tar-heading d-lg-block d-none'><span className='outbound-icon'></span> SCOREBOARD: APPRAISAL PHASE</div>
              <div className='rank-seprate-bar'>
                <hr />
                <div className='rank-seprate-box'>YOUR RANK <span>8th</span></div>
              </div>
              <div className='mt-4 row align-items-center'>
                <div className='col-lg-4 pe-5'>
                  <div>
                    <div className='text-center'>
                      <img src={icon1} />
                    </div>
                    <div className='md-title text-white text-center mt-3'>TASKS ASSIGNED</div>
                  </div>
                  <div className='row mt-4'>
                    <div className='col-6'>
                      <div className='ta-value text-center'>95</div>
                      <div className='ta-label text-center'>TOTAL</div>
                    </div>
                    <div className='col-6'>
                      <div className='ta-value text-center'>64</div>
                      <div className='ta-label text-center'>COMPLETED</div>
                    </div>
                  </div>
                </div>
                <div className='col-lg-8 ta-right py-2'>
                  <div className='row'>
                    <div className='col-lg-6'>
                      <div className='d-flex align-items-center tar-col-list'>
                        <div className='me-3 tar-icon'>
                          <img src={icon2} />
                        </div>
                        <div className='tar-col-info'>
                          <div className='tar-value'>95</div>
                          <div className='tar-label'>CALLS MADE</div>
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-6'>
                      <div className='d-flex align-items-center tar-col-list'>
                        <div className='me-3 tar-icon'>
                          <img src={icon3} />
                        </div>
                        <div className='tar-col-info'>
                          <div className='tar-value'>18</div>
                          <div className='tar-label'>EMAIL SENT</div>
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-6'>
                      <div className='d-flex align-items-center tar-col-list'>
                        <div className='me-3 tar-icon'>
                          <img src={icon4} />
                        </div>
                        <div className='tar-col-info'>
                          <div className='tar-value'>12</div>
                          <div className='tar-label'>TEXT MESSAGES SENT</div>
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-6'>
                      <div className='d-flex align-items-center tar-col-list'>
                        <div className='me-3 tar-icon'>
                          <img src={icon5} />
                        </div>
                        <div className='tar-col-info'>
                          <div className='tar-value'>22</div>
                          <div className='tar-label'>APPRAISALS MADE</div>
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-6'>
                      <div className='d-flex align-items-center tar-col-list'>
                        <div className='me-3 tar-icon'>
                          <img src={icon6} />
                        </div>
                        <div className='tar-col-info'>
                          <div className='tar-value'>30</div>
                          <div className='tar-label'>SUCCESSFUL HANDOFFS</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={`gray-box scoreboard-gray-box p-4 pb-5 d-none d-lg-block ${openScoreboard === 'closingPhase' && 'selected'}`}>
              <div className='main-heading text-uppercase mb-3 tar-heading d-lg-block d-none'><span className='outbound-icon'></span> SCOREBOARD: Closing PHASE</div>
              <div className='rank-seprate-bar'>
                <hr />
                <div className='rank-seprate-box'>YOUR RANK <span>8th</span></div>
              </div>
              <div className='mt-4 row align-items-center'>
                <div className='col-lg-4 pe-5'>
                  <div>
                    <div className='text-center'>
                      <img src={icon1} />
                    </div>
                    <div className='md-title text-white text-center mt-3'>TASKS ASSIGNED</div>
                  </div>
                  <div className='row mt-4'>
                    <div className='col-6'>
                      <div className='ta-value text-center'>50</div>
                      <div className='ta-label text-center'>TOTAL</div>
                    </div>
                    <div className='col-6'>
                      <div className='ta-value text-center'>47</div>
                      <div className='ta-label text-center'>COMPLETED</div>
                    </div>
                  </div>
                </div>
                <div className='col-lg-8 ta-right py-2'>
                  <div className='row'>
                    <div className='col-lg-6'>
                      <div className='d-flex align-items-center tar-col-list'>
                        <div className='me-3 tar-icon'>
                          <img src={icon2} />
                        </div>
                        <div className='tar-col-info'>
                          <div className='tar-value'>42</div>
                          <div className='tar-label'>CALLS MADE</div>
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-6'>
                      <div className='d-flex align-items-center tar-col-list'>
                        <div className='me-3 tar-icon'>
                          <img src={icon3} />
                        </div>
                        <div className='tar-col-info'>
                          <div className='tar-value'>18</div>
                          <div className='tar-label'>EMAIL SENT</div>
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-6'>
                      <div className='d-flex align-items-center tar-col-list'>
                        <div className='me-3 tar-icon'>
                          <img src={icon4} />
                        </div>
                        <div className='tar-col-info'>
                          <div className='tar-value'>12</div>
                          <div className='tar-label'>TEXT MESSAGES SENT</div>
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-6'>
                      <div className='d-flex align-items-center tar-col-list'>
                        <div className='me-3 tar-icon'>
                          <img src={icon5} />
                        </div>
                        <div className='tar-col-info'>
                          <div className='tar-value'>22</div>
                          <div className='tar-label'>CHECKS CUT</div>
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-6'>
                      <div className='d-flex align-items-center tar-col-list'>
                        <div className='me-3 tar-icon'>
                          <img src={icon6} />
                        </div>
                        <div className='tar-col-info'>
                          <div className='tar-value'>20</div>
                          <div className='tar-label'>SUCCESSFUL PICKUPS</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Dashboard