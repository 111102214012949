import React from 'react'

const LeadTaskItem = ({index, taskData, handleRadioChange, lead_ui_id}) => {

    //console.log(taskData,taskData && taskData.completed != 0 && taskData.completed == 1,taskData.completed != 1);

    const checked = taskData && taskData.completed != 0 && taskData.completed == 1;
    return (
        <>
            <tr>
                <td>
                    <span className='task-day-title pe-3'>Day {taskData && taskData.day}</span>
                    <span className={`task-call-count ${taskData && taskData.completed ? 'text-decoration-line-through' : ''}`}>
                        {taskData && taskData.date} - {taskData && taskData.text} 
                    </span>
                </td>
                <td className='text-end'>
                    <div className='d-inline-block custom-radio me-5'>
                        <input
                            type="radio"
                            id={`day${lead_ui_id}${index}_yes`}
                            name={`day${lead_ui_id}${index}`}
                            checked={checked}
                            onChange={(e) => handleRadioChange(index, true)}
                            value={checked ? "complete" : "incomplete"}
                        />
                        <label htmlFor={`day${lead_ui_id}${index}_yes`}>Yes</label>
                    </div>
                    <div className='d-inline-block custom-radio'>
                        <input
                            type="radio"
                            id={`day${lead_ui_id}${index}_no`}
                            name={`day${lead_ui_id}${index}`}
                            checked={!checked}
                            onChange={(e) => handleRadioChange(index, false)}
                            value={!checked ? "complete" : "incomplete"}
                        />
                        <label htmlFor={`day${lead_ui_id}${index}_no`}>No</label>
                    </div>
                </td>
            </tr>
        </>
    )
}

export default LeadTaskItem